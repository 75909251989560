.section {
  /* background-color: #fef9f7; */
  min-height: 300px;
  display: flex;
  align-items: center;
}

.heading {
  text-align: center;
  font-family: "Poppins", serif;
  font-weight: 600;
  font-size: 3.5rem;
  color: var(--frontendFont1);
  line-height: 1.1;
}

@media (max-width: 992px) {
  .heading {
    font-size: 3.2rem;
  }
}

@media (max-width: 992px) {
  .heading {
    font-size: 3rem;
  }
}

@media (max-width: 576px) {
  .heading {
    font-size: 2.75rem;
  }
}
