.hero_section {
  background-color: var(--bgColorFrontend);
  min-height: calc(100vh - 82px);
  display: flex;
  align-items: center;
}

.features {
  font-family: "Poppins", sans-serif;
  font-weight: 500;
  font-size: 1.3rem;
  color: var(--brandColorFrontend);
  letter-spacing: 0.3rem;
  text-transform: uppercase;
}

.heading {
  font-family: "Poppins", sans-serif;
  font-weight: 500;
  font-size: 3.5rem;
  color: var(--white);
  border-bottom: 4px solid var(--brandColorFrontend);
  line-height: 1.3;
  letter-spacing: 0.1rem;
}

.text_heading {
  font-family: "Inter", sans-serif;
  font-weight: 500;
  font-size: 1.3rem;
  color: var(--white);
  line-height: 1.4;
  letter-spacing: 0.1rem;
}

.btn_begin {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  max-width: 12rem;
  height: 3.15rem;
  padding: 0 2.5rem;
  border-radius: 2rem;
  background: var(--brandColorFrontend);
  color: var(--white);
  font-family: "Inter", sans-serif;
  font-weight: 600;
  font-size: 1.125rem;
  transition: all 0.3s ease;
}

.btn_begin:hover {
  color: var(--brandColorFrontend);
  background-color: var(--white);
}

.btn_begin:active {
  background-color: var(--brandColor);
  color: var(--white);
}

@media (max-width: 992px) {
  .features {
    font-size: 1.25rem;
    letter-spacing: 0.25rem;
  }

  .heading {
    font-size: 3rem;
  }
  .text_heading {
    font-size: 1.25rem;
  }

  .btn_begin {
    font-size: 1.1rem;
  }
}

@media (max-width: 992px) {
  .features {
    font-size: 1.2rem;
    letter-spacing: 0.2rem;
  }

  .heading {
    font-size: 2.5rem;
  }
  .text_heading {
    font-size: 1.2rem;
  }

  .btn_begin {
    font-size: 1.05rem;
  }
}

@media (max-width: 576px) {
  .features {
    font-size: 1.1rem;
    letter-spacing: 0.15rem;
  }

  .heading {
    font-size: 2rem;
  }
  .text_heading {
    font-size: 1.1rem;
  }

  .btn_begin {
    font-size: 1rem;
  }
}
