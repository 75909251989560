.section {
  background-color: var(--backgroundPink);
  min-height: 300px;
  display: flex;
  align-items: center;
}

.form_rectangle {
  width: 100%;
  height: 100%;
  contain: content;
  max-width: 46rem;
  background: var(--white);
  position: relative;
  transform: translate(0rem, -3rem);
  border-radius: 0.875rem 0.875rem 0 0;
}

.form_header {
  width: 100%;
  height: 15rem;
  background: var(--bgColorFrontend);
}

.form_header_heading {
  text-align: center;
  font-family: "Poppins", serif;
  font-weight: 600;
  font-size: 2rem;
  line-height: 1.1;
  color: var(--white);
}

.form_header_text {
  text-align: center;
  font-family: "Inter", serif;
  font-weight: 500;
  font-size: 1.1rem;
  line-height: 1.4;
  color: var(--white);
}

.form_footer {
  width: 100%;
  height: 100%;
  min-height: 20rem;
  padding: 2rem 2rem 1rem 2rem;
  background: var(--white);
}

.input_rectangle {
  width: 100%;
  height: 48px;
  padding: 0 2rem 0 1rem;
  border-radius: 0.3rem;
  border: 1px solid var(--borderInput);
  background-color: inherit;
  font-family: "Inter", serif;
  font-weight: 500;
  font-size: 1rem;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  max-width: 100%;
  color: var(--frontendFont2) !important;
}

.textarea_rectangle {
  width: 100%;
  padding: 1rem;
  border-radius: 0.3rem;
  border: 1px solid var(--borderInput);
  background-color: inherit;
  font-family: "Inter", serif;
  font-weight: 500;
  font-size: 1rem;
  max-width: 100%;
  color: var(--frontendFont2) !important;
}

.input_text {
  font-family: "Inter", serif;
  font-weight: 500;
  font-size: 1rem;
  line-height: 1.3;
  color: var(--frontendFont2);
}

.required {
  color: var(--red);
}

.input_error {
  border: 1px solid var(--red) !important;
}

.input_rectangle:hover {
  border: 1px solid var(--frontendFont1);
}

.input_rectangle:focus {
  border: 1px solid var(--frontendFont1);
}

.error_text {
  font-family: "Inter", serif;
  font-weight: 500;
  font-size: 0.875rem;
  line-height: 1.3;
  color: var(--red);
}

.button {
  font-family: "Inter", sans-serif;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  font-weight: 500;
  font-size: 1rem;
  height: 3rem;
  border-radius: 0.5rem;
  color: var(--white);
  background-color: var(--frontendFont1);
  border: 1px solid var(--frontendFont1);
  transition: all 0.4s ease;
}

.button:hover {
  background-color: var(--bgColorFrontend);
  border: 1px solid var(--bgColorFrontend);
}

.button:active {
  background-color: var(--frontendFont1);
  border: 1px solid var(--frontendFont1);
}

.file_text {
  width: fit-content;
  font-family: "Inter", serif;
  font-weight: 500;
  font-size: 1.25rem;
  line-height: 1.3;
  color: var(--frontendFont1);
}

.file_input {
  width: 0;
  height: 0;
  display: none;
}

.file_button {
  font-family: "Inter", sans-serif;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  max-width: 8rem;
  min-width: 8rem;
  cursor: pointer;
  font-weight: 500;
  font-size: 1rem;
  height: 3rem;
  border-radius: 0.5rem;
  color: var(--frontendFont1);
  background-color: var(--white);
  border: 2px solid var(--frontendFont1);
  transition: all 0.5s ease;
  margin-bottom: 0px;
}

.file_button:hover {
  color: var(--white);
  background-color: var(--frontendFont1);
  border: 2px solid var(--frontendFont1);
}

.file_button:disabled {
  opacity: 0.5;
}

.file_terms {
  font-family: "Inter", serif;
  font-weight: 500;
  font-size: 0.875rem;
  line-height: 1.3;
  color: var(--frontendFont2);
}

.checkBox {
  padding-left: 0px !important;
}

.checkBox_input {
  width: 1.125rem !important;
  height: 1.125rem !important;
  margin: 0 !important;
  box-shadow: none !important;
  border-color: var(--frontendFont1) !important;
  border-width: 2px;
  cursor: pointer;
}

.checkBox_input:checked {
  width: 1.125rem;
  height: 1.125rem;
  box-shadow: none !important;
  background-color: var(--frontendFont1) !important;
  border-color: var(--frontendFont1);
}

.checkBox_label {
  padding-left: 0.875rem;
  font-family: "Inter", serif;
  font-weight: 500;
  font-size: 0.875rem;
  line-height: 1.3;
  cursor: pointer;
  color: var(--frontendFont2);
}

@media (max-width: 576px) {
  .form_footer {
    padding: 2rem 1rem 1rem 1rem;
  }
}
