.Toolbar {
  width: 100%;
  background-color: var(--bgColorFrontend);
  padding: 1.15rem 0;
  box-sizing: border-box;
}

.Toolbar--sticky {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 100;
  box-shadow: 0 12px 32px rgba(78 153 222 / 18%);
  background-color: var(--bgColorFrontend);
  transition: all 0.5s ease-in-out;
}

.Toolbar nav {
  height: 100%;
  width: 100%;
}

@media (max-width: 1079px) {
  .DesktopOnly {
    display: none;
  }
}
