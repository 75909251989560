.footer_section {
  /* background-image: url("../../Assets//frontendImages/footer.png"); */
  /* background-repeat: no-repeat;
  background-size: cover;
  background-position: center; */
  background-color: var(--bgColorFrontend);
  width: 100%;
  height: 100%;
}

.footer_list_title {
  font-size: 1.75rem;
  letter-spacing: 0.1rem;
  font-weight: 500;
  color: var(--white);
  text-transform: capitalize;
}

.reset_ul {
  list-style-type: none;
  list-style: none;
  padding-left: 0px;
}

.footer_link {
  color: var(--white);
  opacity: 0.85;
  font-size: 1rem;
  font-family: "Poppins", sans-serif;
  line-height: 2.1rem;
  transition: ease-in-out 200ms color;
}

.footer_link:hover {
  opacity: 1;
  color: var(--brandColorFrontend);
}

.footer_copy_write {
  text-align: center;
  color: var(--white);
  opacity: 0.85;
  font-size: 0.875rem;
  font-family: "Poppins", sans-serif;
}

.footer_copy_write:hover {
  opacity: 1;
}

.partnerRatingWrapper {
  margin-top: 2.6rem;
}

@media (max-width: 992px) {
  .partnerRatingWrapper {
    margin-top: 0.8rem;
  }
}

@media (max-width: 576px) {
  .partnerRatingWrapper {
    margin-top: 1.4rem;
  }
}

.partnerRating {
  width: 6rem;
  background-color: var(--backgroundPink);
  cursor: pointer;
}

.partnerRating:hover p {
  opacity: 0.5;
}