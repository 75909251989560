.SideDrawer {
  justify-content: space-between;
  flex-direction: column;
  display: flex;
  position: fixed;
  width: 70%;
  max-width: 70%;
  height: 100vh;
  left: 0;
  top: 0;
  bottom: 0;
  z-index: 300;
  background-color: var(--bgColorFrontend);
  padding: 1rem;
  box-sizing: border-box;
  /* overflow: scroll; */
  transition: transform 0.3s ease-out;
}

@media (min-width: 1079px) {
  .SideDrawer {
    display: none;
  }
}

.Open {
  transform: translateX(0);
}

.Close {
  transform: translateX(-120%);
}
