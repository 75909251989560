.second_section {
  background: #fefbfa;
  width: 100%;
  height: 100%;
}

.heading {
  font-family: "Poppins", serif;
  font-weight: 600;
  font-size: 3rem;
  line-height: 1.1;
  color: var(--frontendFont1);
}

.heading_text {
  font-family: "Inter", serif;
  font-weight: 500;
  font-size: 1.125rem;
  line-height: 1.3;
  color: var(--frontendFont2);
}

.heading_text_brand {
  font-weight: 500;
  color: var(--brandColorFrontend);
}

.section_button {
  width: 100%;
  max-width: fit-content;
  padding: 0.6rem 1.8rem;
  font-weight: 500;
  font-size: 1rem !important;
  white-space: nowrap;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  font-family: "Inter", serif;
  color: var(--brandColor);
  border-radius: 2rem;
  border: 2px solid var(--brandColor);
  background-color: var(--white);
  transition: all 0.3s ease-in;
}

.section_button.is_active {
  color: var(--white);
  background-color: var(--brandColor);
}
.section_button:hover {
  color: var(--white);
  background-color: var(--brandColor);
}

@media (max-width: 992px) {
  .heading {
    font-size: 2.9rem;
  }
  .heading_text {
    font-size: 1.12rem;
  }
  .section_button {
    font-size: 1rem !important;
  }
}

@media (max-width: 768px) {
  .heading {
    font-size: 2.8rem;
  }
  .heading_text {
    font-size: 1.1rem;
  }
}

@media (max-width: 576px) {
  .heading {
    font-size: 2.6rem;
  }
}
