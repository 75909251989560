.section {
  background-color: #fef9f7;
  width: 100%;
  height: 100%;
}

.my_masonry_grid {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  gap: 20px;
  width: auto;
}

.my_masonry_grid_column {
  background-clip: padding-box;
}

.my_masonry_grid_column > div {
  background: var(--white);
  border: 1px solid #d9e3ea;
  padding: 1.5rem 1rem;
  border-radius: 0.5rem;
  margin-bottom: 20px;
}

.my_masonry_grid_column > div:hover {
  background: #f5f8fa;
}

.review_pic_box {
  width: 3rem;
  height: 3rem;
  background-color: var(--brandColor);
  border-radius: 50%;
  contain: content;
  display: flex;
  justify-content: center;
  align-items: center;
}

.review_pic_text {
  font-family: "Poppins", sans-serif;
  font-weight: 600;
  font-size: 1.25rem;
  color: var(--white);
}

.reviewer_name {
  font-family: "Poppins", sans-serif;
  font-weight: 600;
  font-size: 1rem;
  color: var(--frontendFont1);
}

.reviewer_designation {
  font-family: "Inter", sans-serif;
  font-weight: 500;
  font-size: 1rem;
  color: var(--frontendFont2);
}

.review_text {
  font-family: "Inter", sans-serif;
  font-weight: 500;
  font-size: 1rem;
  color: var(--frontendFont2);
}
