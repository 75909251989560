.error_container {
  width: 100%;
  height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  padding: 15px;
}

.heading {
  text-align: center;
  font-family: "Poppins", serif;
  font-weight: 500;
  font-size: 2.5rem;
  line-height: 1.3;
  color: var(--frontendFont1);
  margin: 1rem 0;
}

.image {
  max-width: 500px;
  object-fit: contain;
}

.sub_heading {
  text-align: center;
  font-family: "Poppins", serif;
  font-weight: 500;
  font-size: 1.1rem;
  line-height: 1.5;
  color: var(--frontendFont1);
  margin-bottom: 1.5rem;
}

.contact_support {
  font-weight: 600;
  color: var(--brandColor);
  cursor: pointer;
  padding: 0 4px;
}

.button {
  max-width: 180px;
  height: 3.15rem;
  display: flex;
  font-weight: 500;
  font-size: 1.1rem;
  justify-content: center;
  align-items: center;
  color: var(--white) !important;
  padding: 0 20px;
  background: var(--brandColor);
  border-radius: 9px;
  transition: ease-in-out 200ms all;
}

.button:hover {
  background: var(--hoverButtonColor);
}

@media (max-width: 992px) {
  .heading {
    font-size: 2.3rem;
  }
  .sub_heading {
    font-size: 1.05rem;
  }
  .button {
    font-size: 1.05rem;
  }
}

@media (max-width: 768px) {
  .heading {
    font-size: 2.1rem;
  }
  .sub_heading {
    font-size: 1.02rem;
  }
  .button {
    font-size: 1.2rem;
  }
}

@media (max-width: 576px) {
  .heading {
    font-size: 2rem;
  }
  .sub_heading {
    font-size: 1rem;
  }
  .button {
    font-size: 1rem;
  }
}
