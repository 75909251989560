.section {
  background-color: var(--white);
  width: 100%;
  height: 100%;
  min-height: calc(100vh - 80px);
  display: flex;
  justify-content: center;
  align-items: center;
}

.heading {
  font-family: "Poppins", sans-serif;
  font-weight: 600;
  font-size: 3rem;
  line-height: 1.3;
  color: var(--frontendFont1);
}

.heading_text {
  font-family: "Inter", sans-serif;
  font-weight: 500;
  font-size: 1.25rem;
  line-height: 1.5;
  color: var(--frontendFont2);
}

.more_button {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  width: 10.5rem;
  font-weight: 500;
  font-size: 1.125rem;
  padding: 0.6rem 0.5rem 0.6rem 1.5rem;
  border-radius: 2rem;
  font-family: "Inter", sans-serif;
  color: var(--white);
  background-color: var(--brandColorFrontend);
  border: 2px solid var(--brandColorFrontend);
  transition: all 0.5s ease;
}

.more_button:hover {
  background-color: var(--brandColor);
  color: var(--white);
  border: 2px solid var(--brandColor);
}

.farword_arrow {
  margin-left: 0.75rem;
  transition: margin-left 0.4s ease;
}
.more_button:hover .farword_arrow {
  margin-left: 1.25rem;
}

.text_font2 {
  font-family: "Inter", sans-serif;
  font-weight: 500;
  font-size: 1rem;
  line-height: 1.4;
  color: var(--frontendFont2);
}

@media (max-width: 992px) {
  .heading {
    font-size: 2.75rem;
  }
  .heading_text {
    font-size: 1.2rem;
  }
}

@media (max-width: 768px) {
  .heading {
    font-size: 2.5rem;
  }
  .heading_text {
    font-size: 1.15rem;
  }
}

@media (max-width: 576px) {
  .heading {
    font-size: 2rem;
  }
  .heading_text {
    font-size: 1.1rem;
  }
}
