.circle {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 2.5rem;
  height: 2.5rem;
  cursor: pointer;
  border-radius: 50%;
  background-color: #fefbfa;
  border: 2px solid var(--brandColor);
}
.circle:hover {
  background-color: var(--brandColor);
}

.upBtn {
  color: var(--brandColor);
  font-size: 25px;
  font-weight: 500;
}

.circle:hover .upBtn {
  color: var(--white);
}
