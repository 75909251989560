.page_404 {
  font-family: Poppins, serif;
  width: 100%;
  height: 100vh;
}

.innerPage {
  height: calc(100vh - 80px);
}

.page_404_image {
  max-width: 22.5rem;
  
  @media (max-width: 576px) {
    max-width: 17rem;
  }
}

.link_404 {
  text-decoration: none;
  height: 3.15rem;
  display: flex;
  justify-content: center;
  align-items: center;
  color: var(--white) !important;
  padding: 0 20px;
  background: var(--brandColor);
  border-radius: 5px;
  transition: ease-in-out 200ms all;
}

.link_404:hover {
  background: var(--bgButtonHoverColor);
}
