.hero_section {
  background-color: var(--bgColorFrontend);
  min-height: calc(100vh - 82px);
  display: flex;
  align-items: center;
}

.btn_begin {
  font-family: "Poppins", sans-serif;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  max-width: 10rem;
  height: 3.15rem;
  padding: 0 1.5rem;
  border-radius: 2rem;
  background-color: var(--brandColorFrontend);
  color: var(--white);
  font-weight: 600;
  font-size: 1.1rem;
  transition: all 0.5s ease-in;
}

.btn_begin:hover {
  /* background-color: var(--white);
  color: var(--brandColorFrontend); */
  color: var(--white);
  background: var(--brandColor);
}

.btn_begin:active {
  background-color: var(--brandColor);
  color: var(--white);
}

.heading {
  font-family: "Poppins", serif;
  font-weight: 600;
  font-size: 2.5rem;
  color: var(--white);
  line-height: 1.33;
}

.italic {
  font-family: "Poppins", serif;
  font-weight: 600;
  font-style: italic;
  font-size: 2.5rem;
  margin-right: 0.3rem;
  color: var(--brandColorFrontend);
}

.typingAnimation {
  font-family: "Poppins", serif;
  font-weight: 600;
  font-size: 2.5rem;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: var(--white);
}

.text {
  font-family: "Inter", serif;
  font-weight: 500;
  font-size: 1rem;
  color: var(--white);
}

@media (max-width: 992px) {
  .heading {
    font-size: 2.3rem;
  }

  .italic {
    font-size: 2.3rem;
  }
  .typingAnimation {
    font-size: 2.3rem;
  }
}

@media (max-width: 992px) {
  .heading {
    font-size: 2.1rem;
  }

  .italic {
    font-size: 2.1rem;
  }
  .typingAnimation {
    font-size: 2.1rem;
  }
}

@media (max-width: 576px) {
  .heading {
    font-weight: 500;
    font-size: 2rem;
  }

  .italic {
    font-weight: 500;
    font-size: 2rem;
  }
  .typingAnimation {
    font-weight: 500;
    font-size: 2rem;
  }
}

@media (max-width: 400px) {
  .heading {
    font-weight: 500;
    font-size: 1.5rem;
  }

  .italic {
    font-weight: 500;
    font-size: 1.5rem;
  }
  .typingAnimation {
    font-weight: 500;
    font-size: 1.5rem;
  }
}
