/* global colors for main pages */
:root {
  --brandColor: #6c3e83;
  --bgColorFrontend: #2c1338;
  --brandColorFrontend: #e57cd8;
  --bgButtonHoverColor: #564260;
  --bgButtonColor: #fce5d8;
  --font1: #1a1a1a;
  --frontendFont1: #412a4c;
  /* --font2: #7d7e82; */
  --font2: #333;
  --frontendFont2: #6b5a74;
  --borderInput: #d1d1d1;
  --backgroundGrey: #f6f7fb;
  --white: #ffffff;
  --tableHeader: #ebebef;
  --red: #f46237;
  --backgroundPink: #f2ecfb;
  --success: #7fb97a;
  --borderInput: #d1d1d1;
  --hoverButtonColor: #5b336f;

  --formInputHeight: 3.15rem;
  --formInputBorderRadius: 0.75rem;
}

*,
*:before,
*:after {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  font-family: "Poppins", sans-serif;
}

:focus {
  outline: none;
}

/* do not remove dropdow class  */

input {
  border: 0;
  outline: none;
}

button {
  background-color: transparent;
  border: 0;
  cursor: pointer;
  outline: none;
  padding: 0;
}

button,
input {
  overflow: visible;
}

.dropdown {
  display: inline-block;
}

/* to make fullscreen modal, which is very important class so please don't remove */
.ToMakeFullscreenModal {
  width: 100vw !important;
  max-width: none !important;
  height: 100% !important;
  margin: 0 !important;
}

.ToMakeModalOnRight500px {
  position: fixed !important;
  max-width: 500px !important;
  width: 100% !important;
  margin: 0 !important;
  right: 0;
  top: 0;
  bottom: 0 !important;
}

.ToMakeModalOnRight350px {
  position: fixed !important;
  width: 100% !important;
  max-width: 350px !important;
  margin: 0 !important;
  right: 0;
  top: 0;
  bottom: 0 !important;
}

.ToMakeModalOnRight600px {
  position: fixed !important;
  max-width: 600px !important;
  width: 100% !important;
  margin: 0 !important;
  right: 0;
  top: 0;
  bottom: 0 !important;
}

.ToMakeModalWidth600px {
  max-width: 600px !important;
}

.ToMakeModalBorderRudius12px {
  border-radius: 12px !important;
}

.email-template-popover .ant-popover-content .ant-popover-inner {
  max-width: 250px;
  max-height: 300px;
  overflow-y: auto;
}

.three-dots {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  max-width: 100%;
}

.truncate-text {
  display: -webkit-box;
  -webkit-line-clamp: 3; /* Limit to 3 lines */
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: normal;
}

.ant-message-notice-content {
  padding: 15px 20px !important;
  font-family: "Poppins", sans-serif;
}

.font-weight-500 {
  font-weight: 500;
}
.font-weight-600 {
  font-weight: 600;
}
.font-weight-700 {
  font-weight: 700;
}

.modal-width-1000 {
  max-width: 1000px !important;
}

/* Custom Modal Dialog Animation */
.custom-modal .modal-dialog {
  transition: transform 0.2s ease-in !important;
  transform: translateY(-100%) !important;
}

.custom-modal.show .modal-dialog {
  transform: translateY(0) !important;
}

/* Custom Backdrop Animation (Sliding and Fading) */
.custom-modal .modal-backdrop {
  transition: opacity 0.2s ease-in, transform 0.2s ease-in !important;
  opacity: 0;
  transform: translateY(-100%) !important; /* Slide from top */
}

.custom-modal.show .modal-backdrop {
  opacity: 1 !important;
  transform: translateY(0) !important; /* Slide into place */
}
