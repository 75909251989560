.Backdrop {
  display: block;
  width: 100%;
  height: 100vh;
  position: fixed;
  z-index: 200;
  top: 0;
  transform: translateX(-100%);
  background-color: rgb(0 0 0 / 74%);
  transition: transform 0.3s ease-in-out;
}

.Backdrop.show {
  transform: translateX(0%);
}

@media (min-width: 1080px) {
  .Backdrop {
    display: none;
  }
}
