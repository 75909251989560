.section {
  background-color: #fff3f2;
  height: 100%;
  width: 100%;
  display: flex;
  align-items: center;
}

.heading {
  font-family: "Poppins", sans-serif;
  font-weight: 600;
  font-size: 3.5rem;
  line-height: 1.1;
  color: var(--frontendFont1);
}

.text_font1 {
  font-family: "Inter", sans-serif;
  font-weight: 500;
  font-size: 1.2rem;
  line-height: 1.4;
  color: var(--frontendFont1);
}

.feature_container {
  width: 100%;
  height: 100%;
  max-width: 220px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 3rem 0.5rem;
  border-radius: 0.75rem;
  background-color: inherit;
  margin-bottom: 1.875rem;
  background: var(--white);
  box-shadow: 0px 2px 2px 2px rgba(220, 175, 166, 0.2);
}

.feature_container_number {
  font-family: "Poppins", sans-serif;
  font-weight: 500;
  font-size: 1.8rem;
  line-height: 1.2;
  color: var(--frontendFont1) !important;
}

.container_text {
  font-family: "Inter", sans-serif;
  font-weight: 500;
  font-size: 1.1rem;
  color: var(--frontendFont2);
}

@media (max-width: 992px) {
  .heading {
    font-size: 3rem;
  }
  .text_font1 {
    font-size: 1.15rem;
  }
  .feature_container_number {
    font-size: 1.7rem;
  }
  .container_text {
    font-size: 1.05rem;
  }
}

@media (max-width: 768px) {
  .heading {
    font-size: 2.75rem;
  }
  .text_font1 {
    font-size: 1.1rem;
  }
  .feature_container_number {
    font-size: 1.6rem;
  }
  .container_text {
    font-size: 1rem;
  }
}

@media (max-width: 576px) {
  .container_padding {
    padding: 2rem 1rem;
  }
  .heading {
    font-size: 2.5rem;
  }
  .feature_container_number {
    font-size: 1.5rem;
  }
}
