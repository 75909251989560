.homePageCTACarousel .slick-slide {
  padding: 2rem 1rem;
  text-align: center;
  display: flex !important;
  justify-content: center;
}

.homePageCTACarousel .feature_container {
  position: relative;
  width: 100%;
  max-width: 24rem;
  height: 100%;
  border-radius: 0.75rem;
  padding: 2rem 1.5rem;
  display: flex !important;
  flex-direction: column;
  gap: 0.75rem;
}

.homePageCTACarousel .feature_container[data-color="primary"] {
  background-color: #fae5f7;
}

.homePageCTACarousel .feature_container[data-color="secondary"] {
  background: var(--bgButtonColor) !important;
}

.homePageCTACarousel .prev_arrow {
  position: absolute;
  z-index: 10;
  top: -2rem;
  left: 1rem;
  cursor: pointer;
}

.homePageCTACarousel .prev_arrow .button {
  color: var(--brandColorFrontend);
  font-size: 3rem;
}

.homePageCTACarousel .next_arrow {
  position: absolute;
  z-index: 10;
  top: -2rem;
  right: 1rem;
  cursor: pointer;
}
.homePageCTACarousel .next_arrow .button {
  color: var(--brandColorFrontend);
  font-size: 3rem;
}

.homePageCTACarousel .container_icons {
  width: 30px;
}

.homePageCTACarousel .container_heading {
  font-family: "Poppins", serif;
  font-weight: 600;
  font-size: 1.5rem;
  line-height: 1.35;
  color: var(--frontendFont1);
}

.homePageCTACarousel .container_text {
  font-family: "Inter", serif;
  font-weight: 500;
  font-size: 1.125rem;
  line-height: 1.7;
  color: var(--frontendFont2);
}

.homePageCTACarousel .hover_image {
  position: relative;
  visibility: hidden;
  transform: rotate(-6deg) translate(45px, 25px);
  margin: 5px 20px 10px;
  width: 200px;
  text-align: center;
  transition: 0.3s ease-in-out;
  color: var(--bgColorFrontend);
}
.homePageCTACarousel .hover_image::before {
  content: "";
  display: block;
  position: absolute;
  z-index: 0;
  right: 0px;
  bottom: -5px;
  width: 130%;
  height: 170%;
  -webkit-background-size: 100% 100%;
  background-size: 100% 100%;
  background-repeat: no-repeat;
  -webkit-transform: rotate(5deg);
  -moz-transform: rotate(5deg);
  -ms-transform: rotate(5deg);
  transform: rotate(5deg);
}

.homePageCTACarousel .hover_image[data-color="pink"]::before {
  background-image: url("../../../../Assets//frontendImages//CTAHoverImage2.svg");
}
.homePageCTACarousel .hover_image[data-color="yellow"]::before {
  background-image: url("../../../../Assets//frontendImages//CTAHoverImage1.svg");
}

.homePageCTACarousel .hover_content {
  font-family: "Inter", sans-serif;
  line-height: 1.2;
  position: relative;
  z-index: 20;
  color: var(--bgColorFrontend);
  font-weight: 500;
  font-size: 1.25rem;
}

.homePageCTACarousel .feature_container:hover .hover_image {
  visibility: visible;
}

.homePageCTACarousel .container_image_wrapper {
  width: 100%;
  transform: translateY(3.2rem);
  border-radius: 0.5rem;
  overflow: hidden;
  vertical-align: top;
  position: relative;
}

.homePageCTACarousel .container_image {
  width: 100%;
  position: static;
  height: 100%;
  max-height: 190px;
  object-fit: cover;
}

.homePageCTACarouselDots {
  width: 100%;
  display: flex !important;
  justify-content: center;
  align-items: center;
  list-style: none;
  padding: 0.5rem 0 0 0 !important;
}

.homePageCTACarouselDots li {
  margin: 0 0.5rem;
  display: flex;
}

.homePageCTACarouselDots li button {
  width: 1rem;
  height: 1rem;
  border-radius: 50%;
  background-color: var(--brandColorFrontend);
  border: none;
  cursor: pointer;
}

.homePageCTACarouselDots li button:hover {
  background-color: var(--brandColor);
}

.homePageCTACarouselDots li.slick-active button {
  background-color: var(--brandColor);
}

@media (max-width: 992px) {
  .homePageCTACarousel .container_heading {
    font-size: 1.4rem;
  }
  .homePageCTACarousel .container_text {
    font-size: 1.12rem;
  }
  .homePageCTACarousel .hover_content {
    font-size: 1.2rem;
  }
}

@media (max-width: 768px) {
  .homePageCTACarousel .container_heading {
    font-size: 1.3rem;
  }
  .homePageCTACarousel .container_text {
    font-size: 1.1rem;
  }
  .homePageCTACarousel .hover_content {
    font-size: 1.15rem;
  }
}

@media (max-width: 576px) {
  .homePageCTACarousel .prev_arrow {
    top: -2rem;
    left: 0rem;
  }

  .homePageCTACarousel .next_arrow {
    top: -2rem;
    right: 0rem;
  }
  .homePageCTACarousel .slick-slide {
    padding: 2rem 0.5rem;
  }
  .homePageCTACarousel .container_heading {
    font-size: 1.25rem;
  }
  .homePageCTACarousel .container_text {
    font-size: 1rem;
  }
  .homePageCTACarousel .hover_content {
    font-size: 1.1rem;
  }
}
