.custum-date-picker-three {
  width: 100%;
  height: fit-content;
  padding: 0.2rem 0rem;
  border: none;
  background-color: transparent;
  color: var(--font1);
  cursor: pointer !important;
  font-weight: 400;
  font-family: "Poppins", sans-serif;
  font-size: 0.875rem;
}

.custum-date-picker-three input {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  max-width: 100%;
  text-align: center;
  cursor: pointer !important;
}

/* Ensure placeholder is visible as text when empty */
.custum-date-picker-three input::placeholder {
  color: var(--font1) !important; /* Adjust color of the placeholder */
  opacity: 1 !important;
}

.custum-date-picker-three:hover,
.custum-date-picker-three:focus,
.custum-date-picker-three:focus-within {
  box-shadow: none !important;
  outline: none !important;
  border: none !important;
  /* background-color: transparent; */
}

.custum-date-picker-three .ant-picker-clear {
  color: var(--brandColor);
}

.custum-date-picker-three .ant-picker-clear:hover {
  color: var(--brandColor);
}

.custum-date-picker-three-popup {
  font-family: "Poppins", sans-serif;
  font-size: 0.875rem;
  font-weight: 500;
  z-index: 1100;
}

.custum-date-picker-three-popup .ant-picker-header-view {
  font-size: 1rem;
  font-weight: 600;
  color: var(--font1);
}

.custum-date-picker-three-popup .ant-picker-header-view > button:hover {
  color: var(--brandColor) !important;
}

.custum-date-picker-three-popup .ant-picker-content tr th {
  font-family: "Poppins", sans-serif;
  font-size: 0.875rem;
  font-weight: 600;
  color: var(--brandColor);
}

.custum-date-picker-three-popup .ant-picker-cell {
  font-family: "Poppins", sans-serif;
  font-size: 0.875rem;
  font-weight: 500 !important;
}

.custum-date-picker-three-popup
  .ant-picker-cell-in-view.ant-picker-cell-today:not(.ant-picker-cell-disabled)
  .ant-picker-cell-inner::before {
  border: 2px solid var(--brandColor);
}

.custum-date-picker-three-popup
  .ant-picker-cell-in-view.ant-picker-cell-selected:not(
    .ant-picker-cell-disabled
  )
  .ant-picker-cell-inner {
  color: var(--white) !important;
  background-color: var(--brandColor) !important;
}

.custum-date-picker-three-popup
  .ant-picker-cell:hover:not(.ant-picker-cell-selected):not(
    .ant-picker-cell-range-start
  ):not(.ant-picker-cell-range-end)
  .ant-picker-cell-inner {
  background: var(--backgroundPink) !important;
}

.custum-date-picker-three-popup .ant-picker-ranges > li > a {
  text-decoration: none !important;
  color: var(--brandColor) !important;
}
