.backdrop {
  display: none;
  position: fixed;
  top: 4.25rem;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(108, 62, 131, 0.5);
  z-index: 200;
  transform: translateX(-100%);
  transition: transform 0.4s ease-out;
}

.backdrop.show {
  transform: translateX(0%);
}

@media screen and (max-width: 992px) {
  .backdrop {
    display: block;
  }
}
