.section {
  background: #fefbfa;
  width: 100%;
  height: 100%;
}

.heading_section {
  background: var(--bgColorFrontend);
  width: 100%;
  height: 100%;
  min-height: 200px;
  display: flex;
  align-items: center;
}

.heading {
  text-align: center;
  font-family: "Poppins", sans-serif;
  font-weight: 500;
  font-size: 3rem;
  line-height: 1.3;
  color: var(--white);
}

.heading_text {
  text-align: center;
  font-family: "Inter", sans-serif;
  font-size: 1.25rem;
  line-height: 1.6;
  color: var(--white);
  margin-top: 1rem;
}

.menu_title {
  font-size: 1.5rem;
  color: var(--frontendFont1);
  font-weight: 600;
  font-family: "Poppins", sans-serif;
}

.menu_item {
  border-bottom-style: dashed;
  border-bottom-width: 1px;
  border-bottom-color: var(--frontendFont1);
  font-size: 1.2rem;
  cursor: pointer;
  color: var(--frontendFont1);
  font-family: "Inter", sans-serif;
  transition: ease-in-out 200ms color;
}
.menu_counter {
  position: absolute;
  margin-left: -30px;
  font-size: 1.2rem;
  color: var(--frontendFont1);
  font-family: "Inter", sans-serif;
}

.menu_item:hover {
  border-bottom-color: var(--white);
  color: var(--brandColorFrontend);
}

.content_title {
  margin-bottom: 1rem;
  font-size: 2rem;
  line-height: 1.3;
  color: var(--frontendFont1);
  font-weight: 600;
  font-family: "Poppins", sans-serif;
}

.content_text {
  text-align: justify;
  line-height: 1.67;
  font-size: 1rem;
  color: var(--frontendFont2);
  font-weight: 500;
  font-family: "Inter", sans-serif;
}

.content_text_strong {
  font-weight: 600;
  font-size: 1.1rem;
  margin-right: 6px;
}
.email {
  color: var(--brandColorFrontend);
}
.email:hover {
  color: var(--brandColorFrontend);
}
.up_arrow {
  position: fixed;
  bottom: 60px;
  right: 30px;
}
.numbered_list {
  list-style: none;
  counter-reset: list-counter;
}
.numbered_list li {
  counter-increment: list-counter;
  margin-bottom: 10px;
  display: flex;
  gap: 8px;
}
.numbered_list li::before {
  content: counter(list-counter) ". ";
  font-weight: bold;
}

@media (max-width: 992px) {
  .heading_section {
    min-height: 190px;
  }
  .heading {
    font-size: 2.275rem;
  }
  .heading_text {
    font-size: 1.2rem;
  }
}
@media (max-width: 768px) {
  .heading_section {
    min-height: 180px;
  }
  .heading {
    font-size: 2.5rem;
  }
  .heading_text {
    font-size: 1.15rem;
  }
}
@media (max-width: 576px) {
  .heading_section {
    min-height: 170px;
  }
  .heading {
    font-size: 2rem;
  }
  .heading_text {
    font-size: 1.1rem;
  }
  .up_arrow {
    position: fixed;
    bottom: 20px;
    right: 10px;
  }
}
