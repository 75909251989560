.heading {
  font-family: "Poppins", sans-serif;
  font-weight: 600;
  font-size: 2.2rem;
  line-height: 1.3;
  color: var(--frontendFont1);
}

.text {
  font-family: "Inter", sans-serif;
  font-weight: 500;
  font-size: 1.1rem;
  line-height: 1.3;
  color: var(--frontendFont1);
}
