.section {
  background: #fff3f2;
  width: 100%;
  height: 100%;
}

.heading {
  font-family: "Poppins", sans-serif;
  font-size: 2.2rem;
  font-weight: 600;
  line-height: 1.3;
  color: var(--frontendFont1);
}

.text_font1 {
  font-family: "Inter", sans-serif;
  font-weight: 500;
  font-size: 1.1rem;
  line-height: 1.5;
  color: var(--frontendFont2);
}

.more_button {
  width: fit-content;
  font-weight: 500;
  font-size: 1.1rem;
  padding: 0.4rem 2rem;
  border-radius: 1.5rem;
  font-family: "Inter", sans-serif;
  color: var(--white);
  background-color: var(--brandColorFrontend);
  border: 2px solid var(--brandColorFrontend);
  transition: all 0.4s ease;
}

.more_button:hover {
  background-color: var(--brandColor);
  border: 2px solid var(--brandColor);
  color: var(--white);
}

.farword_arrow {
  margin-left: 0.5rem;
  transition: margin-left 0.4s ease;
}
.more_button:hover .farword_arrow {
  margin-left: 1.5rem;
}

@media (max-width: 992px) {
  .heading {
    font-size: 2rem;
  }
  .text_font1 {
    font-size: 1rem;
  }
  .more_button {
    font-size: 1rem;
  }
  .text_font2 {
    font-size: 1rem;
  }
}

@media (max-width: 768px) {
  .heading {
    font-size: 1.8rem;
  }
}

@media (max-width: 576px) {
  .heading {
    font-size: 1.5rem;
  }
}
