.mobile-view {
  display: none;
  font-weight: 500;
  background-color: var(--bgButtonColor);
  border-radius: 0.75rem;
  box-shadow: 0 12px 32px rgba(103, 142, 178, 0.16);
  padding: 0.75rem 1.5rem !important;
  position: absolute;
  z-index: 10;
  list-style-type: none;
  line-height: 29px;
  width: 15.625rem;
  min-height: fit-content;
  max-height: 25rem;
  overflow-x: hidden;
  overflow-y: auto;
}

/* .Nav-Submenu-mobile:hover .mobile-view {
  display: block;
  left: 1.875rem;
} */

.mobile-view-open {
  display: block;
  left: 1.875rem;
}

.features {
  font-size: 1rem;
  font-weight: 600;
  font-family: Poppins, serif;
  color: var(--white);
}

.Nav-Submenu-mobile:hover .features {
  color: var(--brandColorFrontend);
}

/* .Nav-Submenu-mobile:hover svg {
  transform: rotate(180deg);
} */

.Nav-link-ul {
  font-weight: 500;
  display: flex;
  width: 100%;
  font-size: 1rem;
  border-radius: 8px;
  line-height: 2.5;
  color: var(--bgColorFrontend);
  padding: 2px 8px;
  font-family: Poppins, serif;
  transition: ease-in-out 200ms all;
}

.mobile-view li {
  padding: 0;
  transition: ease-in-out 200ms all;
  width: 13.75rem;
}

.mobile-view li:hover .Nav-link-ul {
  background-color: var(--bgButtonColor);
  color: var(--brandColorFrontend);
  padding-left: 1.5rem;
  width: 100%;
}

a.Nav-link-mobile.is-active {
  color: var(--brandColorFrontend);
}

a.Nav-linkli-mobile.active {
  color: var(--brandColorFrontend);
}

.Nav-mainMenu-mobile {
  padding-top: 0.75rem;
}

.ul-reset-mobile {
  list-style-type: none;
  padding-inline-start: 10px;
}

.Nav-Submenu-mobile {
  margin-bottom: 10px;
}

.link-mobile-t {
  padding-top: 15px;
  border-top: 2px solid #ececec;
}

a.Nav-link-ul.is-active {
  color: var(--brandColorFrontend);
}

.link-mobile {
  display: flex;
  width: 100%;
  padding-top: 15px;
  border-top: 2px solid #ececec;
  padding-bottom: 15px;
}

.Nav-signUp-mobile {
  margin-bottom: 10px;
}

.Nav-webApp-link-mobile {
  color: var(--white);
}

.Nav-link-mobile {
  width: 100%;
  font-size: 1rem;
  font-family: Poppins, serif;
  font-weight: 600;
  color: var(--white);
}

.link-mobile:hover .Nav-link-mobile {
  color: var(--brandColorFrontend);
  transition: ease-in-out 300ms all;
}

.Nav-signUp-mobile .Nav-webApp-link--signUp {
  font-weight: 600;
  font-size: 1rem;
  border-radius: 2rem;
  font-family: Poppins, serif;
  background: var(--brandColorFrontend);
  color: var(--white);
  pointer-events: all;
  transition: ease-in-out 300ms all;
}

.Nav-signUp-mobile .Nav-webApp-link--signUp:hover {
  background: var(--bgButtonHoverColor);
}

.Nav-logIn-mobile .Nav-webApp-link--signIn {
  font-weight: 600;
  font-size: 1rem;
  font-family: Poppins, serif;
  color: var(--brandColorFrontend);
  border: 2px solid var(--brandColorFrontend);
  transition: ease-in-out 300ms all;
}

.Nav-logIn-mobile .Nav-webApp-link--signIn:hover {
  background: var(--bgButtonHoverColor);
}

@media (max-width: 576px) {
  .mobile-view {
    max-width: 14.375rem;
    padding: 12px 6px !important;
  }
}
