.custum-range-picker-box {
  height: 2.75rem;
  width: 100%;
  display: flex;
  align-items: center;
  border: 1px solid var(--borderInput);
  background-color: var(--white);
  padding: 6px 9px;
  border-radius: 6px;
  cursor: pointer;
  font-weight: 400;
  font-family: "Poppins", sans-serif;
  font-size: 0.875rem;
}

.custum-range-picker-box:hover {
  border: 1px solid var(--brandColor);
}

.custum-range-picker-box .dropdown-icon {
  font-size: 14px !important;
  color: var(--font2) !important;
  opacity: 0.7;
}

.custum-range-picker-box .button-hover:hover {
  background-color: var(--backgroundPink) !important;
}

.custum-range-dropdown .ant-dropdown-menu {
  max-height: 350px;
  overflow-y: auto;
  padding: 9px 6px;
}

.custum-range-dropdown .ant-dropdown-menu .ant-dropdown-menu-item-selected {
  background-color: var(--backgroundPink) !important;
  color: var(--brandColor);
}

.custum-range-dropdown .ant-dropdown-menu .ant-dropdown-menu-item:hover {
  background-color: var(--backgroundPink) !important;
}

.custom-range-picker {
  height: 0px !important;
  padding: 0px !important;
  width: 0px !important;
  border: none !important;
  outline: none !important;
  background: transparent;
  position: absolute;
}

.custom-range-picker-popup {
  font-family: Poppins, serif;
  font-size: 0.875rem;
  font-weight: 400;
  background: var(--white);
  color: var(--font1);
}

.custom-range-picker-popup .ant-picker-header-view button {
  font-family: Poppins, serif;
  font-size: 0.875rem;
  font-weight: 600;
  color: var(--font1);
}

.custom-range-picker-popup .ant-picker-header-view button:hover {
  color: var(--brandColor);
}

.custom-range-picker-popup .ant-picker-content tr th {
  font-family: Poppins, serif;
  font-size: 0.875rem;
  font-weight: 600;
  color: var(--brandColor);
}

.custom-range-picker-popup .ant-picker-cell {
  font-family: Poppins, serif;
  font-size: 0.812rem;
  font-weight: 500 !important;
}

.custom-range-picker-popup .ant-picker-cell::after {
  border-color: var(--brandColor) !important;
}

.custom-range-picker-popup .ant-picker-cell-inner::before {
  border-color: var(--brandColor) !important;
  color: var(--brandColor);
}

.custom-range-picker-popup
  .ant-picker-cell-in-view.ant-picker-cell-range-start
  .ant-picker-cell-inner {
  color: var(--white);
  background: var(--brandColor) !important;
}

.custom-range-picker-popup
  .ant-picker-cell-in-view.ant-picker-cell-range-end
  .ant-picker-cell-inner {
  color: var(--white);
  background: var(--brandColor) !important;
}

.custom-range-picker-popup
  .ant-picker-cell-in-view.ant-picker-cell-range-start:not(
    .ant-picker-cell-range-start-single
  )::before {
  background: var(--backgroundPink) !important;
}

.custom-range-picker-popup
  .ant-picker-cell-in-view.ant-picker-cell-range-end:not(
    .ant-picker-cell-range-end-single
  )::before {
  background: var(--backgroundPink) !important;
}

.custom-range-picker-popup
  .ant-picker-cell-in-view.ant-picker-cell-range-hover-end
  .ant-picker-cell-selected::before:hover {
  background: var(--brandColor) !important;
}

.custom-range-picker-popup
  .ant-picker-cell-in-view.ant-picker-cell-range-hover-end
  .ant-picker-cell-inner {
  font-weight: 500 !important;
  color: var(--brandColor) !important;
  background: var(--backgroundPink) !important;
}

.custom-range-picker-popup
  .ant-picker-cell-in-view.ant-picker-cell-in-range::before {
  background: var(--backgroundPink) !important;
}

.custom-range-picker-popup
  .ant-picker-cell-in-view.ant-picker-cell-in-range
  .ant-picker-cell-inner {
  font-weight: 500 !important;
  color: var(--brandColor) !important;
  background: var(--backgroundPink) !important;
}

.custom-range-picker-popup
  .ant-picker-cell-in-view.ant-picker-cell-in-range
  .ant-picker-cell-range-hover:before {
  color: var(--white) !important;
  background: var(--brandColor) !important;
}

.custom-range-picker-popup
  .ant-picker-cell-in-view.ant-picker-cell-in-range
  .ant-picker-cell-range-hover-start,
.ant-picker-cell-inner::after {
  border-radius: 12px;
  background: var(--backgroundPink) !important;
}

.custom-range-picker-popup
  .ant-picker-cell-in-view.ant-picker-cell-in-range
  .ant-picker-cell-range-hover-end,
.ant-picker-cell-inner::after {
  border-radius: 12px;
  background: var(--backgroundPink) !important;
}

@media (max-width: 992px) {
  .custom-range-picker-popup .ant-picker-panels {
    width: 300px !important;
    flex-wrap: wrap !important;
  }
}
