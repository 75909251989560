.custom-date-picker-two-btn {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 1rem 0.75rem;
  cursor: pointer;
  font-weight: 400;
  font-family: "Poppins", sans-serif;
  font-size: 0.875rem;
}

.custom-date-picker-two-btn .clear-icon {
  opacity: 0;
  transition: opacity 0.3s ease;
}

.custom-date-picker-two-btn:hover .clear-icon {
  opacity: 1;
}

.custum-date-picker-two-popup {
  font-family: "Poppins", sans-serif;
  font-size: 0.875rem;
  font-weight: 500;
}

.custum-date-picker-two-popup .ant-picker-header-view {
  font-size: 1rem;
  font-weight: 600;
  color: var(--font1);
}

.custum-date-picker-two-popup .ant-picker-header-view > button:hover {
  color: var(--brandColor) !important;
}

.custum-date-picker-two-popup .ant-picker-content tr th {
  font-family: "Poppins", sans-serif;
  font-size: 0.875rem;
  font-weight: 600;
  color: var(--brandColor);
}

.custum-date-picker-two-popup .ant-picker-cell {
  font-family: "Poppins", sans-serif;
  font-size: 0.875rem;
  font-weight: 500 !important;
}

.custum-date-picker-two-popup
  .ant-picker-cell-in-view.ant-picker-cell-today:not(.ant-picker-cell-disabled)
  .ant-picker-cell-inner::before {
  border: 2px solid var(--brandColor);
}

.custum-date-picker-two-popup
  .ant-picker-cell-in-view.ant-picker-cell-selected:not(
    .ant-picker-cell-disabled
  )
  .ant-picker-cell-inner {
  color: var(--white) !important;
  background-color: var(--brandColor) !important;
}

.custum-date-picker-two-popup
  .ant-picker-cell:hover:not(.ant-picker-cell-selected):not(
    .ant-picker-cell-range-start
  ):not(.ant-picker-cell-range-end)
  .ant-picker-cell-inner {
  background: var(--backgroundPink) !important;
}

.custum-date-picker-two-popup .ant-picker-ranges > li > a {
  text-decoration: none !important;
  color: var(--brandColor) !important;
}
