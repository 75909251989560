.section {
  background: #fefbfa;
  width: 100%;
  height: 100%;
}

.last_update {
  font-size: 1rem;
  color: var(--frontendFont1);
  font-weight: 500;
  font-family: "Poppins", sans-serif;
}

.heading {
  text-align: center;
  margin-bottom: 1.5rem;
  font-size: 2rem;
  color: var(--frontendFont1);
  font-weight: 600;
  font-family: "Poppins", sans-serif;
}

.menu_box {
  width: 30%;
  position: sticky;
  top: 100px;
  height: calc(100vh - 100px);
  overflow-y: auto;
}

.content_box {
  width: 70%;
  cursor: pointer;
}

.menu_title {
  font-size: 1.3rem;
  color: var(--frontendFont1);
  font-weight: 600;
  font-family: "Poppins", sans-serif;
}

.menu_item {
  text-decoration: none;
  font-size: 0.875rem;
  line-height: 1.35;
  display: flex;
  align-items: center;
  cursor: pointer;
  padding: 6px 12px;
  border-radius: 8px;
  color: var(--frontendFont2);
  font-weight: 500;
  font-family: "Inter", sans-serif;
  transition: ease-in-out 200ms color;
}
.menu_item_selected {
  color: var(--brandColorFrontend);
  font-weight: 600;
}

.menu_item:hover {
  text-decoration: none;
  color: var(--frontendFont1);
}

.content_title {
  margin-bottom: 1rem;
  font-size: 1.4rem;
  color: var(--frontendFont1);
  font-weight: 600;
  font-family: "Poppins", sans-serif;
}

.content_text {
  text-align: justify;
  line-height: 1.67;
  font-size: 0.875rem;
  color: var(--frontendFont2);
  line-height: 1.75;
  font-weight: 500;
  font-family: "Inter", sans-serif;
}

.content_text_strong {
  font-weight: 600;
  font-size: 1rem;
  margin-right: 6px;
}
.email {
  color: var(--brandColorFrontend);
}

.email:hover {
  color: var(--brandColorFrontend);
}

@media (max-width: 992px) {
  .menu_box {
    display: none;
  }
  .content_box {
    width: 100%;
    cursor: pointer;
  }
}
