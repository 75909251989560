.homePageTestinomialCarousel .slick-slide {
  padding: 1rem;
  text-align: center;
  display: flex !important;
  justify-content: center;
}

.homePageTestinomialCarousel .testi_content_box_one {
  width: 100%;
  max-width: 100%;
  height: 100%;
  min-height: 20rem;
  border-radius: 0.75rem;
  background-color: var(--bgColorFrontend);
  padding: 3rem;
}

.homePageTestinomialCarousel .testinomial-content {
  font-family: "Inter", sans-serif;
  font-weight: 500;
  letter-spacing: 0.05rem;
  color: var(--white);
  font-size: 1.2rem;
  line-height: 1.6;
}

.homePageTestinomialCarousel .testi_pics_box {
  position: relative;
  width: 100%;
  max-width: 9rem;
  height: 9rem;
  contain: content;
  border-radius: 50%;
  -webkit-transition: opacity 0.2s ease-in-out;
  transition: opacity 0.4s ease-in-out;
  background-color: inherit;
}

.homePageTestinomialCarousel .prev_arrow {
  position: absolute;
  z-index: 10;
  top: -3rem;
  left: 1rem;
  cursor: pointer;
}

.homePageTestinomialCarousel .prev_arrow .button {
  color: var(--brandColorFrontend);
  font-size: 3rem;
}

.homePageTestinomialCarousel .next_arrow {
  position: absolute;
  z-index: 10;
  top: -3rem;
  right: 1rem;
  cursor: pointer;
}

.homePageTestinomialCarousel .next_arrow .button {
  color: var(--brandColorFrontend);
  font-size: 3rem;
}

.homePageTestinomialCarouselDots {
  width: 100%;
  display: flex !important;
  justify-content: center;
  align-items: center;
  list-style: none;
  padding: 0.5rem 0 0 0 !important;
}

.homePageTestinomialCarouselDots li {
  margin: 0 0.5rem;
  display: flex;
}

.homePageTestinomialCarouselDots li button {
  width: 1rem;
  height: 1rem;
  border-radius: 50%;
  background-color: var(--brandColorFrontend);
  border: none;
  cursor: pointer;
}

.homePageTestinomialCarouselDots li button:hover {
  background-color: var(--brandColor);
}

.homePageTestinomialCarouselDots li.slick-active button {
  background-color: var(--brandColor);
}

@media (max-width: 992px) {
  .homePageTestinomialCarousel .testinomial-content {
    font-size: 1.1rem;
  }
}

@media (max-width: 768px) {
  .homePageTestinomialCarousel .slick-slide {
    padding: 5rem 0.5rem 1rem 0.5rem;
  }

  .homePageTestinomialCarousel .testi_content_box_one {
    padding: 2rem 1.5rem;
    height: 100%;
    border-radius: 0.5rem;
  }
  .homePageTestinomialCarousel .testi_pics_box {
    position: absolute;
    top: 0;
    left: 50%;
    transform: translate(-50%, -50%);
    border-radius: 50%;
    width: 9rem;
    height: 9rem;
    z-index: 1;
  }

  .homePageTestinomialCarousel .prev_arrow {
    position: relative;
    top: -1rem;
    left: 0;
  }

  .homePageTestinomialCarousel .next_arrow {
    top: -1rem;
    right: 0;
  }
  .homePageTestinomialCarousel .testinomial-content {
    font-size: 1rem;
  }
}

/* @media (max-width: 576px) {
  .homePageTestinomialCarousel .testinomial-content {
    font-size: 1rem;
  }
} */
