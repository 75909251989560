.section {
  background-color: #fef9f7;
  width: 100%;
  height: 100%;
}

.heading {
  font-family: "Poppins", sans-serif;
  font-weight: 600;
  font-size: 3.5rem;
  line-height: 1.1;
  color: var(--frontendFont1);
}

.heading_text {
  font-family: "Inter", sans-serif;
  font-size: 1.2rem;
  font-weight: 500;
  line-height: 1.3;
  color: var(--frontendFont2);
}

.heading_text_brand {
  font-weight: 500;
  color: var(--brandColorFrontend);
}

.feature_container {
  width: 100%;
  min-height: 15rem;
  height: 100%;
  border-radius: 1rem;
  padding: 2rem 1.5rem;
  background-color: var(--white);
  margin-bottom: 1.875rem;
}

.feature_container[data-color="primary"] {
  background-color: #fae5f7;
}

.feature_container[data-color="secondary"] {
  background: var(--bgButtonColor) !important;
}

.feature_container_heading {
  font-family: "Poppins", sans-serif;
  font-weight: 600;
  font-size: 1.4rem;
  line-height: 1.2;
  color: var(--frontendFont1) !important;
}

.container_text {
  font-family: "Inter", sans-serif;
  font-weight: 500;
  font-size: 1rem;
  color: var(--frontendFont2);
}

.more_button {
  max-width: 11rem;
  font-size: 1.125rem;
  padding: 0.6rem 0 0.6rem 1.5rem;
  border-radius: 1.6rem;
  font-weight: 500;
  font-family: "Inter", sans-serif;
  color: var(--white);
  background-color: var(--brandColorFrontend);
  transition: all 0.4s ease;
}

.more_button:hover {
  color: var(--white);
  background-color: var(--brandColor);
}

.forward_arrow {
  margin-left: 0.5rem;
  transition: margin-left 0.4s ease;
}

.more_button:hover .forward_arrow {
  margin-left: 1.5rem;
}

@media (max-width: 992px) {
  .heading {
    font-size: 3rem;
  }

  .heading_text {
    font-size: 1.1rem;
  }
}

@media (max-width: 768px) {
  .heading {
    font-size: 2.5rem;
  }

  .heading_text {
    font-size: 1rem;
  }
}

@media (max-width: 576px) {
  .heading {
    font-size: 2rem;
  }

  .feature_container_heading {
    font-size: 1.15rem;
  }
}