.reviewLandingPageCarousel .slick-slide {
  padding: 1.5rem 1rem;
  text-align: center;
  display: flex !important;
  justify-content: center;
}

.reviewLandingPageCarousel .feature_container {
  position: relative;
  width: 90%;
  height: 9.5rem;
  padding: 1.5rem;
  display: flex !important;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  gap: 0.2rem;
}

.reviewLandingPageCarousel .rating_text {
  font-family: "Poppins", serif;
  font-weight: 600;
  font-size: 1rem;
  line-height: 1.35;
  color: var(--frontendFont1);
}
