.container-fluid {
  max-width: 1300px;
}

.Nav {
  background-color: inherit;
  z-index: 10;
}

.u-reset-ul {
  list-style-type: none;
  margin-bottom: 0rem;
}

a.Nav-link.Nav-link--bordered.is-active {
  color: var(--brandColorFrontend) !important;
}

a.Nav-link.is-active {
  color: var(--brandColorFrontend) !important;
}

.Nav-webApp-link {
  color: var(--brandColorFrontend);
}

.Nav-webApp-link:hover {
  color: var(--brandColorFrontend);
  opacity: 0.8;
}

.Nav-link {
  line-height: 1.75rem;
  font-family: Poppins, serif;
  transition: ease-in-out 200ms color;
}

.Nav-link,
.Nav-webApp-link {
  font-weight: 500;
  font-size: 1.062rem;
  color: var(--white);
}

.Nav-link :hover {
  color: var(--brandColorFrontend);
}

.u-reset-button,
.u-reset-input {
  border: 0;
  outline: 0;
}

.u-reset-button {
  background-color: transparent;
  cursor: pointer;
  padding: 0;
}

.Nav-webApp-link {
  align-items: center;
  border-radius: 2rem;
  box-sizing: border-box;
  display: flex;
  height: 3rem;
  justify-content: center;
}

/* .Nav-webApp-link--signIn {
  border: 2px solid var(--brandColorFrontend);
  transition: ease-in-out 500ms border-color, ease-in-out 500ms color;
} */

.Nav--sticky {
  margin: 0 !important;
  position: sticky;
  top: 0;
  z-index: 20;
}

.Nav-featuresMenu {
  display: flex;
  flex-direction: column;
  width: 100%;
  max-height: 400px;
  overflow-y: auto;
}

.Nav--sticky .Nav-webApp-link {
  padding: 0.75rem 1.5rem;
}

/* .Nav--sticky .Nav-webApp-link--signUp {
  background-color: var(--brandColorFrontend);
  color: var(--white);
  pointer-events: all;
}

.Nav--sticky .Nav-webApp-link--signUp:hover {
  background-color: var(--bgButtonHoverColor);
  color: var(--white);
} */

.Nav--sticky .Nav-webApp-link {
  padding: 0.75rem 1.5rem;
}

/* .Nav--sticky .Nav-webApp-link--signIn {
  color: var(--brandColorFrontend);
  border: 2px solid var(--brandColorFrontend);
  margin-right: 8px;
}

.Nav--sticky .Nav-webApp-link--signIn:hover {
  background-color: var(--bgButtonHoverColor);
  color: var(--brandColorFrontend);
} */

.Nav-featuresMenu .Nav-link {
  display: inline-block;
  margin-bottom: 1rem;
}

/* @media (min-width: 1080px) {
  .Nav-webApp-link--signIn {
    border: 0;
    color: var(--brandColorFrontend);
  }
} */

@media (min-width: 1080px) {
  .Nav-mainMenu,
  .Nav-webApp {
    display: flex !important;
  }
}

@media (min-width: 1080px) {
  .Nav-webApp {
    align-items: center;
    gap: 1rem;
  }
}

/* @media (min-width: 1080px) {
  .Nav-webApp-link--signUp {
    margin-right: 8px;
    pointer-events: none;
  }
} */

@media (min-width: 1080px) {
  .Nav-featuresMenu,
  .Nav-mobileAppsLinks,
  .Nav-mobileButton,
  .Nav-socialLinks {
    display: none;
  }
}

@media (min-width: 1080px) {
  .Nav-Submenu:hover .Nav-link--suite ~ .Nav-featuresMenu a:hover {
    background-color: var(--bgButtonColor);
    color: var(--brandColorFrontend);
    padding-left: 1.5rem;
  }
}

@media (min-width: 1080px) {
  .Nav-Submenu:hover .Nav-link--suite span ~ .Nav-link--suite svg {
    color: var(--brandColorFrontend);
  }
}

@media (min-width: 1080px) {
  .Nav-mainMenu > li > a,
  .Nav-mainMenu > li > button {
    font-family: Poppins, serif;
    font-size: 1.062rem;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.78;
    color: var(--white);
    width: fit-content;
    height: 1.687rem;
  }
  .Nav-mainMenu > li > a:hover {
    color: var(--brandColorFrontend);
  }
  .Nav-mainMenu > li > button:hover {
    color: var(--brandColorFrontend);
  }
}

@media (min-width: 1080px) {
  .Nav {
    align-items: center;
    display: flex;
    justify-content: space-between;
  }
}

@media (min-width: 1080px) {
  .Nav-mainMenu > li > a,
  .Nav-mainMenu > li > button {
    color: var(--white);
  }
}

@media (min-width: 1080px) {
  .Nav-featuresMenu {
    font-weight: 500;
    background-color: var(--bgButtonColor);
    border-radius: 12px;
    box-shadow: 0 12px 32px rgba(103, 142, 178, 0.16);
    padding: 15px 9px;
    position: absolute;
    transform: translateY(6px);
    z-index: 10;
    width: 15.625rem;
    min-height: fit-content;
    max-height: 26.25rem;
  }
}

@media (min-width: 1080px) {
  .Nav-Submenu:hover .Nav-link--suite ~ .Nav-featuresMenu a {
    border-radius: 8px;
    box-sizing: border-box;
    font-weight: 500;
    line-height: 1.687rem;
    margin: 0;
    padding: 0.75rem;
    transition: ease-in-out 200ms all;
    width: 100%;
    color: var(--bgColorFrontend);
  }
}

@media (min-width: 1080px) {
  .Nav-mainMenu > li {
    margin-right: 1.25rem;
    padding: 5px 0;
  }
}

@media (min-width: 1080px) {
  .Nav-webApp-link--signIn,
  .Nav-webApp-link--signUp {
    border: 2px solid var(--white);
    border-radius: 8px;
    padding: 8px 12px;
    color: var(--white);
    font-weight: 500;
    text-decoration: none;
  }

  .Nav-webApp-link--signIn:hover,
  .Nav-webApp-link--signUp:hover {
    background-color: var(--brandColorFrontend);
    border-color: var(--brandColorFrontend);
  }
}

@media (min-width: 1080px) {
  .Nav-Submenu:hover .Nav-link--suite ~ .Nav-featuresMenu {
    display: flex;
  }
}

@media (min-width: 1080px) {
  .Nav-Submenu:hover .Nav-link--suite svg {
    transform: rotate(180deg);
  }
}

@media (min-width: 1080px) {
  .Nav-link--suite svg {
    margin: 0 0 2px 3px;
    transition: ease-in-out 200ms transform;
  }
}
