.hero_section {
  background-color: var(--bgColorFrontend);
  min-height: calc(100vh - 82px);
  display: flex;
  align-items: center;
}

.btn_begin {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  max-width: fit-content;
  height: 3.5rem;
  padding: 0 2rem;
  border-radius: 2rem;
  font-family: "Inter", sans-serif;
  background: var(--brandColorFrontend);
  color: var(--white);
  font-weight: 600;
  font-size: 1.1rem;
  transition: all 0.3s ease;
}

.btn_begin:hover {
  color: var(--brandColorFrontend);
  background-color: var(--white);
}

.btn_begin:active {
  background-color: var(--brandColor);
  color: var(--white);
}

.heading {
  font-family: "Poppins", sans-serif;
  font-weight: 600;
  font-size: 3.5rem;
  color: var(--white);
  line-height: 1.3;
}

.heading_text {
  font-family: "Inter", sans-serif;
  font-size: 1.3rem;
  font-weight: 500;
  color: var(--white);
  line-height: 1.5;
}

.text {
  font-family: "Inter", serif;
  font-weight: 500;
  font-size: 1rem;
  color: var(--white);
}

@media (max-width: 992px) {
  .heading {
    font-size: 3rem;
  }
  .heading_text {
    font-size: 1.25rem;
  }
}

@media (max-width: 768px) {
  .heading {
    font-size: 2.5rem;
  }
  .heading_text {
    font-size: 1.2rem;
  }
}

@media (max-width: 576px) {
  .heading {
    font-size: 2rem;
  }
  .heading_text {
    font-size: 1rem;
  }
}
