.custum-time-picker-two {
  width: 100%;
  height: fit-content;
  padding: 0.2rem 0rem;
  border: none;
  background-color: transparent;
  color: var(--font1);
  cursor: pointer !important;
  font-weight: 400;
  font-family: "Poppins", sans-serif;
  font-size: 0.875rem;
}

.custum-time-picker-two input {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  max-width: 100%;
  text-align: center;
  cursor: pointer !important;
}

.custum-time-picker-two:hover,
.custum-time-picker-two:focus,
.custum-time-picker-two:focus-within {
  box-shadow: none !important;
  outline: none !important;
  border: none !important;
  /* background-color: transparent; */
}

.custum-time-picker-two .ant-picker-clear {
  color: var(--brandColor);
}

.custum-time-picker-two .ant-picker-clear:hover {
  color: var(--brandColor);
}

.custum-time-picker-two-popup {
  font-family: "Poppins", sans-serif;
  font-size: 0.875rem;
  font-weight: 500;
  z-index: 1100;
}

.custum-time-picker-two-popup .ant-picker-header-view {
  font-size: 0.875rem;
  font-weight: 500;
  color: var(--font1);
}

.custum-time-picker-two-popup
  .ant-picker-time-panel-column
  > li.ant-picker-time-panel-cell-selected
  .ant-picker-time-panel-cell-inner {
  color: var(--brandColor) !important;
  background: var(--backgroundPink) !important;
}

.custum-time-picker-two-popup
  .ant-picker-time-panel-column
  > li.ant-picker-time-panel-cell
  .ant-picker-time-panel-cell-inner:hover {
  color: var(--brandColor) !important;
  background: var(--backgroundPink) !important;
}

.custum-time-picker-two-popup .ant-picker-ranges > li > a {
  text-decoration: none !important;
  color: var(--brandColor) !important;
}

.custum-time-picker-two-popup .ant-picker-ranges > li > button {
  background-color: var(--brandColor) !important;
}

.custum-time-picker-two-popup .ant-picker-ranges > li > button:hover {
  background-color: var(--hoverButtonColor) !important;
}
