.fouth_section {
  background-color: var(--bgButtonColor);
  width: 100%;
  height: 100%;
}

.heading {
  font-family: "Poppins", sans-serif;
  font-weight: 600;
  font-size: 4rem;
  line-height: 1.1;
  color: var(--frontendFont1);
}

@media (max-width: 992px) {
  .container_padding {
    padding: 6rem 2rem;
  }
  .heading {
    font-size: 3.5rem;
  }
}

@media (max-width: 768px) {
  .container_padding {
    padding: 4rem 2rem;
  }
  .heading {
    font-size: 3rem;
  }
}

@media (max-width: 576px) {
  .container_padding {
    padding: 2rem 1rem;
  }
  .heading {
    font-size: 2.5rem;
  }
}
