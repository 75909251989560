.content_box {
  /* width: 100%; */
  /* height: 100%; */
  border-radius: 0.75rem;
  background-color: var(--bgColorFrontend);
  padding: 2rem 1.5rem;
}

/* .heading {
  font-family: "Poppins", serif;
  font-weight: 500;
  font-size: 1.75rem;
  line-height: 1.3;
  color: var(--white);
} */

.input_rectangle {
  width: 100%;
  height: 48px;
  padding: 0 2rem 0 1rem;
  border-radius: 0.3rem;
  border: 2px solid var(--brandColor);
  background-color: inherit;
  font-family: "Inter", serif;
  font-weight: 500;
  font-size: 1.125rem;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  max-width: 100%;
  color: var(--white) !important;
}

.input_text {
  font-family: "Inter", serif;
  font-weight: 500;
  font-size: 1.1rem;
  line-height: 1.3;
  color: var(--white);
}

.input_error {
  border: 2px solid var(--red) !important;
}

.input_rectangle:hover {
  border: 2px solid var(--brandColorFrontend);
}

.input_rectangle:focus {
  border: 2px solid var(--brandColorFrontend);
}

.error_text {
  font-family: "Inter", serif;
  font-weight: 500;
  font-size: 1rem;
  line-height: 1.3;
  color: var(--red);
}

.button_width {
  width: 100%;
}

.button {
  font-family: "Inter", sans-serif;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  font-weight: 500;
  font-size: 1.125rem;
  height: 48px;
  border-radius: 0.5rem;
  color: var(--white);
  background-color: var(--brandColorFrontend);
  border: 2px solid var(--brandColorFrontend);
  transition: all 0.5s ease-in;
}

.button:hover {
  background-color: var(--brandColor);
  color: var(--white);
  border: 2px solid var(--brandColor);
}

.button:active {
  color: var(--white);
  background-color: var(--brandColorFrontend);
  border: 2px solid var(--brandColorFrontend);
}

@media (max-width: 992px) {
  /* .heading {
    font-size: 1.6em;
  } */

  .input_rectangle {
    font-size: 1.1rem;
  }

  .input_text {
    font-size: 1.05rem;
  }

  .more_button {
    font-size: 1.12rem;
  }
}

@media (max-width: 768px) {
  .content_box {
    padding: 2rem 1rem;
  }

  /* .heading {
    font-size: 1.5em;
  } */

  .input_rectangle {
    font-size: 1.1rem;
  }

  .input_text {
    font-size: 1rem;
  }

  .more_button {
    font-size: 1.1rem;
  }
}

@media (max-width: 576px) {
  /* .heading {
    font-size: 1.4em;
  } */

  .heading_text {
    font-size: 1rem;
  }

  .more_button {
    font-size: 1rem;
  }
}
