.LandingPage_hero_section__2TaDq {
  background-color: var(--bgColorFrontend);
  min-height: calc(100vh - 82px);
  display: flex;
  align-items: center;
}

.LandingPage_btn_begin__3yT9p {
  font-family: "Poppins", sans-serif;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  max-width: 10rem;
  height: 3.15rem;
  padding: 0 1.5rem;
  border-radius: 2rem;
  background-color: var(--brandColorFrontend);
  color: var(--white);
  font-weight: 600;
  font-size: 1.1rem;
  transition: all 0.5s ease-in;
}

.LandingPage_btn_begin__3yT9p:hover {
  /* background-color: var(--white);
  color: var(--brandColorFrontend); */
  color: var(--white);
  background: var(--brandColor);
}

.LandingPage_btn_begin__3yT9p:active {
  background-color: var(--brandColor);
  color: var(--white);
}

.LandingPage_heading__32P8r {
  font-family: "Poppins", serif;
  font-weight: 600;
  font-size: 2.5rem;
  color: var(--white);
  line-height: 1.33;
}

.LandingPage_italic__2vkSb {
  font-family: "Poppins", serif;
  font-weight: 600;
  font-style: italic;
  font-size: 2.5rem;
  margin-right: 0.3rem;
  color: var(--brandColorFrontend);
}

.LandingPage_typingAnimation__7iXS- {
  font-family: "Poppins", serif;
  font-weight: 600;
  font-size: 2.5rem;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: var(--white);
}

.LandingPage_text__JQv6I {
  font-family: "Inter", serif;
  font-weight: 500;
  font-size: 1rem;
  color: var(--white);
}

@media (max-width: 992px) {
  .LandingPage_heading__32P8r {
    font-size: 2.3rem;
  }

  .LandingPage_italic__2vkSb {
    font-size: 2.3rem;
  }
  .LandingPage_typingAnimation__7iXS- {
    font-size: 2.3rem;
  }
}

@media (max-width: 992px) {
  .LandingPage_heading__32P8r {
    font-size: 2.1rem;
  }

  .LandingPage_italic__2vkSb {
    font-size: 2.1rem;
  }
  .LandingPage_typingAnimation__7iXS- {
    font-size: 2.1rem;
  }
}

@media (max-width: 576px) {
  .LandingPage_heading__32P8r {
    font-weight: 500;
    font-size: 2rem;
  }

  .LandingPage_italic__2vkSb {
    font-weight: 500;
    font-size: 2rem;
  }
  .LandingPage_typingAnimation__7iXS- {
    font-weight: 500;
    font-size: 2rem;
  }
}

@media (max-width: 400px) {
  .LandingPage_heading__32P8r {
    font-weight: 500;
    font-size: 1.5rem;
  }

  .LandingPage_italic__2vkSb {
    font-weight: 500;
    font-size: 1.5rem;
  }
  .LandingPage_typingAnimation__7iXS- {
    font-weight: 500;
    font-size: 1.5rem;
  }
}

.Features_second_section__20P92 {
  background: #fefbfa;
  width: 100%;
  height: 100%;
}

.Features_heading__3hEpq {
  font-family: "Poppins", serif;
  font-weight: 600;
  font-size: 3rem;
  line-height: 1.1;
  color: var(--frontendFont1);
}

.Features_heading_text__1p7jU {
  font-family: "Inter", serif;
  font-weight: 500;
  font-size: 1.125rem;
  line-height: 1.3;
  color: var(--frontendFont2);
}

.Features_heading_text_brand__3RQ7W {
  font-weight: 500;
  color: var(--brandColorFrontend);
}

.Features_section_button__30hMG {
  width: 100%;
  max-width: -webkit-fit-content;
  max-width: -moz-fit-content;
  max-width: fit-content;
  padding: 0.6rem 1.8rem;
  font-weight: 500;
  font-size: 1rem !important;
  white-space: nowrap;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  font-family: "Inter", serif;
  color: var(--brandColor);
  border-radius: 2rem;
  border: 2px solid var(--brandColor);
  background-color: var(--white);
  transition: all 0.3s ease-in;
}

.Features_section_button__30hMG.Features_is_active__3ZBqy {
  color: var(--white);
  background-color: var(--brandColor);
}
.Features_section_button__30hMG:hover {
  color: var(--white);
  background-color: var(--brandColor);
}

@media (max-width: 992px) {
  .Features_heading__3hEpq {
    font-size: 2.9rem;
  }
  .Features_heading_text__1p7jU {
    font-size: 1.12rem;
  }
  .Features_section_button__30hMG {
    font-size: 1rem !important;
  }
}

@media (max-width: 768px) {
  .Features_heading__3hEpq {
    font-size: 2.8rem;
  }
  .Features_heading_text__1p7jU {
    font-size: 1.1rem;
  }
}

@media (max-width: 576px) {
  .Features_heading__3hEpq {
    font-size: 2.6rem;
  }
}

.TimeTracting_heading__rAfn4 {
  font-family: "Poppins", sans-serif;
  font-weight: 600;
  line-height: 1.3;
  font-size: 2rem;
  color: var(--frontendFont1);
}

.TimeTracting_text_font1__2ModK {
  font-family: "Inter", sans-serif;
  font-weight: 500;
  font-size: 1.1rem;
  line-height: 1.5;
  color: var(--frontendFont2);
}

.TimeTracting_more_button__3BjpG {
  font-family: "Inter", sans-serif;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  max-width: -webkit-fit-content;
  max-width: -moz-fit-content;
  max-width: fit-content;
  color: var(--brandColorFrontend);
  font-weight: 500;
  font-size: 1.1rem;
  transition: all 0.4s ease;
}
.TimeTracting_more_button__3BjpG:hover {
  color: var(--brandColor);
}

.TimeTracting_cta_icons__2FBbW {
  margin-left: 0.1rem;
  transition: margin-left 0.4s ease;
}

.TimeTracting_more_button__3BjpG:hover .TimeTracting_cta_icons__2FBbW {
  margin-left: 0.6rem;
}

.TimeTracting_text_font2__12b-j {
  font-family: "Inter", sans-serif;
  font-weight: 500;
  font-size: 1.1rem;
  color: var(--font2);
  line-height: 1.5;
}

.TimeTracting_border_left_side_of_content__2ElNO {
  border-left: 3px solid var(--brandColorFrontend);
  padding-left: 0.5rem;
}

@media (max-width: 992px) {
  .TimeTracting_heading__rAfn4 {
    font-size: 1.8rem;
  }
  .TimeTracting_text_font1__2ModK {
    font-size: 1rem;
  }
  .TimeTracting_more_button__3BjpG {
    font-size: 1rem;
  }
  .TimeTracting_text_font2__12b-j {
    font-size: 1rem;
  }
}

@media (max-width: 768px) {
  .TimeTracting_heading__rAfn4 {
    font-size: 1.7rem;
  }
}

@media (max-width: 576px) {
  .TimeTracting_heading__rAfn4 {
    font-size: 1.5rem;
  }
}

.Invoices_heading__2H7xk {
  font-family: "Poppins", sans-serif;
  font-weight: 600;
  line-height: 1.3;
  font-size: 2rem;
  color: var(--frontendFont1);
}

.Invoices_text_font1__3rRcK {
  font-family: "Inter", sans-serif;
  font-weight: 500;
  font-size: 1.1rem;
  line-height: 1.5;
  color: var(--frontendFont2);
}

.Invoices_more_button__2Ubz1 {
  font-family: "Inter", sans-serif;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  max-width: -webkit-fit-content;
  max-width: -moz-fit-content;
  max-width: fit-content;
  color: var(--brandColorFrontend);
  font-weight: 500;
  font-size: 1.1rem;
  transition: all 0.4s ease;
}
.Invoices_more_button__2Ubz1:hover {
  color: var(--brandColor);
}

.Invoices_cta_icons__2RC9u {
  margin-left: 0.1rem;
  transition: margin-left 0.4s ease;
}

.Invoices_more_button__2Ubz1:hover .Invoices_cta_icons__2RC9u {
  margin-left: 0.6rem;
}

.Invoices_text_font2__17wBj {
  font-family: "Inter", sans-serif;
  font-weight: 500;
  font-size: 1.1rem;
  color: var(--font2);
  line-height: 1.5;
}

.Invoices_border_left_side_of_content__3-ddc {
  border-left: 3px solid var(--brandColorFrontend);
  padding-left: 0.5rem;
}

@media (max-width: 992px) {
  .Invoices_heading__2H7xk {
    font-size: 1.8rem;
  }
  .Invoices_text_font1__3rRcK {
    font-size: 1rem;
  }
  .Invoices_more_button__2Ubz1 {
    font-size: 1rem;
  }
  .Invoices_text_font2__17wBj {
    font-size: 1rem;
  }
}

@media (max-width: 768px) {
  .Invoices_heading__2H7xk {
    font-size: 1.7rem;
  }
}

@media (max-width: 576px) {
  .Invoices_heading__2H7xk {
    font-size: 1.5rem;
  }
}

.Statements_heading__3W71h {
  font-family: "Poppins", sans-serif;
  font-weight: 600;
  line-height: 1.3;
  font-size: 2rem;
  color: var(--frontendFont1);
}

.Statements_text_font1__25lcY {
  font-family: "Inter", sans-serif;
  font-weight: 500;
  font-size: 1.1rem;
  line-height: 1.5;
  color: var(--frontendFont2);
}

.Statements_more_button__1j7au {
  font-family: "Inter", sans-serif;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  max-width: -webkit-fit-content;
  max-width: -moz-fit-content;
  max-width: fit-content;
  color: var(--brandColorFrontend);
  font-weight: 500;
  font-size: 1.1rem;
  transition: all 0.4s ease;
}
.Statements_more_button__1j7au:hover {
  color: var(--brandColor);
}

.Statements_cta_icons__1pU2C {
  margin-left: 0.1rem;
  transition: margin-left 0.4s ease;
}

.Statements_more_button__1j7au:hover .Statements_cta_icons__1pU2C {
  margin-left: 0.6rem;
}

.Statements_text_font2__1ZFVA {
  font-family: "Inter", sans-serif;
  font-weight: 500;
  font-size: 1.1rem;
  color: var(--font2);
  line-height: 1.5;
}

.Statements_border_left_side_of_content__TepsV {
  border-left: 3px solid var(--brandColorFrontend);
  padding-left: 0.5rem;
}

@media (max-width: 992px) {
  .Statements_heading__3W71h {
    font-size: 1.8rem;
  }
  .Statements_text_font1__25lcY {
    font-size: 1rem;
  }
  .Statements_more_button__1j7au {
    font-size: 1rem;
  }
  .Statements_text_font2__1ZFVA {
    font-size: 1rem;
  }
}

@media (max-width: 768px) {
  .Statements_heading__3W71h {
    font-size: 1.7rem;
  }
}

@media (max-width: 576px) {
  .Statements_heading__3W71h {
    font-size: 1.5rem;
  }
}

.Agreements_heading__GYO7R {
  font-family: "Poppins", sans-serif;
  font-weight: 600;
  line-height: 1.3;
  font-size: 2rem;
  color: var(--frontendFont1);
}

.Agreements_text_font1__R8xw7 {
  font-family: "Inter", sans-serif;
  font-weight: 500;
  font-size: 1.1rem;
  line-height: 1.5;
  color: var(--frontendFont2);
}

.Agreements_more_button__21_jP {
  font-family: "Inter", sans-serif;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  max-width: -webkit-fit-content;
  max-width: -moz-fit-content;
  max-width: fit-content;
  color: var(--brandColorFrontend);
  font-weight: 500;
  font-size: 1.1rem;
  transition: all 0.4s ease;
}
.Agreements_more_button__21_jP:hover {
  color: var(--brandColor);
}

.Agreements_cta_icons__3T9h7 {
  margin-left: 0.1rem;
  transition: margin-left 0.4s ease;
}

.Agreements_more_button__21_jP:hover .Agreements_cta_icons__3T9h7 {
  margin-left: 0.6rem;
}

.Agreements_text_font2__yniKa {
  font-family: "Inter", sans-serif;
  font-weight: 500;
  font-size: 1.1rem;
  color: var(--font2);
  line-height: 1.5;
}

.Agreements_border_left_side_of_content__2giCH {
  border-left: 3px solid var(--brandColorFrontend);
  padding-left: 0.5rem;
}

@media (max-width: 992px) {
  .Agreements_heading__GYO7R {
    font-size: 1.8rem;
  }
  .Agreements_text_font1__R8xw7 {
    font-size: 1rem;
  }
  .Agreements_more_button__21_jP {
    font-size: 1rem;
  }
  .Agreements_text_font2__yniKa {
    font-size: 1rem;
  }
}

@media (max-width: 768px) {
  .Agreements_heading__GYO7R {
    font-size: 1.7rem;
  }
}

@media (max-width: 576px) {
  .Agreements_heading__GYO7R {
    font-size: 1.5rem;
  }
}

.Services_third_section__378C9 {
  background-color: #fef9f7;
  width: 100%;
  height: 100%;
}

.Services_heading_brand__2nvLG {
  font-family: "Poppins", serif;
  font-weight: 600;
  font-size: 1rem;
  line-height: 1.1;
  letter-spacing: 0.05rem;
  color: var(--brandColorFrontend);
}

.Services_heading_font1__Nob-A {
  font-family: "Poppins", serif;
  font-weight: 600;
  font-size: 3rem;
  letter-spacing: 0.05rem;
  line-height: 1;
  color: var(--frontendFont1);
}

.Services_heading_font2__3dArX {
  font-family: "Inter", serif;
  font-weight: 500;
  font-size: 1.25rem;
  letter-spacing: 0.05rem;
  line-height: 1.3;
  color: var(--frontendFont2);
}

@media (max-width: 992px) {
  .Services_heading_font1__Nob-A {
    font-size: 2.5rem;
  }
  .Services_heading_font2__3dArX {
    font-size: 1.125rem;
  }
}

@media (max-width: 768px) {
  .Services_heading_font1__Nob-A {
    font-size: 2.3rem;
  }
  .Services_heading_font2__3dArX {
    font-size: 1.1rem;
  }
}

@media (max-width: 576px) {
  .Services_heading_font1__Nob-A {
    font-size: 2rem;
  }
  .Services_heading_font2__3dArX {
    font-size: 1rem;
  }
}

.homePageCTACarousel .slick-slide {
  padding: 2rem 1rem;
  text-align: center;
  display: flex !important;
  justify-content: center;
}

.homePageCTACarousel .feature_container {
  position: relative;
  width: 100%;
  max-width: 24rem;
  height: 100%;
  border-radius: 0.75rem;
  padding: 2rem 1.5rem;
  display: flex !important;
  flex-direction: column;
  grid-gap: 0.75rem;
  gap: 0.75rem;
}

.homePageCTACarousel .feature_container[data-color="primary"] {
  background-color: #fae5f7;
}

.homePageCTACarousel .feature_container[data-color="secondary"] {
  background: var(--bgButtonColor) !important;
}

.homePageCTACarousel .prev_arrow {
  position: absolute;
  z-index: 10;
  top: -2rem;
  left: 1rem;
  cursor: pointer;
}

.homePageCTACarousel .prev_arrow .button {
  color: var(--brandColorFrontend);
  font-size: 3rem;
}

.homePageCTACarousel .next_arrow {
  position: absolute;
  z-index: 10;
  top: -2rem;
  right: 1rem;
  cursor: pointer;
}
.homePageCTACarousel .next_arrow .button {
  color: var(--brandColorFrontend);
  font-size: 3rem;
}

.homePageCTACarousel .container_icons {
  width: 30px;
}

.homePageCTACarousel .container_heading {
  font-family: "Poppins", serif;
  font-weight: 600;
  font-size: 1.5rem;
  line-height: 1.35;
  color: var(--frontendFont1);
}

.homePageCTACarousel .container_text {
  font-family: "Inter", serif;
  font-weight: 500;
  font-size: 1.125rem;
  line-height: 1.7;
  color: var(--frontendFont2);
}

.homePageCTACarousel .hover_image {
  position: relative;
  visibility: hidden;
  transform: rotate(-6deg) translate(45px, 25px);
  margin: 5px 20px 10px;
  width: 200px;
  text-align: center;
  transition: 0.3s ease-in-out;
  color: var(--bgColorFrontend);
}
.homePageCTACarousel .hover_image::before {
  content: "";
  display: block;
  position: absolute;
  z-index: 0;
  right: 0px;
  bottom: -5px;
  width: 130%;
  height: 170%;
  background-size: 100% 100%;
  background-repeat: no-repeat;
  transform: rotate(5deg);
}

.homePageCTACarousel .hover_image[data-color="pink"]::before {
  background-image: url(/static/media/CTAHoverImage2.ada79ba9.svg);
}
.homePageCTACarousel .hover_image[data-color="yellow"]::before {
  background-image: url(/static/media/CTAHoverImage1.45df695a.svg);
}

.homePageCTACarousel .hover_content {
  font-family: "Inter", sans-serif;
  line-height: 1.2;
  position: relative;
  z-index: 20;
  color: var(--bgColorFrontend);
  font-weight: 500;
  font-size: 1.25rem;
}

.homePageCTACarousel .feature_container:hover .hover_image {
  visibility: visible;
}

.homePageCTACarousel .container_image_wrapper {
  width: 100%;
  transform: translateY(3.2rem);
  border-radius: 0.5rem;
  overflow: hidden;
  vertical-align: top;
  position: relative;
}

.homePageCTACarousel .container_image {
  width: 100%;
  position: static;
  height: 100%;
  max-height: 190px;
  object-fit: cover;
}

.homePageCTACarouselDots {
  width: 100%;
  display: flex !important;
  justify-content: center;
  align-items: center;
  list-style: none;
  padding: 0.5rem 0 0 0 !important;
}

.homePageCTACarouselDots li {
  margin: 0 0.5rem;
  display: flex;
}

.homePageCTACarouselDots li button {
  width: 1rem;
  height: 1rem;
  border-radius: 50%;
  background-color: var(--brandColorFrontend);
  border: none;
  cursor: pointer;
}

.homePageCTACarouselDots li button:hover {
  background-color: var(--brandColor);
}

.homePageCTACarouselDots li.slick-active button {
  background-color: var(--brandColor);
}

@media (max-width: 992px) {
  .homePageCTACarousel .container_heading {
    font-size: 1.4rem;
  }
  .homePageCTACarousel .container_text {
    font-size: 1.12rem;
  }
  .homePageCTACarousel .hover_content {
    font-size: 1.2rem;
  }
}

@media (max-width: 768px) {
  .homePageCTACarousel .container_heading {
    font-size: 1.3rem;
  }
  .homePageCTACarousel .container_text {
    font-size: 1.1rem;
  }
  .homePageCTACarousel .hover_content {
    font-size: 1.15rem;
  }
}

@media (max-width: 576px) {
  .homePageCTACarousel .prev_arrow {
    top: -2rem;
    left: 0rem;
  }

  .homePageCTACarousel .next_arrow {
    top: -2rem;
    right: 0rem;
  }
  .homePageCTACarousel .slick-slide {
    padding: 2rem 0.5rem;
  }
  .homePageCTACarousel .container_heading {
    font-size: 1.25rem;
  }
  .homePageCTACarousel .container_text {
    font-size: 1rem;
  }
  .homePageCTACarousel .hover_content {
    font-size: 1.1rem;
  }
}

.Footer_footer_section__3TgNE {
  /* background-image: url("../../Assets//frontendImages/footer.png"); */
  /* background-repeat: no-repeat;
  background-size: cover;
  background-position: center; */
  background-color: var(--bgColorFrontend);
  width: 100%;
  height: 100%;
}

.Footer_footer_list_title__2toi5 {
  font-size: 1.75rem;
  letter-spacing: 0.1rem;
  font-weight: 500;
  color: var(--white);
  text-transform: capitalize;
}

.Footer_reset_ul__2eLt9 {
  list-style-type: none;
  list-style: none;
  padding-left: 0px;
}

.Footer_footer_link__2-D1X {
  color: var(--white);
  opacity: 0.85;
  font-size: 1rem;
  font-family: "Poppins", sans-serif;
  line-height: 2.1rem;
  transition: ease-in-out 200ms color;
}

.Footer_footer_link__2-D1X:hover {
  opacity: 1;
  color: var(--brandColorFrontend);
}

.Footer_footer_copy_write__28S2C {
  text-align: center;
  color: var(--white);
  opacity: 0.85;
  font-size: 0.875rem;
  font-family: "Poppins", sans-serif;
}

.Footer_footer_copy_write__28S2C:hover {
  opacity: 1;
}

.Footer_partnerRatingWrapper__2UypI {
  margin-top: 2.6rem;
}

@media (max-width: 992px) {
  .Footer_partnerRatingWrapper__2UypI {
    margin-top: 0.8rem;
  }
}

@media (max-width: 576px) {
  .Footer_partnerRatingWrapper__2UypI {
    margin-top: 1.4rem;
  }
}

.Footer_partnerRating__k0oa2 {
  width: 6rem;
  background-color: var(--backgroundPink);
  cursor: pointer;
}

.Footer_partnerRating__k0oa2:hover p {
  opacity: 0.5;
}
.Testinomials_fouth_section__rkV9c {
  background-color: var(--bgButtonColor);
  width: 100%;
  height: 100%;
}

.Testinomials_heading__uam9h {
  font-family: "Poppins", sans-serif;
  font-weight: 600;
  font-size: 4rem;
  line-height: 1.1;
  color: var(--frontendFont1);
}

@media (max-width: 992px) {
  .Testinomials_container_padding__UDhMJ {
    padding: 6rem 2rem;
  }
  .Testinomials_heading__uam9h {
    font-size: 3.5rem;
  }
}

@media (max-width: 768px) {
  .Testinomials_container_padding__UDhMJ {
    padding: 4rem 2rem;
  }
  .Testinomials_heading__uam9h {
    font-size: 3rem;
  }
}

@media (max-width: 576px) {
  .Testinomials_container_padding__UDhMJ {
    padding: 2rem 1rem;
  }
  .Testinomials_heading__uam9h {
    font-size: 2.5rem;
  }
}

.homePageTestinomialCarousel .slick-slide {
  padding: 1rem;
  text-align: center;
  display: flex !important;
  justify-content: center;
}

.homePageTestinomialCarousel .testi_content_box_one {
  width: 100%;
  max-width: 100%;
  height: 100%;
  min-height: 20rem;
  border-radius: 0.75rem;
  background-color: var(--bgColorFrontend);
  padding: 3rem;
}

.homePageTestinomialCarousel .testinomial-content {
  font-family: "Inter", sans-serif;
  font-weight: 500;
  letter-spacing: 0.05rem;
  color: var(--white);
  font-size: 1.2rem;
  line-height: 1.6;
}

.homePageTestinomialCarousel .testi_pics_box {
  position: relative;
  width: 100%;
  max-width: 9rem;
  height: 9rem;
  contain: content;
  border-radius: 50%;
  transition: opacity 0.4s ease-in-out;
  background-color: inherit;
}

.homePageTestinomialCarousel .prev_arrow {
  position: absolute;
  z-index: 10;
  top: -3rem;
  left: 1rem;
  cursor: pointer;
}

.homePageTestinomialCarousel .prev_arrow .button {
  color: var(--brandColorFrontend);
  font-size: 3rem;
}

.homePageTestinomialCarousel .next_arrow {
  position: absolute;
  z-index: 10;
  top: -3rem;
  right: 1rem;
  cursor: pointer;
}

.homePageTestinomialCarousel .next_arrow .button {
  color: var(--brandColorFrontend);
  font-size: 3rem;
}

.homePageTestinomialCarouselDots {
  width: 100%;
  display: flex !important;
  justify-content: center;
  align-items: center;
  list-style: none;
  padding: 0.5rem 0 0 0 !important;
}

.homePageTestinomialCarouselDots li {
  margin: 0 0.5rem;
  display: flex;
}

.homePageTestinomialCarouselDots li button {
  width: 1rem;
  height: 1rem;
  border-radius: 50%;
  background-color: var(--brandColorFrontend);
  border: none;
  cursor: pointer;
}

.homePageTestinomialCarouselDots li button:hover {
  background-color: var(--brandColor);
}

.homePageTestinomialCarouselDots li.slick-active button {
  background-color: var(--brandColor);
}

@media (max-width: 992px) {
  .homePageTestinomialCarousel .testinomial-content {
    font-size: 1.1rem;
  }
}

@media (max-width: 768px) {
  .homePageTestinomialCarousel .slick-slide {
    padding: 5rem 0.5rem 1rem 0.5rem;
  }

  .homePageTestinomialCarousel .testi_content_box_one {
    padding: 2rem 1.5rem;
    height: 100%;
    border-radius: 0.5rem;
  }
  .homePageTestinomialCarousel .testi_pics_box {
    position: absolute;
    top: 0;
    left: 50%;
    transform: translate(-50%, -50%);
    border-radius: 50%;
    width: 9rem;
    height: 9rem;
    z-index: 1;
  }

  .homePageTestinomialCarousel .prev_arrow {
    position: relative;
    top: -1rem;
    left: 0;
  }

  .homePageTestinomialCarousel .next_arrow {
    top: -1rem;
    right: 0;
  }
  .homePageTestinomialCarousel .testinomial-content {
    font-size: 1rem;
  }
}

/* @media (max-width: 576px) {
  .homePageTestinomialCarousel .testinomial-content {
    font-size: 1rem;
  }
} */

.Workload_section__36reF {
  background: #fefbfa url(/static/media/workloadBg.d16757a5.avif)
    no-repeat center bottom / contain;
  height: 100%;
  width: 100%;
  display: flex;
  align-items: center;
}

.Workload_container_padding__1zDDC {
  padding: 8rem 2rem;
}

.Workload_more_button__12umu {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  width: 15rem;
  font-weight: 500;
  font-size: 1.125rem;
  padding: 0.6rem 1rem 0.6rem 1.5rem;
  border-radius: 2rem;
  font-family: "Inter", sans-serif;
  color: var(--white);
  background-color: var(--brandColorFrontend);
  border: 2px solid var(--brandColorFrontend);
  transition: all 0.5s ease;
}

.Workload_more_button__12umu:hover {
  background-color: var(--brandColor);
  color: var(--white);
  border: 2px solid var(--brandColor);
}

.Workload_farword_arrow__2I31y {
  margin-left: 0.75rem;
  transition: margin-left 0.4s ease;
}
.Workload_more_button__12umu:hover .Workload_farword_arrow__2I31y {
  margin-left: 1.2rem;
}

.Workload_heading__2kWT7 {
  font-family: "Poppins", sans-serif;
  font-weight: 600;
  font-size: 4rem;
  line-height: 1.1;
  color: var(--frontendFont1);
}

.Workload_text_font1__1fPOp {
  font-family: "Inter", sans-serif;
  font-weight: 500;
  font-size: 1.2rem;
  line-height: 1.4;
  color: var(--frontendFont2);
}

.Workload_text_font2__1GYx1 {
  font-family: "Inter", sans-serif;
  font-weight: 500;
  font-size: 1rem;
  line-height: 1.4;
  color: var(--frontendFont2);
}

@media (max-width: 992px) {
  .Workload_container_padding__1zDDC {
    padding: 6rem 2rem;
  }
  .Workload_heading__2kWT7 {
    font-size: 3.5rem;
  }
  .Workload_text_font1__1fPOp {
    font-size: 1.15rem;
  }
  .Workload_text_font2__1GYx1 {
    font-size: 0.9rem;
  }
  .Workload_more_button__12umu {
    width: 16rem;
    font-size: 1.1rem;
  }
}

@media (max-width: 768px) {
  .Workload_section__36reF {
    background: #fefbfa
      url(/static/media/workloadBgSmall.0543aedf.avif) no-repeat
      center bottom / contain;
  }
  .Workload_container_padding__1zDDC {
    padding: 4rem 2rem;
  }
  .Workload_heading__2kWT7 {
    font-size: 3rem;
  }
  .Workload_text_font1__1fPOp {
    font-size: 1.1rem;
  }
  .Workload_text_font2__1GYx1 {
    font-size: 0.875rem;
  }
  .Workload_more_button__12umu {
    width: 15.5rem;
    font-size: 1rem;
  }
}

@media (max-width: 576px) {
  .Workload_container_padding__1zDDC {
    padding: 2rem 1rem;
  }
  .Workload_heading__2kWT7 {
    font-size: 2.5rem;
  }
  .Workload_text_font1__1fPOp {
    font-size: 1rem;
  }
}

.CTAOne_section___ieb- {
  background: #fefbfa;
  width: 100%;
  height: 100%;
}

.CTAOne_content_box__2UR-q {
  width: 100%;
  height: 100%;
  border-radius: 0.75rem;
  background-color: var(--bgButtonColor);
  padding: 3rem 2rem;
}

.CTAOne_heading__1-0Gm {
  font-family: "Poppins", serif;
  font-weight: 500;
  font-size: 1.75rem;
  line-height: 1.3;
  color: var(--frontendFont1);
}

.CTAOne_heading_text__2Kw0f {
  font-family: "Inter", serif;
  font-weight: 500;
  font-size: 1.125rem;
  line-height: 1.3;
  color: var(--frontendFont2);
}

.CTAOne_hr__r2AdU {
  height: 1.2px !important;
  background: var(--brandColorFrontend);
}
.CTAOne_button_width__1anfy {
  width: 100%;
  max-width: 18rem;
}
.CTAOne_more_button__3Jgil {
  font-family: "Inter", sans-serif;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  max-height: 3rem;
  font-weight: 500;
  font-size: 1.125rem;
  padding: 0.6rem 1.5rem;
  border-radius: 2rem;
  color: var(--white);
  background-color: var(--brandColorFrontend);
  border: 2px solid var(--brandColorFrontend);
  transition: all 0.5s ease-in;
}

.CTAOne_more_button__3Jgil:hover {
  background-color: var(--brandColor);
  color: var(--white);
  border: 2px solid var(--brandColor);
}

.CTAOne_text__ObaTN {
  font-family: "Inter", serif;
  font-weight: 500;
  font-size: 1rem;
  line-height: 1.3;
  color: var(--frontendFont2);
}

.CTAOne_icons__neKSR {
  font-size: 2.5rem !important;
  color: var(--brandColorFrontend);
}

@media (max-width: 992px) {
  .CTAOne_heading__1-0Gm {
    font-size: 1.6em;
  }
  .CTAOne_heading_text__2Kw0f {
    font-size: 1.12rem;
  }
  .CTAOne_more_button__3Jgil {
    font-size: 1.12rem;
  }
  .CTAOne_text__ObaTN {
    font-size: 0.95rem;
  }
}

@media (max-width: 768px) {
  .CTAOne_content_box__2UR-q {
    padding: 2rem 1rem;
  }
  .CTAOne_button_width__1anfy {
    max-width: 100%;
  }
  .CTAOne_heading__1-0Gm {
    font-size: 1.5em;
  }
  .CTAOne_heading_text__2Kw0f {
    font-size: 1.1rem;
  }
  .CTAOne_more_button__3Jgil {
    font-size: 1.1rem;
  }
}

@media (max-width: 576px) {
  .CTAOne_heading__1-0Gm {
    font-size: 1.3em;
  }
  .CTAOne_heading_text__2Kw0f {
    font-size: 1rem;
  }
  .CTAOne_more_button__3Jgil {
    font-size: 1rem;
  }
}

.LandingPage_hero_section__36Wr5 {
  background-color: var(--bgColorFrontend);
  min-height: calc(100vh - 82px);
  display: flex;
  align-items: center;
}

.LandingPage_features__3sRTu {
  font-family: "Poppins", sans-serif;
  font-weight: 500;
  font-size: 1.3rem;
  color: var(--brandColorFrontend);
  letter-spacing: 0.3rem;
  text-transform: uppercase;
}

.LandingPage_heading__lvglr {
  font-family: "Poppins", sans-serif;
  font-weight: 500;
  font-size: 3.5rem;
  color: var(--white);
  border-bottom: 4px solid var(--brandColorFrontend);
  line-height: 1.3;
  letter-spacing: 0.1rem;
}

.LandingPage_text_heading__39X5F {
  font-family: "Inter", sans-serif;
  font-weight: 500;
  font-size: 1.3rem;
  color: var(--white);
  line-height: 1.4;
  letter-spacing: 0.1rem;
}

.LandingPage_btn_begin__1CTzp {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  max-width: 12rem;
  height: 3.15rem;
  padding: 0 2.5rem;
  border-radius: 2rem;
  background: var(--brandColorFrontend);
  color: var(--white);
  font-family: "Inter", sans-serif;
  font-weight: 600;
  font-size: 1.125rem;
  transition: all 0.3s ease;
}

.LandingPage_btn_begin__1CTzp:hover {
  color: var(--brandColorFrontend);
  background-color: var(--white);
}

.LandingPage_btn_begin__1CTzp:active {
  background-color: var(--brandColor);
  color: var(--white);
}

@media (max-width: 992px) {
  .LandingPage_features__3sRTu {
    font-size: 1.25rem;
    letter-spacing: 0.25rem;
  }

  .LandingPage_heading__lvglr {
    font-size: 3rem;
  }
  .LandingPage_text_heading__39X5F {
    font-size: 1.25rem;
  }

  .LandingPage_btn_begin__1CTzp {
    font-size: 1.1rem;
  }
}

@media (max-width: 992px) {
  .LandingPage_features__3sRTu {
    font-size: 1.2rem;
    letter-spacing: 0.2rem;
  }

  .LandingPage_heading__lvglr {
    font-size: 2.5rem;
  }
  .LandingPage_text_heading__39X5F {
    font-size: 1.2rem;
  }

  .LandingPage_btn_begin__1CTzp {
    font-size: 1.05rem;
  }
}

@media (max-width: 576px) {
  .LandingPage_features__3sRTu {
    font-size: 1.1rem;
    letter-spacing: 0.15rem;
  }

  .LandingPage_heading__lvglr {
    font-size: 2rem;
  }
  .LandingPage_text_heading__39X5F {
    font-size: 1.1rem;
  }

  .LandingPage_btn_begin__1CTzp {
    font-size: 1rem;
  }
}

.Features_section__3iHnU {
  background: #fff3f2;
  width: 100%;
  height: 100%;
}

.Features_heading__22igb {
  font-family: "Poppins", sans-serif;
  font-size: 2.2rem;
  font-weight: 600;
  line-height: 1.3;
  color: var(--frontendFont1);
}

.Features_text_font1__1uf4r {
  font-family: "Inter", sans-serif;
  font-weight: 500;
  font-size: 1.1rem;
  line-height: 1.5;
  color: var(--frontendFont2);
}

.Features_more_button__299Xh {
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  font-weight: 500;
  font-size: 1.1rem;
  padding: 0.4rem 2rem;
  border-radius: 1.5rem;
  font-family: "Inter", sans-serif;
  color: var(--white);
  background-color: var(--brandColorFrontend);
  border: 2px solid var(--brandColorFrontend);
  transition: all 0.4s ease;
}

.Features_more_button__299Xh:hover {
  background-color: var(--brandColor);
  border: 2px solid var(--brandColor);
  color: var(--white);
}

.Features_farword_arrow__2CyTv {
  margin-left: 0.5rem;
  transition: margin-left 0.4s ease;
}
.Features_more_button__299Xh:hover .Features_farword_arrow__2CyTv {
  margin-left: 1.5rem;
}

@media (max-width: 992px) {
  .Features_heading__22igb {
    font-size: 2rem;
  }
  .Features_text_font1__1uf4r {
    font-size: 1rem;
  }
  .Features_more_button__299Xh {
    font-size: 1rem;
  }
  .Features_text_font2__SpkU3 {
    font-size: 1rem;
  }
}

@media (max-width: 768px) {
  .Features_heading__22igb {
    font-size: 1.8rem;
  }
}

@media (max-width: 576px) {
  .Features_heading__22igb {
    font-size: 1.5rem;
  }
}

.SignupForm_section__2yA3I {
  background: #fff3f2;
  width: 100%;
  height: 100%;
}

.SignupForm_content_box__108qp {
  width: 100%;
  height: 100%;
  border-radius: 0.75rem;
  background-color: var(--bgButtonColor);
  padding: 2rem 1.5rem;
}

.SignupForm_heading__1zMuB {
  font-family: "Poppins", serif;
  font-weight: 500;
  font-size: 1.75rem;
  line-height: 1.2;
  color: var(--frontendFont1);
}

.SignupForm_heading_text__GxfcQ {
  font-family: "Inter", serif;
  font-weight: 500;
  font-size: 1.125rem;
  line-height: 1.3;
  color: var(--frontendFont2);
}

.SignupForm_input_rectangle__FRp8s {
  width: 100%;
  height: 48px;
  padding: 0 2rem 0 1rem;
  border-radius: 0.5rem;
  border: 2px solid var(--brandColorFrontend);
  background-color: inherit;
  font-family: "Inter", serif;
  font-weight: 500;
  font-size: 1.125rem;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  max-width: 100%;
  color: var(--frontendFont2) !important;
}

.SignupForm_input_error__19KyC {
  border: 2px solid var(--red) !important;
}
.SignupForm_input_rectangle__FRp8s:hover {
  border: 2px solid var(--brandColor);
}

.SignupForm_input_rectangle__FRp8s:focus {
  border: 2px solid var(--brandColor);
}

.SignupForm_error_text__3alvX {
  font-family: "Inter", serif;
  font-weight: 500;
  font-size: 1rem;
  line-height: 1.3;
  color: var(--red);
}

.SignupForm_button_width__UJSNk {
  width: 100%;
  max-width: 13rem;
}

.SignupForm_button__2vOSo {
  font-family: "Inter", sans-serif;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  font-weight: 500;
  font-size: 1.125rem;
  height: 48px;
  border-radius: 1.5rem;
  color: var(--white);
  background-color: var(--brandColorFrontend);
  border: 2px solid var(--brandColorFrontend);
  transition: all 0.4s ease-in;
}

.SignupForm_button__2vOSo:hover {
  background-color: var(--brandColor);
  color: var(--white);
  border: 2px solid var(--brandColor);
}

.SignupForm_button__2vOSo:active {
  color: var(--white);
  background-color: var(--brandColorFrontend);
  border: 2px solid var(--brandColorFrontend);
}

.SignupForm_hr__3Xa7o {
  height: 1.2px !important;
  background: var(--brandColorFrontend);
}

.SignupForm_text__rfYHN {
  font-family: "Inter", serif;
  font-weight: 500;
  font-size: 1rem;
  line-height: 1.3;
  color: var(--frontendFont2);
}

.SignupForm_icons__1x21U {
  font-size: 2.5rem !important;
  color: var(--brandColorFrontend);
}

@media (max-width: 992px) {
  .SignupForm_heading__1zMuB {
    font-size: 1.6em;
  }
  .SignupForm_heading_text__GxfcQ {
    font-size: 1.12rem;
  }
  .SignupForm_more_button__YEUq- {
    font-size: 1.12rem;
  }
  .SignupForm_text__rfYHN {
    font-size: 0.95rem;
  }
}

@media (max-width: 768px) {
  .SignupForm_content_box__108qp {
    padding: 2rem 1rem;
  }
  .SignupForm_button_width__UJSNk {
    max-width: 100%;
  }
  .SignupForm_heading__1zMuB {
    font-size: 1.5em;
  }
  .SignupForm_heading_text__GxfcQ {
    font-size: 1.1rem;
  }
  .SignupForm_more_button__YEUq- {
    font-size: 1.1rem;
  }
}

@media (max-width: 576px) {
  .SignupForm_heading__1zMuB {
    font-size: 1.3em;
  }
  .SignupForm_heading_text__GxfcQ {
    font-size: 1rem;
  }
  .SignupForm_more_button__YEUq- {
    font-size: 1rem;
  }
}

.LandingPage_hero_section__1SPTQ {
  background-color: var(--bgColorFrontend);
  min-height: calc(100vh - 82px);
  display: flex;
  align-items: center;
}

.LandingPage_features__2cMAM {
  font-family: "Poppins", sans-serif;
  font-weight: 500;
  font-size: 1.3rem;
  color: var(--brandColorFrontend);
  letter-spacing: 0.3rem;
  text-transform: uppercase;
}

.LandingPage_heading__2RzPV {
  font-family: "Poppins", sans-serif;
  font-weight: 500;
  font-size: 3.5rem;
  color: var(--white);
  border-bottom: 4px solid var(--brandColorFrontend);
  line-height: 1.3;
  letter-spacing: 0.1rem;
}

.LandingPage_text_heading__xL-DG {
  font-family: "Inter", sans-serif;
  font-weight: 500;
  font-size: 1.3rem;
  color: var(--white);
  line-height: 1.4;
  letter-spacing: 0.1rem;
}

.LandingPage_btn_begin__10FiD {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  max-width: 12rem;
  height: 3.15rem;
  padding: 0 2.5rem;
  border-radius: 2rem;
  background: var(--brandColorFrontend);
  color: var(--white);
  font-family: "Inter", sans-serif;
  font-weight: 600;
  font-size: 1.125rem;
  transition: all 0.3s ease;
}

.LandingPage_btn_begin__10FiD:hover {
  color: var(--brandColorFrontend);
  background-color: var(--white);
}

.LandingPage_btn_begin__10FiD:active {
  background-color: var(--brandColor);
  color: var(--white);
}

@media (max-width: 992px) {
  .LandingPage_features__2cMAM {
    font-size: 1.25rem;
    letter-spacing: 0.25rem;
  }

  .LandingPage_heading__2RzPV {
    font-size: 3rem;
  }
  .LandingPage_text_heading__xL-DG {
    font-size: 1.25rem;
  }

  .LandingPage_btn_begin__10FiD {
    font-size: 1.1rem;
  }
}

@media (max-width: 992px) {
  .LandingPage_features__2cMAM {
    font-size: 1.2rem;
    letter-spacing: 0.2rem;
  }

  .LandingPage_heading__2RzPV {
    font-size: 2.5rem;
  }
  .LandingPage_text_heading__xL-DG {
    font-size: 1.2rem;
  }

  .LandingPage_btn_begin__10FiD {
    font-size: 1.05rem;
  }
}

@media (max-width: 576px) {
  .LandingPage_features__2cMAM {
    font-size: 1.1rem;
    letter-spacing: 0.15rem;
  }

  .LandingPage_heading__2RzPV {
    font-size: 2rem;
  }
  .LandingPage_text_heading__xL-DG {
    font-size: 1.1rem;
  }

  .LandingPage_btn_begin__10FiD {
    font-size: 1rem;
  }
}

.Features_section__3s9MZ {
  background: #fff3f2;
  width: 100%;
  height: 100%;
}

.Features_heading__3EJ4H {
  font-family: "Poppins", sans-serif;
  font-size: 2.2rem;
  font-weight: 600;
  line-height: 1.3;
  color: var(--frontendFont1);
}

.Features_text_font1__2TV_S {
  font-family: "Inter", sans-serif;
  font-weight: 500;
  font-size: 1.1rem;
  line-height: 1.5;
  color: var(--frontendFont2);
}

.Features_more_button__2ALFy {
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  font-weight: 500;
  font-size: 1.1rem;
  padding: 0.4rem 2rem;
  border-radius: 1.5rem;
  font-family: "Inter", sans-serif;
  color: var(--white);
  background-color: var(--brandColorFrontend);
  border: 2px solid var(--brandColorFrontend);
  transition: all 0.4s ease;
}

.Features_more_button__2ALFy:hover {
  background-color: var(--brandColor);
  border: 2px solid var(--brandColor);
  color: var(--white);
}

.Features_farword_arrow__ZIWoL {
  margin-left: 0.5rem;
  transition: margin-left 0.4s ease;
}
.Features_more_button__2ALFy:hover .Features_farword_arrow__ZIWoL {
  margin-left: 1.5rem;
}

@media (max-width: 992px) {
  .Features_heading__3EJ4H {
    font-size: 2rem;
  }
  .Features_text_font1__2TV_S {
    font-size: 1rem;
  }
  .Features_more_button__2ALFy {
    font-size: 1rem;
  }
  .Features_text_font2__15vXz {
    font-size: 1rem;
  }
}

@media (max-width: 768px) {
  .Features_heading__3EJ4H {
    font-size: 1.8rem;
  }
}

@media (max-width: 576px) {
  .Features_heading__3EJ4H {
    font-size: 1.5rem;
  }
}

.AdditionalFeatures_section__1-l7a {
  background-color: #fef9f7;
  width: 100%;
  height: 100%;
}

.AdditionalFeatures_heading__2wa6o {
  font-family: "Poppins", sans-serif;
  font-weight: 600;
  font-size: 3.5rem;
  line-height: 1.1;
  color: var(--frontendFont1);
}

.AdditionalFeatures_heading_text__2IBwm {
  font-family: "Inter", sans-serif;
  font-size: 1.2rem;
  font-weight: 500;
  line-height: 1.3;
  color: var(--frontendFont2);
}

.AdditionalFeatures_heading_text_brand__2Wz9M {
  font-weight: 500;
  color: var(--brandColorFrontend);
}

.AdditionalFeatures_feature_container__34FH6 {
  width: 100%;
  min-height: 15rem;
  height: 100%;
  border-radius: 1rem;
  padding: 2rem 1.5rem;
  background-color: var(--white);
  margin-bottom: 1.875rem;
}

.AdditionalFeatures_feature_container__34FH6[data-color="primary"] {
  background-color: #fae5f7;
}

.AdditionalFeatures_feature_container__34FH6[data-color="secondary"] {
  background: var(--bgButtonColor) !important;
}

.AdditionalFeatures_feature_container_heading__1jvRX {
  font-family: "Poppins", sans-serif;
  font-weight: 600;
  font-size: 1.4rem;
  line-height: 1.2;
  color: var(--frontendFont1) !important;
}

.AdditionalFeatures_container_text__1I_du {
  font-family: "Inter", sans-serif;
  font-weight: 500;
  font-size: 1rem;
  color: var(--frontendFont2);
}

.AdditionalFeatures_more_button__1Cp-F {
  max-width: 12rem;
  font-size: 1.125rem;
  padding: 0.6rem 0 0.6rem 1.5rem;
  border-radius: 1.6rem;
  font-weight: 500;
  font-family: "Inter", sans-serif;
  color: var(--white);
  background-color: var(--brandColorFrontend);
  transition: all 0.4s ease;
}

.AdditionalFeatures_more_button__1Cp-F:hover {
  color: var(--white);
  background-color: var(--brandColor);
}

.AdditionalFeatures_forward_arrow__3BkuR {
  margin-left: 0.5rem;
  transition: margin-left 0.4s ease;
}

.AdditionalFeatures_more_button__1Cp-F:hover .AdditionalFeatures_forward_arrow__3BkuR {
  margin-left: 1.5rem;
}

@media (max-width: 992px) {
  .AdditionalFeatures_heading__2wa6o {
    font-size: 3rem;
  }

  .AdditionalFeatures_heading_text__2IBwm {
    font-size: 1.1rem;
  }
}

@media (max-width: 768px) {
  .AdditionalFeatures_heading__2wa6o {
    font-size: 2.5rem;
  }

  .AdditionalFeatures_heading_text__2IBwm {
    font-size: 1rem;
  }
}

@media (max-width: 576px) {
  .AdditionalFeatures_heading__2wa6o {
    font-size: 2rem;
  }

  .AdditionalFeatures_feature_container_heading__1jvRX {
    font-size: 1.15rem;
  }
}
.LandingPage_hero_section__d1OLS {
  background-color: var(--bgColorFrontend);
  min-height: calc(100vh - 82px);
  display: flex;
  align-items: center;
}

.LandingPage_features__3vnx- {
  font-family: "Poppins", sans-serif;
  font-weight: 500;
  font-size: 1.3rem;
  color: var(--brandColorFrontend);
  letter-spacing: 0.3rem;
  text-transform: uppercase;
}

.LandingPage_heading__1arWM {
  font-family: "Poppins", sans-serif;
  font-weight: 500;
  font-size: 3.5rem;
  color: var(--white);
  border-bottom: 4px solid var(--brandColorFrontend);
  line-height: 1.3;
  letter-spacing: 0.1rem;
}

.LandingPage_text_heading__2oVAA {
  font-family: "Inter", sans-serif;
  font-weight: 500;
  font-size: 1.3rem;
  color: var(--white);
  line-height: 1.4;
  letter-spacing: 0.1rem;
}

.LandingPage_btn_begin__3ryvx {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  max-width: 12rem;
  height: 3.15rem;
  padding: 0 2.5rem;
  border-radius: 2rem;
  background: var(--brandColorFrontend);
  color: var(--white);
  font-family: "Inter", sans-serif;
  font-weight: 600;
  font-size: 1.125rem;
  transition: all 0.3s ease;
}

.LandingPage_btn_begin__3ryvx:hover {
  color: var(--brandColorFrontend);
  background-color: var(--white);
}

.LandingPage_btn_begin__3ryvx:active {
  background-color: var(--brandColor);
  color: var(--white);
}

@media (max-width: 992px) {
  .LandingPage_features__3vnx- {
    font-size: 1.25rem;
    letter-spacing: 0.25rem;
  }

  .LandingPage_heading__1arWM {
    font-size: 3rem;
  }
  .LandingPage_text_heading__2oVAA {
    font-size: 1.25rem;
  }

  .LandingPage_btn_begin__3ryvx {
    font-size: 1.1rem;
  }
}

@media (max-width: 992px) {
  .LandingPage_features__3vnx- {
    font-size: 1.2rem;
    letter-spacing: 0.2rem;
  }

  .LandingPage_heading__1arWM {
    font-size: 2.5rem;
  }
  .LandingPage_text_heading__2oVAA {
    font-size: 1.2rem;
  }

  .LandingPage_btn_begin__3ryvx {
    font-size: 1.05rem;
  }
}

@media (max-width: 576px) {
  .LandingPage_features__3vnx- {
    font-size: 1.1rem;
    letter-spacing: 0.15rem;
  }

  .LandingPage_heading__1arWM {
    font-size: 2rem;
  }
  .LandingPage_text_heading__2oVAA {
    font-size: 1.1rem;
  }

  .LandingPage_btn_begin__3ryvx {
    font-size: 1rem;
  }
}

.Features_section__2qgoM {
  background: #fff3f2;
  width: 100%;
  height: 100%;
}

.Features_heading__3me04 {
  font-family: "Poppins", sans-serif;
  font-size: 2.2rem;
  font-weight: 600;
  line-height: 1.3;
  color: var(--frontendFont1);
}

.Features_text_font1__3PIlH {
  font-family: "Inter", sans-serif;
  font-weight: 500;
  font-size: 1.1rem;
  line-height: 1.5;
  color: var(--frontendFont2);
}

.Features_more_button__1kHCi {
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  font-weight: 500;
  font-size: 1.1rem;
  padding: 0.4rem 2rem;
  border-radius: 1.5rem;
  font-family: "Inter", sans-serif;
  color: var(--white);
  background-color: var(--brandColorFrontend);
  border: 2px solid var(--brandColorFrontend);
  transition: all 0.4s ease;
}

.Features_more_button__1kHCi:hover {
  background-color: var(--brandColor);
  border: 2px solid var(--brandColor);
  color: var(--white);
}

.Features_farword_arrow__3ybmj {
  margin-left: 0.5rem;
  transition: margin-left 0.4s ease;
}
.Features_more_button__1kHCi:hover .Features_farword_arrow__3ybmj {
  margin-left: 1.5rem;
}

@media (max-width: 992px) {
  .Features_heading__3me04 {
    font-size: 2rem;
  }
  .Features_text_font1__3PIlH {
    font-size: 1rem;
  }
  .Features_more_button__1kHCi {
    font-size: 1rem;
  }
  .Features_text_font2__28vVv {
    font-size: 1rem;
  }
}

@media (max-width: 768px) {
  .Features_heading__3me04 {
    font-size: 1.8rem;
  }
}

@media (max-width: 576px) {
  .Features_heading__3me04 {
    font-size: 1.5rem;
  }
}

.AdditionalFeatures_section__2TCRc {
  background-color: #fef9f7;
  width: 100%;
  height: 100%;
}

.AdditionalFeatures_heading__21DKm {
  font-family: "Poppins", sans-serif;
  font-weight: 600;
  font-size: 3.5rem;
  line-height: 1.1;
  color: var(--frontendFont1);
}

.AdditionalFeatures_heading_text__3V8xR {
  font-family: "Inter", sans-serif;
  font-size: 1.2rem;
  font-weight: 500;
  line-height: 1.3;
  color: var(--frontendFont2);
}

.AdditionalFeatures_heading_text_brand__19WMI {
  font-weight: 500;
  color: var(--brandColorFrontend);
}

.AdditionalFeatures_feature_container__1fxc8 {
  width: 100%;
  min-height: 15rem;
  height: 100%;
  border-radius: 1rem;
  padding: 2rem 1.5rem;
  background-color: var(--white);
  margin-bottom: 1.875rem;
}

.AdditionalFeatures_feature_container__1fxc8[data-color="primary"] {
  background-color: #fae5f7;
}

.AdditionalFeatures_feature_container__1fxc8[data-color="secondary"] {
  background: var(--bgButtonColor) !important;
}

.AdditionalFeatures_feature_container_heading__36lej {
  font-family: "Poppins", sans-serif;
  font-weight: 600;
  font-size: 1.4rem;
  line-height: 1.2;
  color: var(--frontendFont1) !important;
}

.AdditionalFeatures_container_text__IlcXF {
  font-family: "Inter", sans-serif;
  font-weight: 500;
  font-size: 1rem;
  color: var(--frontendFont2);
}

.AdditionalFeatures_more_button__1hkc_ {
  max-width: 12rem;
  font-size: 1.125rem;
  padding: 0.6rem 0 0.6rem 1.5rem;
  border-radius: 1.6rem;
  font-weight: 500;
  font-family: "Inter", sans-serif;
  color: var(--white);
  background-color: var(--brandColorFrontend);
  transition: all 0.4s ease;
}

.AdditionalFeatures_more_button__1hkc_:hover {
  color: var(--white);
  background-color: var(--brandColor);
}

.AdditionalFeatures_forward_arrow__3gI8F {
  margin-left: 0.5rem;
  transition: margin-left 0.4s ease;
}

.AdditionalFeatures_more_button__1hkc_:hover .AdditionalFeatures_forward_arrow__3gI8F {
  margin-left: 1.5rem;
}

@media (max-width: 992px) {
  .AdditionalFeatures_heading__21DKm {
    font-size: 3rem;
  }

  .AdditionalFeatures_heading_text__3V8xR {
    font-size: 1.1rem;
  }
}

@media (max-width: 768px) {
  .AdditionalFeatures_heading__21DKm {
    font-size: 2.5rem;
  }

  .AdditionalFeatures_heading_text__3V8xR {
    font-size: 1rem;
  }
}

@media (max-width: 576px) {
  .AdditionalFeatures_heading__21DKm {
    font-size: 2rem;
  }

  .AdditionalFeatures_feature_container_heading__36lej {
    font-size: 1.15rem;
  }
}
.LandingPage_hero_section__1VB_G {
  background-color: var(--bgColorFrontend);
  min-height: calc(100vh - 82px);
  display: flex;
  align-items: center;
}

.LandingPage_features__3jIJ2 {
  font-family: "Poppins", sans-serif;
  font-weight: 500;
  font-size: 1.3rem;
  color: var(--brandColorFrontend);
  letter-spacing: 0.3rem;
  text-transform: uppercase;
}

.LandingPage_heading__azV7e {
  font-family: "Poppins", sans-serif;
  font-weight: 500;
  font-size: 3.5rem;
  color: var(--white);
  border-bottom: 4px solid var(--brandColorFrontend);
  line-height: 1.3;
  letter-spacing: 0.1rem;
}

.LandingPage_text_heading__2Da_q {
  font-family: "Inter", sans-serif;
  font-weight: 500;
  font-size: 1.3rem;
  color: var(--white);
  line-height: 1.4;
  letter-spacing: 0.1rem;
}

.LandingPage_btn_begin__Rc9Ht {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  max-width: 12rem;
  height: 3.15rem;
  padding: 0 2.5rem;
  border-radius: 2rem;
  background: var(--brandColorFrontend);
  color: var(--white);
  font-family: "Inter", sans-serif;
  font-weight: 600;
  font-size: 1.125rem;
  transition: all 0.3s ease;
}

.LandingPage_btn_begin__Rc9Ht:hover {
  color: var(--brandColorFrontend);
  background-color: var(--white);
}

.LandingPage_btn_begin__Rc9Ht:active {
  background-color: var(--brandColor);
  color: var(--white);
}

@media (max-width: 992px) {
  .LandingPage_features__3jIJ2 {
    font-size: 1.25rem;
    letter-spacing: 0.25rem;
  }

  .LandingPage_heading__azV7e {
    font-size: 3rem;
  }
  .LandingPage_text_heading__2Da_q {
    font-size: 1.25rem;
  }

  .LandingPage_btn_begin__Rc9Ht {
    font-size: 1.1rem;
  }
}

@media (max-width: 992px) {
  .LandingPage_features__3jIJ2 {
    font-size: 1.2rem;
    letter-spacing: 0.2rem;
  }

  .LandingPage_heading__azV7e {
    font-size: 2.5rem;
  }
  .LandingPage_text_heading__2Da_q {
    font-size: 1.2rem;
  }

  .LandingPage_btn_begin__Rc9Ht {
    font-size: 1.05rem;
  }
}

@media (max-width: 576px) {
  .LandingPage_features__3jIJ2 {
    font-size: 1.1rem;
    letter-spacing: 0.15rem;
  }

  .LandingPage_heading__azV7e {
    font-size: 2rem;
  }
  .LandingPage_text_heading__2Da_q {
    font-size: 1.1rem;
  }

  .LandingPage_btn_begin__Rc9Ht {
    font-size: 1rem;
  }
}

.Features_section__1UgCk {
  background: #fff3f2;
  width: 100%;
  height: 100%;
}

.Features_heading__1PFhV {
  font-family: "Poppins", sans-serif;
  font-size: 2.2rem;
  font-weight: 600;
  line-height: 1.3;
  color: var(--frontendFont1);
}

.Features_text_font1__3LODw {
  font-family: "Inter", sans-serif;
  font-weight: 500;
  font-size: 1.1rem;
  line-height: 1.5;
  color: var(--frontendFont2);
}

.Features_more_button__2x_-z {
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  font-weight: 500;
  font-size: 1.1rem;
  padding: 0.4rem 2rem;
  border-radius: 1.5rem;
  font-family: "Inter", sans-serif;
  color: var(--white);
  background-color: var(--brandColorFrontend);
  border: 2px solid var(--brandColorFrontend);
  transition: all 0.4s ease;
}

.Features_more_button__2x_-z:hover {
  background-color: var(--brandColor);
  border: 2px solid var(--brandColor);
  color: var(--white);
}

.Features_farword_arrow__1iGwy {
  margin-left: 0.5rem;
  transition: margin-left 0.4s ease;
}
.Features_more_button__2x_-z:hover .Features_farword_arrow__1iGwy {
  margin-left: 1.5rem;
}

@media (max-width: 992px) {
  .Features_heading__1PFhV {
    font-size: 2rem;
  }
  .Features_text_font1__3LODw {
    font-size: 1rem;
  }
  .Features_more_button__2x_-z {
    font-size: 1rem;
  }
  .Features_text_font2__WYhUc {
    font-size: 1rem;
  }
}

@media (max-width: 768px) {
  .Features_heading__1PFhV {
    font-size: 1.8rem;
  }
}

@media (max-width: 576px) {
  .Features_heading__1PFhV {
    font-size: 1.5rem;
  }
}

.LandingPage_hero_section__3TOU- {
  background-color: var(--bgColorFrontend);
  min-height: calc(100vh - 82px);
  display: flex;
  align-items: center;
}

.LandingPage_features__3mzaW {
  font-family: "Poppins", sans-serif;
  font-weight: 500;
  font-size: 1.3rem;
  color: var(--brandColorFrontend);
  letter-spacing: 0.3rem;
  text-transform: uppercase;
}

.LandingPage_heading__ZtgSa {
  font-family: "Poppins", sans-serif;
  font-weight: 500;
  font-size: 3.5rem;
  color: var(--white);
  border-bottom: 4px solid var(--brandColorFrontend);
  line-height: 1.3;
  letter-spacing: 0.1rem;
}

.LandingPage_text_heading__3ko1F {
  font-family: "Inter", sans-serif;
  font-weight: 500;
  font-size: 1.3rem;
  color: var(--white);
  line-height: 1.4;
  letter-spacing: 0.1rem;
}

.LandingPage_btn_begin__3NG84 {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  max-width: 12rem;
  height: 3.15rem;
  padding: 0 2.5rem;
  border-radius: 2rem;
  background: var(--brandColorFrontend);
  color: var(--white);
  font-family: "Inter", sans-serif;
  font-weight: 600;
  font-size: 1.125rem;
  transition: all 0.3s ease;
}

.LandingPage_btn_begin__3NG84:hover {
  color: var(--brandColorFrontend);
  background-color: var(--white);
}

.LandingPage_btn_begin__3NG84:active {
  background-color: var(--brandColor);
  color: var(--white);
}

@media (max-width: 992px) {
  .LandingPage_features__3mzaW {
    font-size: 1.25rem;
    letter-spacing: 0.25rem;
  }

  .LandingPage_heading__ZtgSa {
    font-size: 3rem;
  }
  .LandingPage_text_heading__3ko1F {
    font-size: 1.25rem;
  }

  .LandingPage_btn_begin__3NG84 {
    font-size: 1.1rem;
  }
}

@media (max-width: 992px) {
  .LandingPage_features__3mzaW {
    font-size: 1.2rem;
    letter-spacing: 0.2rem;
  }

  .LandingPage_heading__ZtgSa {
    font-size: 2.5rem;
  }
  .LandingPage_text_heading__3ko1F {
    font-size: 1.2rem;
  }

  .LandingPage_btn_begin__3NG84 {
    font-size: 1.05rem;
  }
}

@media (max-width: 576px) {
  .LandingPage_features__3mzaW {
    font-size: 1.1rem;
    letter-spacing: 0.15rem;
  }

  .LandingPage_heading__ZtgSa {
    font-size: 2rem;
  }
  .LandingPage_text_heading__3ko1F {
    font-size: 1.1rem;
  }

  .LandingPage_btn_begin__3NG84 {
    font-size: 1rem;
  }
}

.Features_section__36IFg {
  background: #fff3f2;
  width: 100%;
  height: 100%;
}

.Features_heading__zC2lJ {
  font-family: "Poppins", sans-serif;
  font-size: 2.2rem;
  font-weight: 600;
  line-height: 1.3;
  color: var(--frontendFont1);
}

.Features_text_font1__QT6NY {
  font-family: "Inter", sans-serif;
  font-weight: 500;
  font-size: 1.1rem;
  line-height: 1.5;
  color: var(--frontendFont2);
}

.Features_more_button__2iQVe {
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  font-weight: 500;
  font-size: 1.1rem;
  padding: 0.4rem 2rem;
  border-radius: 1.5rem;
  font-family: "Inter", sans-serif;
  color: var(--white);
  background-color: var(--brandColorFrontend);
  border: 2px solid var(--brandColorFrontend);
  transition: all 0.4s ease;
}

.Features_more_button__2iQVe:hover {
  background-color: var(--brandColor);
  border: 2px solid var(--brandColor);
  color: var(--white);
}

.Features_farword_arrow__1uXah {
  margin-left: 0.5rem;
  transition: margin-left 0.4s ease;
}
.Features_more_button__2iQVe:hover .Features_farword_arrow__1uXah {
  margin-left: 1.5rem;
}

@media (max-width: 992px) {
  .Features_heading__zC2lJ {
    font-size: 2rem;
  }
  .Features_text_font1__QT6NY {
    font-size: 1rem;
  }
  .Features_more_button__2iQVe {
    font-size: 1rem;
  }
  .Features_text_font2__qWTXs {
    font-size: 1rem;
  }
}

@media (max-width: 768px) {
  .Features_heading__zC2lJ {
    font-size: 1.8rem;
  }
}

@media (max-width: 576px) {
  .Features_heading__zC2lJ {
    font-size: 1.5rem;
  }
}

.LandingPage_hero_section__2nl4i {
  background-color: var(--bgColorFrontend);
  min-height: calc(100vh - 82px);
  display: flex;
  align-items: center;
}

.LandingPage_features__3sG4o {
  font-family: "Poppins", sans-serif;
  font-weight: 500;
  font-size: 1.3rem;
  color: var(--brandColorFrontend);
  letter-spacing: 0.3rem;
  text-transform: uppercase;
}

.LandingPage_heading__3yTjB {
  font-family: "Poppins", sans-serif;
  font-weight: 500;
  font-size: 3.5rem;
  color: var(--white);
  border-bottom: 4px solid var(--brandColorFrontend);
  line-height: 1.3;
  letter-spacing: 0.1rem;
}

.LandingPage_text_heading__321GX {
  font-family: "Inter", sans-serif;
  font-weight: 500;
  font-size: 1.3rem;
  color: var(--white);
  line-height: 1.4;
  letter-spacing: 0.1rem;
}

.LandingPage_btn_begin__1SiJB {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  max-width: 12rem;
  height: 3.15rem;
  padding: 0 2.5rem;
  border-radius: 2rem;
  background: var(--brandColorFrontend);
  color: var(--white);
  font-family: "Inter", sans-serif;
  font-weight: 600;
  font-size: 1.125rem;
  transition: all 0.3s ease;
}

.LandingPage_btn_begin__1SiJB:hover {
  color: var(--brandColorFrontend);
  background-color: var(--white);
}

.LandingPage_btn_begin__1SiJB:active {
  background-color: var(--brandColor);
  color: var(--white);
}

@media (max-width: 992px) {
  .LandingPage_features__3sG4o {
    font-size: 1.25rem;
    letter-spacing: 0.25rem;
  }

  .LandingPage_heading__3yTjB {
    font-size: 3rem;
  }
  .LandingPage_text_heading__321GX {
    font-size: 1.25rem;
  }

  .LandingPage_btn_begin__1SiJB {
    font-size: 1.1rem;
  }
}

@media (max-width: 992px) {
  .LandingPage_features__3sG4o {
    font-size: 1.2rem;
    letter-spacing: 0.2rem;
  }

  .LandingPage_heading__3yTjB {
    font-size: 2.5rem;
  }
  .LandingPage_text_heading__321GX {
    font-size: 1.2rem;
  }

  .LandingPage_btn_begin__1SiJB {
    font-size: 1.05rem;
  }
}

@media (max-width: 576px) {
  .LandingPage_features__3sG4o {
    font-size: 1.1rem;
    letter-spacing: 0.15rem;
  }

  .LandingPage_heading__3yTjB {
    font-size: 2rem;
  }
  .LandingPage_text_heading__321GX {
    font-size: 1.1rem;
  }

  .LandingPage_btn_begin__1SiJB {
    font-size: 1rem;
  }
}

.Features_section__2EMB6 {
  background: #fff3f2;
  width: 100%;
  height: 100%;
}

.Features_heading__n6m9O {
  font-family: "Poppins", sans-serif;
  font-size: 2.2rem;
  font-weight: 600;
  line-height: 1.3;
  color: var(--frontendFont1);
}

.Features_text_font1__3iak7 {
  font-family: "Inter", sans-serif;
  font-weight: 500;
  font-size: 1.1rem;
  line-height: 1.5;
  color: var(--frontendFont2);
}

.Features_more_button__1xosC {
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  font-weight: 500;
  font-size: 1.1rem;
  padding: 0.4rem 2rem;
  border-radius: 1.5rem;
  font-family: "Inter", sans-serif;
  color: var(--white);
  background-color: var(--brandColorFrontend);
  border: 2px solid var(--brandColorFrontend);
  transition: all 0.4s ease;
}

.Features_more_button__1xosC:hover {
  background-color: var(--brandColor);
  border: 2px solid var(--brandColor);
  color: var(--white);
}

.Features_farword_arrow__2izMu {
  margin-left: 0.5rem;
  transition: margin-left 0.4s ease;
}
.Features_more_button__1xosC:hover .Features_farword_arrow__2izMu {
  margin-left: 1.5rem;
}

@media (max-width: 992px) {
  .Features_heading__n6m9O {
    font-size: 2rem;
  }
  .Features_text_font1__3iak7 {
    font-size: 1rem;
  }
  .Features_more_button__1xosC {
    font-size: 1rem;
  }
  .Features_text_font2__17YlU {
    font-size: 1rem;
  }
}

@media (max-width: 768px) {
  .Features_heading__n6m9O {
    font-size: 1.8rem;
  }
}

@media (max-width: 576px) {
  .Features_heading__n6m9O {
    font-size: 1.5rem;
  }
}

.AdditionalFeatures_section__1IPsL {
  background-color: #fef9f7;
  width: 100%;
  height: 100%;
}

.AdditionalFeatures_heading__YY-LW {
  font-family: "Poppins", sans-serif;
  font-weight: 600;
  font-size: 3.5rem;
  line-height: 1.1;
  color: var(--frontendFont1);
}

.AdditionalFeatures_heading_text__1EJzJ {
  font-family: "Inter", sans-serif;
  font-size: 1.2rem;
  font-weight: 500;
  line-height: 1.3;
  color: var(--frontendFont2);
}

.AdditionalFeatures_heading_text_brand__1fSnc {
  font-weight: 500;
  color: var(--brandColorFrontend);
}

.AdditionalFeatures_feature_container__29FDn {
  width: 100%;
  min-height: 15rem;
  height: 100%;
  border-radius: 1rem;
  padding: 2rem 1.5rem;
  background-color: var(--white);
  margin-bottom: 1.875rem;
}

.AdditionalFeatures_feature_container__29FDn[data-color="primary"] {
  background-color: #fae5f7;
}

.AdditionalFeatures_feature_container__29FDn[data-color="secondary"] {
  background: var(--bgButtonColor) !important;
}

.AdditionalFeatures_feature_container_heading__iqexc {
  font-family: "Poppins", sans-serif;
  font-weight: 600;
  font-size: 1.4rem;
  line-height: 1.2;
  color: var(--frontendFont1) !important;
}

.AdditionalFeatures_container_text__3XilA {
  font-family: "Inter", sans-serif;
  font-weight: 500;
  font-size: 1rem;
  color: var(--frontendFont2);
}

.AdditionalFeatures_more_button__1NC8a {
  max-width: 11rem;
  font-size: 1.125rem;
  padding: 0.6rem 0 0.6rem 1.5rem;
  border-radius: 1.6rem;
  font-weight: 500;
  font-family: "Inter", sans-serif;
  color: var(--white);
  background-color: var(--brandColorFrontend);
  transition: all 0.4s ease;
}

.AdditionalFeatures_more_button__1NC8a:hover {
  color: var(--white);
  background-color: var(--brandColor);
}

.AdditionalFeatures_forward_arrow__2uukC {
  margin-left: 0.5rem;
  transition: margin-left 0.4s ease;
}

.AdditionalFeatures_more_button__1NC8a:hover .AdditionalFeatures_forward_arrow__2uukC {
  margin-left: 1.5rem;
}

@media (max-width: 992px) {
  .AdditionalFeatures_heading__YY-LW {
    font-size: 3rem;
  }

  .AdditionalFeatures_heading_text__1EJzJ {
    font-size: 1.1rem;
  }
}

@media (max-width: 768px) {
  .AdditionalFeatures_heading__YY-LW {
    font-size: 2.5rem;
  }

  .AdditionalFeatures_heading_text__1EJzJ {
    font-size: 1rem;
  }
}

@media (max-width: 576px) {
  .AdditionalFeatures_heading__YY-LW {
    font-size: 2rem;
  }

  .AdditionalFeatures_feature_container_heading__iqexc {
    font-size: 1.15rem;
  }
}
.patherror_page_404__3J6cS {
  font-family: Poppins, serif;
  width: 100%;
  height: 100vh;
}

.patherror_innerPage__1Rppj {
  height: calc(100vh - 80px);
}

.patherror_page_404_image__3Krta {
  max-width: 22.5rem;
  
  @media (max-width: 576px) {
    max-width: 17rem;
  }
}

.patherror_link_404__3UIIu {
  text-decoration: none;
  height: 3.15rem;
  display: flex;
  justify-content: center;
  align-items: center;
  color: var(--white) !important;
  padding: 0 20px;
  background: var(--brandColor);
  border-radius: 5px;
  transition: ease-in-out 200ms all;
}

.patherror_link_404__3UIIu:hover {
  background: var(--bgButtonHoverColor);
}

.SideDrawer {
  justify-content: space-between;
  flex-direction: column;
  display: flex;
  position: fixed;
  width: 70%;
  max-width: 70%;
  height: 100vh;
  left: 0;
  top: 0;
  bottom: 0;
  z-index: 300;
  background-color: var(--bgColorFrontend);
  padding: 1rem;
  box-sizing: border-box;
  /* overflow: scroll; */
  transition: transform 0.3s ease-out;
}

@media (min-width: 1079px) {
  .SideDrawer {
    display: none;
  }
}

.Open {
  transform: translateX(0);
}

.Close {
  transform: translateX(-120%);
}

.Backdrop {
  display: block;
  width: 100%;
  height: 100vh;
  position: fixed;
  z-index: 200;
  top: 0;
  transform: translateX(-100%);
  background-color: rgb(0 0 0 / 74%);
  transition: transform 0.3s ease-in-out;
}

.Backdrop.show {
  transform: translateX(0%);
}

@media (min-width: 1080px) {
  .Backdrop {
    display: none;
  }
}

.mobile-view {
  display: none;
  font-weight: 500;
  background-color: var(--bgButtonColor);
  border-radius: 0.75rem;
  box-shadow: 0 12px 32px rgba(103, 142, 178, 0.16);
  padding: 0.75rem 1.5rem !important;
  position: absolute;
  z-index: 10;
  list-style-type: none;
  line-height: 29px;
  width: 15.625rem;
  min-height: -webkit-fit-content;
  min-height: -moz-fit-content;
  min-height: fit-content;
  max-height: 25rem;
  overflow-x: hidden;
  overflow-y: auto;
}

/* .Nav-Submenu-mobile:hover .mobile-view {
  display: block;
  left: 1.875rem;
} */

.mobile-view-open {
  display: block;
  left: 1.875rem;
}

.features {
  font-size: 1rem;
  font-weight: 600;
  font-family: Poppins, serif;
  color: var(--white);
}

.Nav-Submenu-mobile:hover .features {
  color: var(--brandColorFrontend);
}

/* .Nav-Submenu-mobile:hover svg {
  transform: rotate(180deg);
} */

.Nav-link-ul {
  font-weight: 500;
  display: flex;
  width: 100%;
  font-size: 1rem;
  border-radius: 8px;
  line-height: 2.5;
  color: var(--bgColorFrontend);
  padding: 2px 8px;
  font-family: Poppins, serif;
  transition: ease-in-out 200ms all;
}

.mobile-view li {
  padding: 0;
  transition: ease-in-out 200ms all;
  width: 13.75rem;
}

.mobile-view li:hover .Nav-link-ul {
  background-color: var(--bgButtonColor);
  color: var(--brandColorFrontend);
  padding-left: 1.5rem;
  width: 100%;
}

a.Nav-link-mobile.is-active {
  color: var(--brandColorFrontend);
}

a.Nav-linkli-mobile.active {
  color: var(--brandColorFrontend);
}

.Nav-mainMenu-mobile {
  padding-top: 0.75rem;
}

.ul-reset-mobile {
  list-style-type: none;
  -webkit-padding-start: 10px;
          padding-inline-start: 10px;
}

.Nav-Submenu-mobile {
  margin-bottom: 10px;
}

.link-mobile-t {
  padding-top: 15px;
  border-top: 2px solid #ececec;
}

a.Nav-link-ul.is-active {
  color: var(--brandColorFrontend);
}

.link-mobile {
  display: flex;
  width: 100%;
  padding-top: 15px;
  border-top: 2px solid #ececec;
  padding-bottom: 15px;
}

.Nav-signUp-mobile {
  margin-bottom: 10px;
}

.Nav-webApp-link-mobile {
  color: var(--white);
}

.Nav-link-mobile {
  width: 100%;
  font-size: 1rem;
  font-family: Poppins, serif;
  font-weight: 600;
  color: var(--white);
}

.link-mobile:hover .Nav-link-mobile {
  color: var(--brandColorFrontend);
  transition: ease-in-out 300ms all;
}

.Nav-signUp-mobile .Nav-webApp-link--signUp {
  font-weight: 600;
  font-size: 1rem;
  border-radius: 2rem;
  font-family: Poppins, serif;
  background: var(--brandColorFrontend);
  color: var(--white);
  pointer-events: all;
  transition: ease-in-out 300ms all;
}

.Nav-signUp-mobile .Nav-webApp-link--signUp:hover {
  background: var(--bgButtonHoverColor);
}

.Nav-logIn-mobile .Nav-webApp-link--signIn {
  font-weight: 600;
  font-size: 1rem;
  font-family: Poppins, serif;
  color: var(--brandColorFrontend);
  border: 2px solid var(--brandColorFrontend);
  transition: ease-in-out 300ms all;
}

.Nav-logIn-mobile .Nav-webApp-link--signIn:hover {
  background: var(--bgButtonHoverColor);
}

@media (max-width: 576px) {
  .mobile-view {
    max-width: 14.375rem;
    padding: 12px 6px !important;
  }
}

.Toolbar {
  width: 100%;
  background-color: var(--bgColorFrontend);
  padding: 1.15rem 0;
  box-sizing: border-box;
}

.Toolbar--sticky {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 100;
  box-shadow: 0 12px 32px rgba(78 153 222 / 18%);
  background-color: var(--bgColorFrontend);
  transition: all 0.5s ease-in-out;
}

.Toolbar nav {
  height: 100%;
  width: 100%;
}

@media (max-width: 1079px) {
  .DesktopOnly {
    display: none;
  }
}

.Toggle {
  width: 2.5rem;
  height: 3.5rem;
  float: right;
  display: flex;
  flex-flow: column;
  justify-content: space-around;
  align-items: center;
  padding: 10px 0;
  box-sizing: border-box;
  cursor: pointer;
}

.Toggle div {
  width: 90%;
  height: 3px;
  background-color: var(--white);
}

.Nav-top {
  display: flex;
  height: 3.125rem;
  justify-content: space-between;
  align-items: center;
  width: -webkit-fill-available;
}

@media (min-width: 1079px) {
  .Toggle {
    display: none;
  }
}

@media (min-width: 1079px) {
  .Nav-top {
    display: none;
  }
}

.container-fluid {
  max-width: 1300px;
}

.Nav {
  background-color: inherit;
  z-index: 10;
}

.u-reset-ul {
  list-style-type: none;
  margin-bottom: 0rem;
}

a.Nav-link.Nav-link--bordered.is-active {
  color: var(--brandColorFrontend) !important;
}

a.Nav-link.is-active {
  color: var(--brandColorFrontend) !important;
}

.Nav-webApp-link {
  color: var(--brandColorFrontend);
}

.Nav-webApp-link:hover {
  color: var(--brandColorFrontend);
  opacity: 0.8;
}

.Nav-link {
  line-height: 1.75rem;
  font-family: Poppins, serif;
  transition: ease-in-out 200ms color;
}

.Nav-link,
.Nav-webApp-link {
  font-weight: 500;
  font-size: 1.062rem;
  color: var(--white);
}

.Nav-link :hover {
  color: var(--brandColorFrontend);
}

.u-reset-button,
.u-reset-input {
  border: 0;
  outline: 0;
}

.u-reset-button {
  background-color: transparent;
  cursor: pointer;
  padding: 0;
}

.Nav-webApp-link {
  align-items: center;
  border-radius: 2rem;
  box-sizing: border-box;
  display: flex;
  height: 3rem;
  justify-content: center;
}

/* .Nav-webApp-link--signIn {
  border: 2px solid var(--brandColorFrontend);
  transition: ease-in-out 500ms border-color, ease-in-out 500ms color;
} */

.Nav--sticky {
  margin: 0 !important;
  position: -webkit-sticky;
  position: sticky;
  top: 0;
  z-index: 20;
}

.Nav-featuresMenu {
  display: flex;
  flex-direction: column;
  width: 100%;
  max-height: 400px;
  overflow-y: auto;
}

.Nav--sticky .Nav-webApp-link {
  padding: 0.75rem 1.5rem;
}

/* .Nav--sticky .Nav-webApp-link--signUp {
  background-color: var(--brandColorFrontend);
  color: var(--white);
  pointer-events: all;
}

.Nav--sticky .Nav-webApp-link--signUp:hover {
  background-color: var(--bgButtonHoverColor);
  color: var(--white);
} */

.Nav--sticky .Nav-webApp-link {
  padding: 0.75rem 1.5rem;
}

/* .Nav--sticky .Nav-webApp-link--signIn {
  color: var(--brandColorFrontend);
  border: 2px solid var(--brandColorFrontend);
  margin-right: 8px;
}

.Nav--sticky .Nav-webApp-link--signIn:hover {
  background-color: var(--bgButtonHoverColor);
  color: var(--brandColorFrontend);
} */

.Nav-featuresMenu .Nav-link {
  display: inline-block;
  margin-bottom: 1rem;
}

/* @media (min-width: 1080px) {
  .Nav-webApp-link--signIn {
    border: 0;
    color: var(--brandColorFrontend);
  }
} */

@media (min-width: 1080px) {
  .Nav-mainMenu,
  .Nav-webApp {
    display: flex !important;
  }
}

@media (min-width: 1080px) {
  .Nav-webApp {
    align-items: center;
    grid-gap: 1rem;
    gap: 1rem;
  }
}

/* @media (min-width: 1080px) {
  .Nav-webApp-link--signUp {
    margin-right: 8px;
    pointer-events: none;
  }
} */

@media (min-width: 1080px) {
  .Nav-featuresMenu,
  .Nav-mobileAppsLinks,
  .Nav-mobileButton,
  .Nav-socialLinks {
    display: none;
  }
}

@media (min-width: 1080px) {
  .Nav-Submenu:hover .Nav-link--suite ~ .Nav-featuresMenu a:hover {
    background-color: var(--bgButtonColor);
    color: var(--brandColorFrontend);
    padding-left: 1.5rem;
  }
}

@media (min-width: 1080px) {
  .Nav-Submenu:hover .Nav-link--suite span ~ .Nav-link--suite svg {
    color: var(--brandColorFrontend);
  }
}

@media (min-width: 1080px) {
  .Nav-mainMenu > li > a,
  .Nav-mainMenu > li > button {
    font-family: Poppins, serif;
    font-size: 1.062rem;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.78;
    color: var(--white);
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
    height: 1.687rem;
  }
  .Nav-mainMenu > li > a:hover {
    color: var(--brandColorFrontend);
  }
  .Nav-mainMenu > li > button:hover {
    color: var(--brandColorFrontend);
  }
}

@media (min-width: 1080px) {
  .Nav {
    align-items: center;
    display: flex;
    justify-content: space-between;
  }
}

@media (min-width: 1080px) {
  .Nav-mainMenu > li > a,
  .Nav-mainMenu > li > button {
    color: var(--white);
  }
}

@media (min-width: 1080px) {
  .Nav-featuresMenu {
    font-weight: 500;
    background-color: var(--bgButtonColor);
    border-radius: 12px;
    box-shadow: 0 12px 32px rgba(103, 142, 178, 0.16);
    padding: 15px 9px;
    position: absolute;
    transform: translateY(6px);
    z-index: 10;
    width: 15.625rem;
    min-height: -webkit-fit-content;
    min-height: -moz-fit-content;
    min-height: fit-content;
    max-height: 26.25rem;
  }
}

@media (min-width: 1080px) {
  .Nav-Submenu:hover .Nav-link--suite ~ .Nav-featuresMenu a {
    border-radius: 8px;
    box-sizing: border-box;
    font-weight: 500;
    line-height: 1.687rem;
    margin: 0;
    padding: 0.75rem;
    transition: ease-in-out 200ms all;
    width: 100%;
    color: var(--bgColorFrontend);
  }
}

@media (min-width: 1080px) {
  .Nav-mainMenu > li {
    margin-right: 1.25rem;
    padding: 5px 0;
  }
}

@media (min-width: 1080px) {
  .Nav-webApp-link--signIn,
  .Nav-webApp-link--signUp {
    border: 2px solid var(--white);
    border-radius: 8px;
    padding: 8px 12px;
    color: var(--white);
    font-weight: 500;
    text-decoration: none;
  }

  .Nav-webApp-link--signIn:hover,
  .Nav-webApp-link--signUp:hover {
    background-color: var(--brandColorFrontend);
    border-color: var(--brandColorFrontend);
  }
}

@media (min-width: 1080px) {
  .Nav-Submenu:hover .Nav-link--suite ~ .Nav-featuresMenu {
    display: flex;
  }
}

@media (min-width: 1080px) {
  .Nav-Submenu:hover .Nav-link--suite svg {
    transform: rotate(180deg);
  }
}

@media (min-width: 1080px) {
  .Nav-link--suite svg {
    margin: 0 0 2px 3px;
    transition: ease-in-out 200ms transform;
  }
}

.LandingPage_section__2NQM9 {
  background-color: var(--white);
  width: 100%;
  height: 100%;
  min-height: calc(100vh - 80px);
  display: flex;
  justify-content: center;
  align-items: center;
}

.LandingPage_heading__3AWUm {
  font-family: "Poppins", sans-serif;
  font-weight: 600;
  font-size: 3rem;
  line-height: 1.3;
  color: var(--frontendFont1);
}

.LandingPage_heading_text__2meyX {
  font-family: "Inter", sans-serif;
  font-weight: 500;
  font-size: 1.25rem;
  line-height: 1.5;
  color: var(--frontendFont2);
}

.LandingPage_more_button__34CYg {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  width: 10.5rem;
  font-weight: 500;
  font-size: 1.125rem;
  padding: 0.6rem 0.5rem 0.6rem 1.5rem;
  border-radius: 2rem;
  font-family: "Inter", sans-serif;
  color: var(--white);
  background-color: var(--brandColorFrontend);
  border: 2px solid var(--brandColorFrontend);
  transition: all 0.5s ease;
}

.LandingPage_more_button__34CYg:hover {
  background-color: var(--brandColor);
  color: var(--white);
  border: 2px solid var(--brandColor);
}

.LandingPage_farword_arrow__1nlrg {
  margin-left: 0.75rem;
  transition: margin-left 0.4s ease;
}
.LandingPage_more_button__34CYg:hover .LandingPage_farword_arrow__1nlrg {
  margin-left: 1.25rem;
}

.LandingPage_text_font2__1La9d {
  font-family: "Inter", sans-serif;
  font-weight: 500;
  font-size: 1rem;
  line-height: 1.4;
  color: var(--frontendFont2);
}

@media (max-width: 992px) {
  .LandingPage_heading__3AWUm {
    font-size: 2.75rem;
  }
  .LandingPage_heading_text__2meyX {
    font-size: 1.2rem;
  }
}

@media (max-width: 768px) {
  .LandingPage_heading__3AWUm {
    font-size: 2.5rem;
  }
  .LandingPage_heading_text__2meyX {
    font-size: 1.15rem;
  }
}

@media (max-width: 576px) {
  .LandingPage_heading__3AWUm {
    font-size: 2rem;
  }
  .LandingPage_heading_text__2meyX {
    font-size: 1.1rem;
  }
}

.reviewLandingPageCarousel .slick-slide {
  padding: 1.5rem 1rem;
  text-align: center;
  display: flex !important;
  justify-content: center;
}

.reviewLandingPageCarousel .feature_container {
  position: relative;
  width: 90%;
  height: 9.5rem;
  padding: 1.5rem;
  display: flex !important;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  grid-gap: 0.2rem;
  gap: 0.2rem;
}

.reviewLandingPageCarousel .rating_text {
  font-family: "Poppins", serif;
  font-weight: 600;
  font-size: 1rem;
  line-height: 1.35;
  color: var(--frontendFont1);
}

.ClientReviews_section__2nlvt {
  background-color: #fef9f7;
  width: 100%;
  height: 100%;
}

.ClientReviews_my_masonry_grid__2ps45 {
  display: flex;
  grid-gap: 20px;
  gap: 20px;
  width: auto;
}

.ClientReviews_my_masonry_grid_column__1MnhB {
  background-clip: padding-box;
}

.ClientReviews_my_masonry_grid_column__1MnhB > div {
  background: var(--white);
  border: 1px solid #d9e3ea;
  padding: 1.5rem 1rem;
  border-radius: 0.5rem;
  margin-bottom: 20px;
}

.ClientReviews_my_masonry_grid_column__1MnhB > div:hover {
  background: #f5f8fa;
}

.ClientReviews_review_pic_box__P0Vos {
  width: 3rem;
  height: 3rem;
  background-color: var(--brandColor);
  border-radius: 50%;
  contain: content;
  display: flex;
  justify-content: center;
  align-items: center;
}

.ClientReviews_review_pic_text__2ylaR {
  font-family: "Poppins", sans-serif;
  font-weight: 600;
  font-size: 1.25rem;
  color: var(--white);
}

.ClientReviews_reviewer_name__sbO1w {
  font-family: "Poppins", sans-serif;
  font-weight: 600;
  font-size: 1rem;
  color: var(--frontendFont1);
}

.ClientReviews_reviewer_designation__1ZAsJ {
  font-family: "Inter", sans-serif;
  font-weight: 500;
  font-size: 1rem;
  color: var(--frontendFont2);
}

.ClientReviews_review_text__24xtC {
  font-family: "Inter", sans-serif;
  font-weight: 500;
  font-size: 1rem;
  color: var(--frontendFont2);
}

.LandingPage_hero_section__2ao35 {
  background-color: var(--bgColorFrontend);
  min-height: calc(100vh - 82px);
  display: flex;
  align-items: center;
}

.LandingPage_btn_begin__3rJd1 {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  max-width: -webkit-fit-content;
  max-width: -moz-fit-content;
  max-width: fit-content;
  height: 3.5rem;
  padding: 0 2rem;
  border-radius: 2rem;
  font-family: "Inter", sans-serif;
  background: var(--brandColorFrontend);
  color: var(--white);
  font-weight: 600;
  font-size: 1.1rem;
  transition: all 0.3s ease;
}

.LandingPage_btn_begin__3rJd1:hover {
  color: var(--brandColorFrontend);
  background-color: var(--white);
}

.LandingPage_btn_begin__3rJd1:active {
  background-color: var(--brandColor);
  color: var(--white);
}

.LandingPage_heading__14APq {
  font-family: "Poppins", sans-serif;
  font-weight: 600;
  font-size: 3.5rem;
  color: var(--white);
  line-height: 1.3;
}

.LandingPage_heading_text__2HfVG {
  font-family: "Inter", sans-serif;
  font-size: 1.3rem;
  font-weight: 500;
  color: var(--white);
  line-height: 1.5;
}

.LandingPage_text__sMrXq {
  font-family: "Inter", serif;
  font-weight: 500;
  font-size: 1rem;
  color: var(--white);
}

@media (max-width: 992px) {
  .LandingPage_heading__14APq {
    font-size: 3rem;
  }
  .LandingPage_heading_text__2HfVG {
    font-size: 1.25rem;
  }
}

@media (max-width: 768px) {
  .LandingPage_heading__14APq {
    font-size: 2.5rem;
  }
  .LandingPage_heading_text__2HfVG {
    font-size: 1.2rem;
  }
}

@media (max-width: 576px) {
  .LandingPage_heading__14APq {
    font-size: 2rem;
  }
  .LandingPage_heading_text__2HfVG {
    font-size: 1rem;
  }
}

.AboutPage_section__3QSNX {
  background-color: #fff3f2;
  height: 100%;
  width: 100%;
  display: flex;
  align-items: center;
}

.AboutPage_heading__1hUtK {
  font-family: "Poppins", sans-serif;
  font-weight: 600;
  font-size: 3.5rem;
  line-height: 1.1;
  color: var(--frontendFont1);
}

.AboutPage_text_font1__2gp_t {
  font-family: "Inter", sans-serif;
  font-weight: 500;
  font-size: 1.2rem;
  line-height: 1.4;
  color: var(--frontendFont1);
}

.AboutPage_feature_container__1QSmX {
  width: 100%;
  height: 100%;
  max-width: 220px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 3rem 0.5rem;
  border-radius: 0.75rem;
  background-color: inherit;
  margin-bottom: 1.875rem;
  background: var(--white);
  box-shadow: 0px 2px 2px 2px rgba(220, 175, 166, 0.2);
}

.AboutPage_feature_container_number__3R5kn {
  font-family: "Poppins", sans-serif;
  font-weight: 500;
  font-size: 1.8rem;
  line-height: 1.2;
  color: var(--frontendFont1) !important;
}

.AboutPage_container_text__1Ty5U {
  font-family: "Inter", sans-serif;
  font-weight: 500;
  font-size: 1.1rem;
  color: var(--frontendFont2);
}

@media (max-width: 992px) {
  .AboutPage_heading__1hUtK {
    font-size: 3rem;
  }
  .AboutPage_text_font1__2gp_t {
    font-size: 1.15rem;
  }
  .AboutPage_feature_container_number__3R5kn {
    font-size: 1.7rem;
  }
  .AboutPage_container_text__1Ty5U {
    font-size: 1.05rem;
  }
}

@media (max-width: 768px) {
  .AboutPage_heading__1hUtK {
    font-size: 2.75rem;
  }
  .AboutPage_text_font1__2gp_t {
    font-size: 1.1rem;
  }
  .AboutPage_feature_container_number__3R5kn {
    font-size: 1.6rem;
  }
  .AboutPage_container_text__1Ty5U {
    font-size: 1rem;
  }
}

@media (max-width: 576px) {
  .AboutPage_container_padding__AyYCO {
    padding: 2rem 1rem;
  }
  .AboutPage_heading__1hUtK {
    font-size: 2.5rem;
  }
  .AboutPage_feature_container_number__3R5kn {
    font-size: 1.5rem;
  }
}

.Features_section__3f-QU {
  background-color: #fef9f7;
  width: 100%;
  height: 100%;
}

.Features_heading__1i4F- {
  font-family: "Poppins", sans-serif;
  font-weight: 600;
  font-size: 3rem;
  line-height: 1.1;
  color: var(--frontendFont1);
}

.Features_heading_text__2q0YP {
  font-family: "Inter", sans-serif;
  font-size: 1.2rem;
  font-weight: 500;
  line-height: 1.3;
  color: var(--frontendFont2);
}

.Features_feature_container__1UGbr {
  width: 100%;
  min-height: 18rem;
  height: 100%;
  display: flex;
  flex-direction: column;
  border-radius: 1rem;
  padding: 2rem 1.5rem;
  background-color: var(--white);
  margin-bottom: 1.875rem;
}

.Features_feature_container__1UGbr[data-color="primary"] {
  background-color: #fae5f7;
}

.Features_feature_container__1UGbr[data-color="secondary"] {
  background: var(--bgButtonColor) !important;
}

.Features_feature_container_heading__1cwjV {
  font-family: "Poppins", sans-serif;
  font-weight: 600;
  font-size: 1.4rem;
  line-height: 1.2;
  color: var(--frontendFont1) !important;
}

.Features_container_text__3r8gK {
  font-family: "Inter", sans-serif;
  font-weight: 500;
  font-size: 1.1rem;
  color: var(--frontendFont2);
}

@media (max-width: 992px) {
  .Features_heading__1i4F- {
    font-size: 2.75rem;
  }
  .Features_heading_text__2q0YP {
    font-size: 1.1rem;
  }
  .Features_feature_container_heading__1cwjV {
    font-size: 1.3rem;
  }
  .Features_container_text__3r8gK {
    font-size: 1.05rem;
  }
}

@media (max-width: 768px) {
  .Features_heading__1i4F- {
    font-size: 2.5rem;
  }
  .Features_heading_text__2q0YP {
    font-size: 1rem;
  }
  .Features_feature_container_heading__1cwjV {
    font-size: 1.25rem;
  }
  .Features_container_text__3r8gK {
    font-size: 1rem;
  }
}

@media (max-width: 576px) {
  .Features_heading__1i4F- {
    font-size: 2rem;
  }
  .Features_feature_container__1UGbr {
    min-height: 16rem;
    padding: 2rem 1rem;
  }
  .Features_feature_container_heading__1cwjV {
    font-size: 1.15rem;
  }
}

.BusinessModal_section__32DL6 {
  background-color: #fff3f2;
  width: 100%;
  height: 100%;
}

.BusinessModal_heading__3W689 {
  font-family: "Poppins", sans-serif;
  font-weight: 600;
  font-size: 3.5rem;
  line-height: 1.3;
  color: var(--frontendFont1);
}

.BusinessModal_heading_text__QKPZU {
  font-weight: 500;
  font-family: "Inter", sans-serif;
  font-size: 1.25rem;
  color: var(--frontendFont2);
}

.BusinessModal_text_fontend_brand__3U5Q4 {
  font-family: "Poppins", sans-serif;
  font-weight: 500;
  font-size: 1.1rem;
  color: var(--frontendFont2);
}

.BusinessModal_feature_container__3QAN9 {
  width: 100%;
  min-height: 14rem;
  height: 100%;
  display: flex;
  flex-direction: column;
  border-radius: 1rem;
  padding: 2rem 1.5rem;
  background-color: var(--white);
  margin-bottom: 1.875rem;
}

.BusinessModal_feature_container__3QAN9[data-color="primary"] {
  background-color: #fae5f7;
}

.BusinessModal_feature_container__3QAN9[data-color="secondary"] {
  background: var(--bgButtonColor) !important;
}

.BusinessModal_feature_container_heading__1V8zg {
  font-family: "Poppins", sans-serif;
  font-weight: 600;
  font-size: 1.4rem;
  line-height: 1.2;
  color: var(--frontendFont1) !important;
}

.BusinessModal_container_text__3C6LK {
  font-family: "Inter", sans-serif;
  font-weight: 500;
  font-size: 1.1rem;
  color: var(--frontendFont2);
}

@media (max-width: 992px) {
  .BusinessModal_heading__3W689 {
    font-size: 2.75rem;
  }
  .BusinessModal_heading_text__QKPZU {
    font-size: 1.1rem;
  }
  .BusinessModal_text_fontend_brand__3U5Q4 {
    font-size: 1.05rem;
  }

  .BusinessModal_feature_container_heading__1V8zg {
    font-size: 1.3rem;
  }
  .BusinessModal_container_text__3C6LK {
    font-size: 1.05rem;
  }
}

@media (max-width: 768px) {
  .BusinessModal_heading__3W689 {
    font-size: 2.5rem;
  }
  .BusinessModal_heading_text__QKPZU {
    font-size: 1rem;
  }
  .BusinessModal_text_fontend_brand__3U5Q4 {
    font-size: 1rem;
  }
  .BusinessModal_feature_container_heading__1V8zg {
    font-size: 1.25rem;
  }
  .BusinessModal_container_text__3C6LK {
    font-size: 1rem;
  }
}

@media (max-width: 576px) {
  .BusinessModal_heading__3W689 {
    font-size: 2rem;
  }
  .BusinessModal_feature_container__3QAN9 {
    min-height: 13rem;
    padding: 2rem 1rem;
  }
  .BusinessModal_feature_container_heading__1V8zg {
    font-size: 1.15rem;
  }
}

.SuccessStory_section__63Pvk {
  background-color: #fef9f7;
  width: 100%;
  height: 100%;
}

.SuccessStory_heading__CTEcp {
  font-family: "Poppins", sans-serif;
  font-weight: 600;
  font-size: 3rem;
  line-height: 1.1;
  color: var(--frontendFont1);
}

.SuccessStory_heading_text__q7EE- {
  text-align: justify;
  font-family: "Inter", sans-serif;
  font-size: 1.2rem;
  font-weight: 500;
  line-height: 1.3;
  color: var(--frontendFont2);
}

@media (max-width: 992px) {
  .SuccessStory_heading__CTEcp {
    font-size: 2.75rem;
  }
  .SuccessStory_heading_text__q7EE- {
    font-size: 1.1rem;
  }
}

@media (max-width: 768px) {
  .SuccessStory_heading__CTEcp {
    font-size: 2.5rem;
  }
  .SuccessStory_heading_text__q7EE- {
    font-size: 1rem;
  }
}

@media (max-width: 576px) {
  .SuccessStory_heading__CTEcp {
    font-size: 2rem;
  }
}

.SignupForm2_content_box__1ChUO {
  /* width: 100%; */
  /* height: 100%; */
  border-radius: 0.75rem;
  background-color: var(--bgColorFrontend);
  padding: 2rem 1.5rem;
}

/* .heading {
  font-family: "Poppins", serif;
  font-weight: 500;
  font-size: 1.75rem;
  line-height: 1.3;
  color: var(--white);
} */

.SignupForm2_input_rectangle__301n2 {
  width: 100%;
  height: 48px;
  padding: 0 2rem 0 1rem;
  border-radius: 0.3rem;
  border: 2px solid var(--brandColor);
  background-color: inherit;
  font-family: "Inter", serif;
  font-weight: 500;
  font-size: 1.125rem;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  max-width: 100%;
  color: var(--white) !important;
}

.SignupForm2_input_text__1Jf1t {
  font-family: "Inter", serif;
  font-weight: 500;
  font-size: 1.1rem;
  line-height: 1.3;
  color: var(--white);
}

.SignupForm2_input_error__1-q_e {
  border: 2px solid var(--red) !important;
}

.SignupForm2_input_rectangle__301n2:hover {
  border: 2px solid var(--brandColorFrontend);
}

.SignupForm2_input_rectangle__301n2:focus {
  border: 2px solid var(--brandColorFrontend);
}

.SignupForm2_error_text__10laK {
  font-family: "Inter", serif;
  font-weight: 500;
  font-size: 1rem;
  line-height: 1.3;
  color: var(--red);
}

.SignupForm2_button_width__2ltPP {
  width: 100%;
}

.SignupForm2_button__1YXSV {
  font-family: "Inter", sans-serif;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  font-weight: 500;
  font-size: 1.125rem;
  height: 48px;
  border-radius: 0.5rem;
  color: var(--white);
  background-color: var(--brandColorFrontend);
  border: 2px solid var(--brandColorFrontend);
  transition: all 0.5s ease-in;
}

.SignupForm2_button__1YXSV:hover {
  background-color: var(--brandColor);
  color: var(--white);
  border: 2px solid var(--brandColor);
}

.SignupForm2_button__1YXSV:active {
  color: var(--white);
  background-color: var(--brandColorFrontend);
  border: 2px solid var(--brandColorFrontend);
}

@media (max-width: 992px) {
  /* .heading {
    font-size: 1.6em;
  } */

  .SignupForm2_input_rectangle__301n2 {
    font-size: 1.1rem;
  }

  .SignupForm2_input_text__1Jf1t {
    font-size: 1.05rem;
  }

  .SignupForm2_more_button__JPkl8 {
    font-size: 1.12rem;
  }
}

@media (max-width: 768px) {
  .SignupForm2_content_box__1ChUO {
    padding: 2rem 1rem;
  }

  /* .heading {
    font-size: 1.5em;
  } */

  .SignupForm2_input_rectangle__301n2 {
    font-size: 1.1rem;
  }

  .SignupForm2_input_text__1Jf1t {
    font-size: 1rem;
  }

  .SignupForm2_more_button__JPkl8 {
    font-size: 1.1rem;
  }
}

@media (max-width: 576px) {
  /* .heading {
    font-size: 1.4em;
  } */

  .SignupForm2_heading_text__e48g4 {
    font-size: 1rem;
  }

  .SignupForm2_more_button__JPkl8 {
    font-size: 1rem;
  }
}

.TermsOfservice_section__2mIpG {
  background: #fefbfa;
  width: 100%;
  height: 100%;
}

.TermsOfservice_heading_section__16q7x {
  background: var(--bgColorFrontend);
  width: 100%;
  height: 100%;
  min-height: 200px;
  display: flex;
  align-items: center;
}

.TermsOfservice_heading__3pCLe {
  text-align: center;
  font-family: "Poppins", sans-serif;
  font-weight: 500;
  font-size: 3rem;
  line-height: 1.3;
  color: var(--white);
}

.TermsOfservice_heading_text__316hm {
  text-align: center;
  font-family: "Inter", sans-serif;
  font-size: 1.25rem;
  line-height: 1.6;
  color: var(--white);
  margin-top: 1rem;
}

.TermsOfservice_menu_title__MSmwt {
  font-size: 1.5rem;
  color: var(--frontendFont1);
  font-weight: 600;
  font-family: "Poppins", sans-serif;
}

.TermsOfservice_menu_item__1XiTf {
  border-bottom-style: dashed;
  border-bottom-width: 1px;
  border-bottom-color: var(--frontendFont1);
  font-size: 1.2rem;
  cursor: pointer;
  color: var(--frontendFont1);
  font-family: "Inter", sans-serif;
  transition: ease-in-out 200ms color;
}
.TermsOfservice_menu_counter__2Bj26 {
  position: absolute;
  margin-left: -30px;
  font-size: 1.2rem;
  color: var(--frontendFont1);
  font-family: "Inter", sans-serif;
}

.TermsOfservice_menu_item__1XiTf:hover {
  border-bottom-color: var(--white);
  color: var(--brandColorFrontend);
}

.TermsOfservice_content_title__1NdZ- {
  margin-bottom: 1rem;
  font-size: 2rem;
  line-height: 1.3;
  color: var(--frontendFont1);
  font-weight: 600;
  font-family: "Poppins", sans-serif;
}

.TermsOfservice_content_text__1ALHy {
  text-align: justify;
  line-height: 1.67;
  font-size: 1rem;
  color: var(--frontendFont2);
  font-weight: 500;
  font-family: "Inter", sans-serif;
}

.TermsOfservice_content_text_strong__2_R2g {
  font-weight: 600;
  font-size: 1.1rem;
  margin-right: 6px;
}
.TermsOfservice_email__3apVl {
  color: var(--brandColorFrontend);
}
.TermsOfservice_email__3apVl:hover {
  color: var(--brandColorFrontend);
}
.TermsOfservice_up_arrow__1h2B- {
  position: fixed;
  bottom: 60px;
  right: 30px;
}
.TermsOfservice_numbered_list__GR-qz {
  list-style: none;
  counter-reset: list-counter;
}
.TermsOfservice_numbered_list__GR-qz li {
  counter-increment: list-counter;
  margin-bottom: 10px;
  display: flex;
  grid-gap: 8px;
  gap: 8px;
}
.TermsOfservice_numbered_list__GR-qz li::before {
  content: counter(list-counter) ". ";
  font-weight: bold;
}

@media (max-width: 992px) {
  .TermsOfservice_heading_section__16q7x {
    min-height: 190px;
  }
  .TermsOfservice_heading__3pCLe {
    font-size: 2.275rem;
  }
  .TermsOfservice_heading_text__316hm {
    font-size: 1.2rem;
  }
}
@media (max-width: 768px) {
  .TermsOfservice_heading_section__16q7x {
    min-height: 180px;
  }
  .TermsOfservice_heading__3pCLe {
    font-size: 2.5rem;
  }
  .TermsOfservice_heading_text__316hm {
    font-size: 1.15rem;
  }
}
@media (max-width: 576px) {
  .TermsOfservice_heading_section__16q7x {
    min-height: 170px;
  }
  .TermsOfservice_heading__3pCLe {
    font-size: 2rem;
  }
  .TermsOfservice_heading_text__316hm {
    font-size: 1.1rem;
  }
  .TermsOfservice_up_arrow__1h2B- {
    position: fixed;
    bottom: 20px;
    right: 10px;
  }
}

.UpButton_circle__2SuV- {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 2.5rem;
  height: 2.5rem;
  cursor: pointer;
  border-radius: 50%;
  background-color: #fefbfa;
  border: 2px solid var(--brandColor);
}
.UpButton_circle__2SuV-:hover {
  background-color: var(--brandColor);
}

.UpButton_upBtn__FxctA {
  color: var(--brandColor);
  font-size: 25px;
  font-weight: 500;
}

.UpButton_circle__2SuV-:hover .UpButton_upBtn__FxctA {
  color: var(--white);
}

.PrivacyPolicy_section__3c8td {
  background: #fefbfa;
  width: 100%;
  height: 100%;
}

.PrivacyPolicy_last_update__3zAVR {
  font-size: 1rem;
  color: var(--frontendFont1);
  font-weight: 500;
  font-family: "Poppins", sans-serif;
}

.PrivacyPolicy_heading__jNimD {
  text-align: center;
  margin-bottom: 1.5rem;
  font-size: 2rem;
  color: var(--frontendFont1);
  font-weight: 600;
  font-family: "Poppins", sans-serif;
}

.PrivacyPolicy_menu_box__T-TH3 {
  width: 30%;
  position: -webkit-sticky;
  position: sticky;
  top: 100px;
  height: calc(100vh - 100px);
  overflow-y: auto;
}

.PrivacyPolicy_content_box__2rELT {
  width: 70%;
  cursor: pointer;
}

.PrivacyPolicy_menu_title__3Gb_Q {
  font-size: 1.3rem;
  color: var(--frontendFont1);
  font-weight: 600;
  font-family: "Poppins", sans-serif;
}

.PrivacyPolicy_menu_item__2FLnn {
  text-decoration: none;
  font-size: 0.875rem;
  line-height: 1.35;
  display: flex;
  align-items: center;
  cursor: pointer;
  padding: 6px 12px;
  border-radius: 8px;
  color: var(--frontendFont2);
  font-weight: 500;
  font-family: "Inter", sans-serif;
  transition: ease-in-out 200ms color;
}
.PrivacyPolicy_menu_item_selected__20ls_ {
  color: var(--brandColorFrontend);
  font-weight: 600;
}

.PrivacyPolicy_menu_item__2FLnn:hover {
  text-decoration: none;
  color: var(--frontendFont1);
}

.PrivacyPolicy_content_title__12f0C {
  margin-bottom: 1rem;
  font-size: 1.4rem;
  color: var(--frontendFont1);
  font-weight: 600;
  font-family: "Poppins", sans-serif;
}

.PrivacyPolicy_content_text__ruf12 {
  text-align: justify;
  line-height: 1.67;
  font-size: 0.875rem;
  color: var(--frontendFont2);
  line-height: 1.75;
  font-weight: 500;
  font-family: "Inter", sans-serif;
}

.PrivacyPolicy_content_text_strong__11tqE {
  font-weight: 600;
  font-size: 1rem;
  margin-right: 6px;
}
.PrivacyPolicy_email__3zNbR {
  color: var(--brandColorFrontend);
}

.PrivacyPolicy_email__3zNbR:hover {
  color: var(--brandColorFrontend);
}

@media (max-width: 992px) {
  .PrivacyPolicy_menu_box__T-TH3 {
    display: none;
  }
  .PrivacyPolicy_content_box__2rELT {
    width: 100%;
    cursor: pointer;
  }
}

.LandingPage_section__2W21h {
  /* background-color: #fef9f7; */
  min-height: 300px;
  display: flex;
  align-items: center;
}

.LandingPage_heading__2RDVU {
  text-align: center;
  font-family: "Poppins", serif;
  font-weight: 600;
  font-size: 3.5rem;
  color: var(--frontendFont1);
  line-height: 1.1;
}

@media (max-width: 992px) {
  .LandingPage_heading__2RDVU {
    font-size: 3.2rem;
  }
}

@media (max-width: 992px) {
  .LandingPage_heading__2RDVU {
    font-size: 3rem;
  }
}

@media (max-width: 576px) {
  .LandingPage_heading__2RDVU {
    font-size: 2.75rem;
  }
}

.ContactForm_section__klKMQ {
  background-color: var(--backgroundPink);
  min-height: 300px;
  display: flex;
  align-items: center;
}

.ContactForm_form_rectangle__2nvSc {
  width: 100%;
  height: 100%;
  contain: content;
  max-width: 46rem;
  background: var(--white);
  position: relative;
  transform: translate(0rem, -3rem);
  border-radius: 0.875rem 0.875rem 0 0;
}

.ContactForm_form_header__3K1mj {
  width: 100%;
  height: 15rem;
  background: var(--bgColorFrontend);
}

.ContactForm_form_header_heading__1ppJs {
  text-align: center;
  font-family: "Poppins", serif;
  font-weight: 600;
  font-size: 2rem;
  line-height: 1.1;
  color: var(--white);
}

.ContactForm_form_header_text__2SFXK {
  text-align: center;
  font-family: "Inter", serif;
  font-weight: 500;
  font-size: 1.1rem;
  line-height: 1.4;
  color: var(--white);
}

.ContactForm_form_footer__UsWLF {
  width: 100%;
  height: 100%;
  min-height: 20rem;
  padding: 2rem 2rem 1rem 2rem;
  background: var(--white);
}

.ContactForm_input_rectangle__1n_eA {
  width: 100%;
  height: 48px;
  padding: 0 2rem 0 1rem;
  border-radius: 0.3rem;
  border: 1px solid var(--borderInput);
  background-color: inherit;
  font-family: "Inter", serif;
  font-weight: 500;
  font-size: 1rem;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  max-width: 100%;
  color: var(--frontendFont2) !important;
}

.ContactForm_textarea_rectangle__6ct7S {
  width: 100%;
  padding: 1rem;
  border-radius: 0.3rem;
  border: 1px solid var(--borderInput);
  background-color: inherit;
  font-family: "Inter", serif;
  font-weight: 500;
  font-size: 1rem;
  max-width: 100%;
  color: var(--frontendFont2) !important;
}

.ContactForm_input_text__1lItM {
  font-family: "Inter", serif;
  font-weight: 500;
  font-size: 1rem;
  line-height: 1.3;
  color: var(--frontendFont2);
}

.ContactForm_required__1k9YM {
  color: var(--red);
}

.ContactForm_input_error__3bfU_ {
  border: 1px solid var(--red) !important;
}

.ContactForm_input_rectangle__1n_eA:hover {
  border: 1px solid var(--frontendFont1);
}

.ContactForm_input_rectangle__1n_eA:focus {
  border: 1px solid var(--frontendFont1);
}

.ContactForm_error_text__1U0At {
  font-family: "Inter", serif;
  font-weight: 500;
  font-size: 0.875rem;
  line-height: 1.3;
  color: var(--red);
}

.ContactForm_button__2FAiI {
  font-family: "Inter", sans-serif;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  font-weight: 500;
  font-size: 1rem;
  height: 3rem;
  border-radius: 0.5rem;
  color: var(--white);
  background-color: var(--frontendFont1);
  border: 1px solid var(--frontendFont1);
  transition: all 0.4s ease;
}

.ContactForm_button__2FAiI:hover {
  background-color: var(--bgColorFrontend);
  border: 1px solid var(--bgColorFrontend);
}

.ContactForm_button__2FAiI:active {
  background-color: var(--frontendFont1);
  border: 1px solid var(--frontendFont1);
}

.ContactForm_file_text__scEUa {
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  font-family: "Inter", serif;
  font-weight: 500;
  font-size: 1.25rem;
  line-height: 1.3;
  color: var(--frontendFont1);
}

.ContactForm_file_input__DW7YQ {
  width: 0;
  height: 0;
  display: none;
}

.ContactForm_file_button__3gIs- {
  font-family: "Inter", sans-serif;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  max-width: 8rem;
  min-width: 8rem;
  cursor: pointer;
  font-weight: 500;
  font-size: 1rem;
  height: 3rem;
  border-radius: 0.5rem;
  color: var(--frontendFont1);
  background-color: var(--white);
  border: 2px solid var(--frontendFont1);
  transition: all 0.5s ease;
  margin-bottom: 0px;
}

.ContactForm_file_button__3gIs-:hover {
  color: var(--white);
  background-color: var(--frontendFont1);
  border: 2px solid var(--frontendFont1);
}

.ContactForm_file_button__3gIs-:disabled {
  opacity: 0.5;
}

.ContactForm_file_terms__LNTVg {
  font-family: "Inter", serif;
  font-weight: 500;
  font-size: 0.875rem;
  line-height: 1.3;
  color: var(--frontendFont2);
}

.ContactForm_checkBox__2pclq {
  padding-left: 0px !important;
}

.ContactForm_checkBox_input__h_PBv {
  width: 1.125rem !important;
  height: 1.125rem !important;
  margin: 0 !important;
  box-shadow: none !important;
  border-color: var(--frontendFont1) !important;
  border-width: 2px;
  cursor: pointer;
}

.ContactForm_checkBox_input__h_PBv:checked {
  width: 1.125rem;
  height: 1.125rem;
  box-shadow: none !important;
  background-color: var(--frontendFont1) !important;
  border-color: var(--frontendFont1);
}

.ContactForm_checkBox_label__2r1TV {
  padding-left: 0.875rem;
  font-family: "Inter", serif;
  font-weight: 500;
  font-size: 0.875rem;
  line-height: 1.3;
  cursor: pointer;
  color: var(--frontendFont2);
}

@media (max-width: 576px) {
  .ContactForm_form_footer__UsWLF {
    padding: 2rem 1rem 1rem 1rem;
  }
}

.SuccessModal_heading__MaQFJ {
  font-family: "Poppins", sans-serif;
  font-weight: 600;
  font-size: 2.2rem;
  line-height: 1.3;
  color: var(--frontendFont1);
}

.SuccessModal_text__28R1r {
  font-family: "Inter", sans-serif;
  font-weight: 500;
  font-size: 1.1rem;
  line-height: 1.3;
  color: var(--frontendFont1);
}

.custum-date-picker-one {
  width: 100% !important;
  height: var(--formInputHeight);
  border-radius: var(--formInputBorderRadius);
  display: flex;
  align-items: center;
  padding: 0 1rem;
  border: 1px solid var(--borderInput);
  background-color: var(--white);
  color: var(--font1);
  cursor: pointer;
  font-weight: 400;
  font-family: "Poppins", sans-serif;
  font-size: 0.875rem;
}

.custum-date-picker-one:hover,
.custum-date-picker-one:focus,
.custum-date-picker-one:focus-within {
  box-shadow: none !important;
  outline: none !important;
  border: 1px solid var(--brandColor) !important;
}

.custum-date-picker-one .ant-picker-clear {
  color: var(--brandColor);
}

.custum-date-picker-one .ant-picker-clear:hover {
  color: var(--brandColor);
}

.custum-date-picker-one-popup {
  font-family: "Poppins", sans-serif;
  font-size: 0.875rem;
  font-weight: 500;
  z-index: 1100;
}

.custum-date-picker-one-popup .ant-picker-header-view {
  font-size: 1rem;
  font-weight: 600;
  color: var(--font1);
}

.custum-date-picker-one-popup .ant-picker-header-view > button:hover {
  color: var(--brandColor) !important;
}

.custum-date-picker-one-popup .ant-picker-content tr th {
  font-family: "Poppins", sans-serif;
  font-size: 0.875rem;
  font-weight: 600;
  color: var(--brandColor);
}

.custum-date-picker-one-popup .ant-picker-cell {
  font-family: "Poppins", sans-serif;
  font-size: 0.875rem;
  font-weight: 500 !important;
}

.custum-date-picker-one-popup
  .ant-picker-cell-in-view.ant-picker-cell-today:not(.ant-picker-cell-disabled)
  .ant-picker-cell-inner::before {
  border: 2px solid var(--brandColor);
}

.custum-date-picker-one-popup
  .ant-picker-cell-in-view.ant-picker-cell-selected:not(
    .ant-picker-cell-disabled
  )
  .ant-picker-cell-inner {
  color: var(--white) !important;
  background-color: var(--brandColor) !important;
}

.custum-date-picker-one-popup
  .ant-picker-cell:hover:not(.ant-picker-cell-selected):not(
    .ant-picker-cell-range-start
  ):not(.ant-picker-cell-range-end)
  .ant-picker-cell-inner {
  background: var(--backgroundPink) !important;
}

.custum-date-picker-one-popup .ant-picker-ranges > li > a {
  text-decoration: none !important;
  color: var(--brandColor) !important;
}

.custum-range-picker-box {
  height: 2.75rem;
  width: 100%;
  display: flex;
  align-items: center;
  border: 1px solid var(--borderInput);
  background-color: var(--white);
  padding: 6px 9px;
  border-radius: 6px;
  cursor: pointer;
  font-weight: 400;
  font-family: "Poppins", sans-serif;
  font-size: 0.875rem;
}

.custum-range-picker-box:hover {
  border: 1px solid var(--brandColor);
}

.custum-range-picker-box .dropdown-icon {
  font-size: 14px !important;
  color: var(--font2) !important;
  opacity: 0.7;
}

.custum-range-picker-box .button-hover:hover {
  background-color: var(--backgroundPink) !important;
}

.custum-range-dropdown .ant-dropdown-menu {
  max-height: 350px;
  overflow-y: auto;
  padding: 9px 6px;
}

.custum-range-dropdown .ant-dropdown-menu .ant-dropdown-menu-item-selected {
  background-color: var(--backgroundPink) !important;
  color: var(--brandColor);
}

.custum-range-dropdown .ant-dropdown-menu .ant-dropdown-menu-item:hover {
  background-color: var(--backgroundPink) !important;
}

.custom-range-picker {
  height: 0px !important;
  padding: 0px !important;
  width: 0px !important;
  border: none !important;
  outline: none !important;
  background: transparent;
  position: absolute;
}

.custom-range-picker-popup {
  font-family: Poppins, serif;
  font-size: 0.875rem;
  font-weight: 400;
  background: var(--white);
  color: var(--font1);
}

.custom-range-picker-popup .ant-picker-header-view button {
  font-family: Poppins, serif;
  font-size: 0.875rem;
  font-weight: 600;
  color: var(--font1);
}

.custom-range-picker-popup .ant-picker-header-view button:hover {
  color: var(--brandColor);
}

.custom-range-picker-popup .ant-picker-content tr th {
  font-family: Poppins, serif;
  font-size: 0.875rem;
  font-weight: 600;
  color: var(--brandColor);
}

.custom-range-picker-popup .ant-picker-cell {
  font-family: Poppins, serif;
  font-size: 0.812rem;
  font-weight: 500 !important;
}

.custom-range-picker-popup .ant-picker-cell::after {
  border-color: var(--brandColor) !important;
}

.custom-range-picker-popup .ant-picker-cell-inner::before {
  border-color: var(--brandColor) !important;
  color: var(--brandColor);
}

.custom-range-picker-popup
  .ant-picker-cell-in-view.ant-picker-cell-range-start
  .ant-picker-cell-inner {
  color: var(--white);
  background: var(--brandColor) !important;
}

.custom-range-picker-popup
  .ant-picker-cell-in-view.ant-picker-cell-range-end
  .ant-picker-cell-inner {
  color: var(--white);
  background: var(--brandColor) !important;
}

.custom-range-picker-popup
  .ant-picker-cell-in-view.ant-picker-cell-range-start:not(
    .ant-picker-cell-range-start-single
  )::before {
  background: var(--backgroundPink) !important;
}

.custom-range-picker-popup
  .ant-picker-cell-in-view.ant-picker-cell-range-end:not(
    .ant-picker-cell-range-end-single
  )::before {
  background: var(--backgroundPink) !important;
}

.custom-range-picker-popup
  .ant-picker-cell-in-view.ant-picker-cell-range-hover-end
  .ant-picker-cell-selected::before:hover {
  background: var(--brandColor) !important;
}

.custom-range-picker-popup
  .ant-picker-cell-in-view.ant-picker-cell-range-hover-end
  .ant-picker-cell-inner {
  font-weight: 500 !important;
  color: var(--brandColor) !important;
  background: var(--backgroundPink) !important;
}

.custom-range-picker-popup
  .ant-picker-cell-in-view.ant-picker-cell-in-range::before {
  background: var(--backgroundPink) !important;
}

.custom-range-picker-popup
  .ant-picker-cell-in-view.ant-picker-cell-in-range
  .ant-picker-cell-inner {
  font-weight: 500 !important;
  color: var(--brandColor) !important;
  background: var(--backgroundPink) !important;
}

.custom-range-picker-popup
  .ant-picker-cell-in-view.ant-picker-cell-in-range
  .ant-picker-cell-range-hover:before {
  color: var(--white) !important;
  background: var(--brandColor) !important;
}

.custom-range-picker-popup
  .ant-picker-cell-in-view.ant-picker-cell-in-range
  .ant-picker-cell-range-hover-start,
.ant-picker-cell-inner::after {
  border-radius: 12px;
  background: var(--backgroundPink) !important;
}

.custom-range-picker-popup
  .ant-picker-cell-in-view.ant-picker-cell-in-range
  .ant-picker-cell-range-hover-end,
.ant-picker-cell-inner::after {
  border-radius: 12px;
  background: var(--backgroundPink) !important;
}

@media (max-width: 992px) {
  .custom-range-picker-popup .ant-picker-panels {
    width: 300px !important;
    flex-wrap: wrap !important;
  }
}

.custum-date-picker-three {
  width: 100%;
  height: -webkit-fit-content;
  height: -moz-fit-content;
  height: fit-content;
  padding: 0.2rem 0rem;
  border: none;
  background-color: transparent;
  color: var(--font1);
  cursor: pointer !important;
  font-weight: 400;
  font-family: "Poppins", sans-serif;
  font-size: 0.875rem;
}

.custum-date-picker-three input {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  max-width: 100%;
  text-align: center;
  cursor: pointer !important;
}

/* Ensure placeholder is visible as text when empty */
.custum-date-picker-three input::placeholder {
  color: var(--font1) !important; /* Adjust color of the placeholder */
  opacity: 1 !important;
}

.custum-date-picker-three:hover,
.custum-date-picker-three:focus,
.custum-date-picker-three:focus-within {
  box-shadow: none !important;
  outline: none !important;
  border: none !important;
  /* background-color: transparent; */
}

.custum-date-picker-three .ant-picker-clear {
  color: var(--brandColor);
}

.custum-date-picker-three .ant-picker-clear:hover {
  color: var(--brandColor);
}

.custum-date-picker-three-popup {
  font-family: "Poppins", sans-serif;
  font-size: 0.875rem;
  font-weight: 500;
  z-index: 1100;
}

.custum-date-picker-three-popup .ant-picker-header-view {
  font-size: 1rem;
  font-weight: 600;
  color: var(--font1);
}

.custum-date-picker-three-popup .ant-picker-header-view > button:hover {
  color: var(--brandColor) !important;
}

.custum-date-picker-three-popup .ant-picker-content tr th {
  font-family: "Poppins", sans-serif;
  font-size: 0.875rem;
  font-weight: 600;
  color: var(--brandColor);
}

.custum-date-picker-three-popup .ant-picker-cell {
  font-family: "Poppins", sans-serif;
  font-size: 0.875rem;
  font-weight: 500 !important;
}

.custum-date-picker-three-popup
  .ant-picker-cell-in-view.ant-picker-cell-today:not(.ant-picker-cell-disabled)
  .ant-picker-cell-inner::before {
  border: 2px solid var(--brandColor);
}

.custum-date-picker-three-popup
  .ant-picker-cell-in-view.ant-picker-cell-selected:not(
    .ant-picker-cell-disabled
  )
  .ant-picker-cell-inner {
  color: var(--white) !important;
  background-color: var(--brandColor) !important;
}

.custum-date-picker-three-popup
  .ant-picker-cell:hover:not(.ant-picker-cell-selected):not(
    .ant-picker-cell-range-start
  ):not(.ant-picker-cell-range-end)
  .ant-picker-cell-inner {
  background: var(--backgroundPink) !important;
}

.custum-date-picker-three-popup .ant-picker-ranges > li > a {
  text-decoration: none !important;
  color: var(--brandColor) !important;
}

.custom-date-picker-two-btn {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 1rem 0.75rem;
  cursor: pointer;
  font-weight: 400;
  font-family: "Poppins", sans-serif;
  font-size: 0.875rem;
}

.custom-date-picker-two-btn .clear-icon {
  opacity: 0;
  transition: opacity 0.3s ease;
}

.custom-date-picker-two-btn:hover .clear-icon {
  opacity: 1;
}

.custum-date-picker-two-popup {
  font-family: "Poppins", sans-serif;
  font-size: 0.875rem;
  font-weight: 500;
}

.custum-date-picker-two-popup .ant-picker-header-view {
  font-size: 1rem;
  font-weight: 600;
  color: var(--font1);
}

.custum-date-picker-two-popup .ant-picker-header-view > button:hover {
  color: var(--brandColor) !important;
}

.custum-date-picker-two-popup .ant-picker-content tr th {
  font-family: "Poppins", sans-serif;
  font-size: 0.875rem;
  font-weight: 600;
  color: var(--brandColor);
}

.custum-date-picker-two-popup .ant-picker-cell {
  font-family: "Poppins", sans-serif;
  font-size: 0.875rem;
  font-weight: 500 !important;
}

.custum-date-picker-two-popup
  .ant-picker-cell-in-view.ant-picker-cell-today:not(.ant-picker-cell-disabled)
  .ant-picker-cell-inner::before {
  border: 2px solid var(--brandColor);
}

.custum-date-picker-two-popup
  .ant-picker-cell-in-view.ant-picker-cell-selected:not(
    .ant-picker-cell-disabled
  )
  .ant-picker-cell-inner {
  color: var(--white) !important;
  background-color: var(--brandColor) !important;
}

.custum-date-picker-two-popup
  .ant-picker-cell:hover:not(.ant-picker-cell-selected):not(
    .ant-picker-cell-range-start
  ):not(.ant-picker-cell-range-end)
  .ant-picker-cell-inner {
  background: var(--backgroundPink) !important;
}

.custum-date-picker-two-popup .ant-picker-ranges > li > a {
  text-decoration: none !important;
  color: var(--brandColor) !important;
}

.custum-time-picker-one {
  width: 100% !important;
  height: var(--formInputHeight);
  border-radius: var(--formInputBorderRadius);
  display: flex;
  align-items: center;
  padding: 0 1rem;
  border: 1px solid var(--borderInput);
  background-color: var(--white);
  color: var(--font1);
  cursor: pointer;
  font-weight: 400;
  font-family: "Poppins", sans-serif;
  font-size: 0.875rem;
}

.custum-time-picker-one:hover,
.custum-time-picker-one:focus,
.custum-time-picker-one:focus-within {
  box-shadow: none !important;
  outline: none !important;
  border: 1px solid var(--brandColor) !important;
}

.custum-time-picker-one .ant-picker-clear {
  color: var(--brandColor);
}

.custum-time-picker-one .ant-picker-clear:hover {
  color: var(--brandColor);
}

.custum-time-picker-one-popup {
  font-family: "Poppins", sans-serif;
  font-size: 0.875rem;
  font-weight: 500;
  z-index: 1100;
}

.custum-time-picker-one-popup .ant-picker-header-view {
  font-size: 0.875rem;
  font-weight: 500;
  color: var(--font1);
}

.custum-time-picker-one-popup
  .ant-picker-time-panel-column
  > li.ant-picker-time-panel-cell-selected
  .ant-picker-time-panel-cell-inner {
  color: var(--brandColor) !important;
  background: var(--backgroundPink) !important;
}

.custum-time-picker-one-popup
  .ant-picker-time-panel-column
  > li.ant-picker-time-panel-cell
  .ant-picker-time-panel-cell-inner:hover {
  color: var(--brandColor) !important;
  background: var(--backgroundPink) !important;
}

.custum-time-picker-one-popup .ant-picker-ranges > li > a {
  text-decoration: none !important;
  color: var(--brandColor) !important;
}

.custum-time-picker-one-popup .ant-picker-ranges > li > button {
  background-color: var(--brandColor) !important;
}

.custum-time-picker-one-popup .ant-picker-ranges > li > button:hover {
  background-color: var(--hoverButtonColor) !important;
}

.custum-time-picker-two {
  width: 100%;
  height: -webkit-fit-content;
  height: -moz-fit-content;
  height: fit-content;
  padding: 0.2rem 0rem;
  border: none;
  background-color: transparent;
  color: var(--font1);
  cursor: pointer !important;
  font-weight: 400;
  font-family: "Poppins", sans-serif;
  font-size: 0.875rem;
}

.custum-time-picker-two input {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  max-width: 100%;
  text-align: center;
  cursor: pointer !important;
}

.custum-time-picker-two:hover,
.custum-time-picker-two:focus,
.custum-time-picker-two:focus-within {
  box-shadow: none !important;
  outline: none !important;
  border: none !important;
  /* background-color: transparent; */
}

.custum-time-picker-two .ant-picker-clear {
  color: var(--brandColor);
}

.custum-time-picker-two .ant-picker-clear:hover {
  color: var(--brandColor);
}

.custum-time-picker-two-popup {
  font-family: "Poppins", sans-serif;
  font-size: 0.875rem;
  font-weight: 500;
  z-index: 1100;
}

.custum-time-picker-two-popup .ant-picker-header-view {
  font-size: 0.875rem;
  font-weight: 500;
  color: var(--font1);
}

.custum-time-picker-two-popup
  .ant-picker-time-panel-column
  > li.ant-picker-time-panel-cell-selected
  .ant-picker-time-panel-cell-inner {
  color: var(--brandColor) !important;
  background: var(--backgroundPink) !important;
}

.custum-time-picker-two-popup
  .ant-picker-time-panel-column
  > li.ant-picker-time-panel-cell
  .ant-picker-time-panel-cell-inner:hover {
  color: var(--brandColor) !important;
  background: var(--backgroundPink) !important;
}

.custum-time-picker-two-popup .ant-picker-ranges > li > a {
  text-decoration: none !important;
  color: var(--brandColor) !important;
}

.custum-time-picker-two-popup .ant-picker-ranges > li > button {
  background-color: var(--brandColor) !important;
}

.custum-time-picker-two-popup .ant-picker-ranges > li > button:hover {
  background-color: var(--hoverButtonColor) !important;
}

.custum-week-picker-box {
  height: 2.75rem;
  width: 100%;
  display: flex;
  align-items: center;
  border: 1px solid var(--borderInput);
  background-color: var(--white);
  padding: 6px 9px;
  border-radius: 6px;
  cursor: pointer;
  font-weight: 400;
  font-family: "Poppins", sans-serif;
  font-size: 0.875rem;
}

.custum-week-picker-box:hover {
  border: 1px solid var(--brandColor);
}

.custum-week-picker-box .dropdown-icon {
  font-size: 14px !important;
  color: var(--font2) !important;
  opacity: 0.7;
}

.custum-week-picker-box .button-hover:hover {
  background-color: var(--backgroundPink) !important;
}

.custum-week-dropdown .ant-dropdown-menu .ant-dropdown-menu-item:hover {
  background-color: var(--backgroundPink) !important;
}

.custum-week-picker {
  color: var(--font1);
  font-family: "Poppins", sans-serif;
  font-size: 0.875rem;
  background: var(--white);
  width: 14.375rem;
  border: 1px solid var(--borderInput) !important;
  padding: 0 0.75rem;
  box-shadow: none !important;
  height: 2.75rem;
}

.custum-week-picker:hover {
  border: 1px solid var(--brandColor) !important;
}

.custum-week-picker-popup {
  font-size: 0.812rem;
  font-weight: 500;
}

.custum-week-picker-popup .ant-picker-header-view {
  font-family: "Poppins", sans-serif;
  font-size: 0.875rem;
  font-weight: 600;
  color: var(--font1);
}

.custum-week-picker-popup .ant-picker-header-view .ant-picker-month-btn:hover,
.ant-picker-year-btn:hover {
  color: var(--brandColor) !important;
}

.custum-week-picker-popup .ant-picker-content tr th {
  font-family: "Poppins", sans-serif;
  font-size: 0.875rem;
  font-weight: 600;
  color: var(--brandColor);
}

.custum-week-picker-popup .ant-picker-cell {
  font-family: "Poppins", sans-serif;
  font-size: 0.812rem;
  font-weight: 500 !important;
}

.custum-week-picker-popup
  .ant-picker-week-panel-row:hover
  td.ant-picker-cell::before {
  background: var(--brandColor);
}

.custum-week-picker-popup
  .ant-picker-week-panel-row-selected
  td.ant-picker-cell::before {
  background: var(--brandColor);
}

.custum-week-picker-popup
  .ant-picker-cell-today
  .ant-picker-cell-inner::before {
  border: 2px solid var(--brandColor) !important;
}

.custum-week-picker-popup
  .ant-picker-week-panel-row-selected:hover
  td.ant-picker-cell::before {
  background: var(--brandColor);
}

.backdrop {
  display: none;
  position: fixed;
  top: 4.25rem;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(108, 62, 131, 0.5);
  z-index: 200;
  transform: translateX(-100%);
  transition: transform 0.4s ease-out;
}

.backdrop.show {
  transform: translateX(0%);
}

@media screen and (max-width: 992px) {
  .backdrop {
    display: block;
  }
}

.ErrorBoundary_error_container__2dcqn {
  width: 100%;
  height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  padding: 15px;
}

.ErrorBoundary_heading__3OlfP {
  text-align: center;
  font-family: "Poppins", serif;
  font-weight: 500;
  font-size: 2.5rem;
  line-height: 1.3;
  color: var(--frontendFont1);
  margin: 1rem 0;
}

.ErrorBoundary_image__2OMP7 {
  max-width: 500px;
  object-fit: contain;
}

.ErrorBoundary_sub_heading__2oRQo {
  text-align: center;
  font-family: "Poppins", serif;
  font-weight: 500;
  font-size: 1.1rem;
  line-height: 1.5;
  color: var(--frontendFont1);
  margin-bottom: 1.5rem;
}

.ErrorBoundary_contact_support__3bEWA {
  font-weight: 600;
  color: var(--brandColor);
  cursor: pointer;
  padding: 0 4px;
}

.ErrorBoundary_button__-9sI- {
  max-width: 180px;
  height: 3.15rem;
  display: flex;
  font-weight: 500;
  font-size: 1.1rem;
  justify-content: center;
  align-items: center;
  color: var(--white) !important;
  padding: 0 20px;
  background: var(--brandColor);
  border-radius: 9px;
  transition: ease-in-out 200ms all;
}

.ErrorBoundary_button__-9sI-:hover {
  background: var(--hoverButtonColor);
}

@media (max-width: 992px) {
  .ErrorBoundary_heading__3OlfP {
    font-size: 2.3rem;
  }
  .ErrorBoundary_sub_heading__2oRQo {
    font-size: 1.05rem;
  }
  .ErrorBoundary_button__-9sI- {
    font-size: 1.05rem;
  }
}

@media (max-width: 768px) {
  .ErrorBoundary_heading__3OlfP {
    font-size: 2.1rem;
  }
  .ErrorBoundary_sub_heading__2oRQo {
    font-size: 1.02rem;
  }
  .ErrorBoundary_button__-9sI- {
    font-size: 1.2rem;
  }
}

@media (max-width: 576px) {
  .ErrorBoundary_heading__3OlfP {
    font-size: 2rem;
  }
  .ErrorBoundary_sub_heading__2oRQo {
    font-size: 1rem;
  }
  .ErrorBoundary_button__-9sI- {
    font-size: 1rem;
  }
}

/* global colors for main pages */
:root {
  --brandColor: #6c3e83;
  --bgColorFrontend: #2c1338;
  --brandColorFrontend: #e57cd8;
  --bgButtonHoverColor: #564260;
  --bgButtonColor: #fce5d8;
  --font1: #1a1a1a;
  --frontendFont1: #412a4c;
  /* --font2: #7d7e82; */
  --font2: #333;
  --frontendFont2: #6b5a74;
  --borderInput: #d1d1d1;
  --backgroundGrey: #f6f7fb;
  --white: #ffffff;
  --tableHeader: #ebebef;
  --red: #f46237;
  --backgroundPink: #f2ecfb;
  --success: #7fb97a;
  --borderInput: #d1d1d1;
  --hoverButtonColor: #5b336f;

  --formInputHeight: 3.15rem;
  --formInputBorderRadius: 0.75rem;
}

*,
*:before,
*:after {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  font-family: "Poppins", sans-serif;
}

:focus {
  outline: none;
}

/* do not remove dropdow class  */

input {
  border: 0;
  outline: none;
}

button {
  background-color: transparent;
  border: 0;
  cursor: pointer;
  outline: none;
  padding: 0;
}

button,
input {
  overflow: visible;
}

.dropdown {
  display: inline-block;
}

/* to make fullscreen modal, which is very important class so please don't remove */
.ToMakeFullscreenModal {
  width: 100vw !important;
  max-width: none !important;
  height: 100% !important;
  margin: 0 !important;
}

.ToMakeModalOnRight500px {
  position: fixed !important;
  max-width: 500px !important;
  width: 100% !important;
  margin: 0 !important;
  right: 0;
  top: 0;
  bottom: 0 !important;
}

.ToMakeModalOnRight350px {
  position: fixed !important;
  width: 100% !important;
  max-width: 350px !important;
  margin: 0 !important;
  right: 0;
  top: 0;
  bottom: 0 !important;
}

.ToMakeModalOnRight600px {
  position: fixed !important;
  max-width: 600px !important;
  width: 100% !important;
  margin: 0 !important;
  right: 0;
  top: 0;
  bottom: 0 !important;
}

.ToMakeModalWidth600px {
  max-width: 600px !important;
}

.ToMakeModalBorderRudius12px {
  border-radius: 12px !important;
}

.email-template-popover .ant-popover-content .ant-popover-inner {
  max-width: 250px;
  max-height: 300px;
  overflow-y: auto;
}

.three-dots {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  max-width: 100%;
}

.truncate-text {
  display: -webkit-box;
  -webkit-line-clamp: 3; /* Limit to 3 lines */
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: normal;
}

.ant-message-notice-content {
  padding: 15px 20px !important;
  font-family: "Poppins", sans-serif;
}

.font-weight-500 {
  font-weight: 500;
}
.font-weight-600 {
  font-weight: 600;
}
.font-weight-700 {
  font-weight: 700;
}

.modal-width-1000 {
  max-width: 1000px !important;
}

/* Custom Modal Dialog Animation */
.custom-modal .modal-dialog {
  transition: transform 0.2s ease-in !important;
  transform: translateY(-100%) !important;
}

.custom-modal.show .modal-dialog {
  transform: translateY(0) !important;
}

/* Custom Backdrop Animation (Sliding and Fading) */
.custom-modal .modal-backdrop {
  transition: opacity 0.2s ease-in, transform 0.2s ease-in !important;
  opacity: 0;
  transform: translateY(-100%) !important; /* Slide from top */
}

.custom-modal.show .modal-backdrop {
  opacity: 1 !important;
  transform: translateY(0) !important; /* Slide into place */
}

