.section {
  background-color: #fef9f7;
  width: 100%;
  height: 100%;
}

.heading {
  font-family: "Poppins", sans-serif;
  font-weight: 600;
  font-size: 3rem;
  line-height: 1.1;
  color: var(--frontendFont1);
}

.heading_text {
  text-align: justify;
  font-family: "Inter", sans-serif;
  font-size: 1.2rem;
  font-weight: 500;
  line-height: 1.3;
  color: var(--frontendFont2);
}

@media (max-width: 992px) {
  .heading {
    font-size: 2.75rem;
  }
  .heading_text {
    font-size: 1.1rem;
  }
}

@media (max-width: 768px) {
  .heading {
    font-size: 2.5rem;
  }
  .heading_text {
    font-size: 1rem;
  }
}

@media (max-width: 576px) {
  .heading {
    font-size: 2rem;
  }
}
