.custum-date-picker-one {
  width: 100% !important;
  height: var(--formInputHeight);
  border-radius: var(--formInputBorderRadius);
  display: flex;
  align-items: center;
  padding: 0 1rem;
  border: 1px solid var(--borderInput);
  background-color: var(--white);
  color: var(--font1);
  cursor: pointer;
  font-weight: 400;
  font-family: "Poppins", sans-serif;
  font-size: 0.875rem;
}

.custum-date-picker-one:hover,
.custum-date-picker-one:focus,
.custum-date-picker-one:focus-within {
  box-shadow: none !important;
  outline: none !important;
  border: 1px solid var(--brandColor) !important;
}

.custum-date-picker-one .ant-picker-clear {
  color: var(--brandColor);
}

.custum-date-picker-one .ant-picker-clear:hover {
  color: var(--brandColor);
}

.custum-date-picker-one-popup {
  font-family: "Poppins", sans-serif;
  font-size: 0.875rem;
  font-weight: 500;
  z-index: 1100;
}

.custum-date-picker-one-popup .ant-picker-header-view {
  font-size: 1rem;
  font-weight: 600;
  color: var(--font1);
}

.custum-date-picker-one-popup .ant-picker-header-view > button:hover {
  color: var(--brandColor) !important;
}

.custum-date-picker-one-popup .ant-picker-content tr th {
  font-family: "Poppins", sans-serif;
  font-size: 0.875rem;
  font-weight: 600;
  color: var(--brandColor);
}

.custum-date-picker-one-popup .ant-picker-cell {
  font-family: "Poppins", sans-serif;
  font-size: 0.875rem;
  font-weight: 500 !important;
}

.custum-date-picker-one-popup
  .ant-picker-cell-in-view.ant-picker-cell-today:not(.ant-picker-cell-disabled)
  .ant-picker-cell-inner::before {
  border: 2px solid var(--brandColor);
}

.custum-date-picker-one-popup
  .ant-picker-cell-in-view.ant-picker-cell-selected:not(
    .ant-picker-cell-disabled
  )
  .ant-picker-cell-inner {
  color: var(--white) !important;
  background-color: var(--brandColor) !important;
}

.custum-date-picker-one-popup
  .ant-picker-cell:hover:not(.ant-picker-cell-selected):not(
    .ant-picker-cell-range-start
  ):not(.ant-picker-cell-range-end)
  .ant-picker-cell-inner {
  background: var(--backgroundPink) !important;
}

.custum-date-picker-one-popup .ant-picker-ranges > li > a {
  text-decoration: none !important;
  color: var(--brandColor) !important;
}
