.section {
  background: #fefbfa url("../../../Assets/frontendImages/workloadBg.avif")
    no-repeat center bottom / contain;
  height: 100%;
  width: 100%;
  display: flex;
  align-items: center;
}

.container_padding {
  padding: 8rem 2rem;
}

.more_button {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  width: 15rem;
  font-weight: 500;
  font-size: 1.125rem;
  padding: 0.6rem 1rem 0.6rem 1.5rem;
  border-radius: 2rem;
  font-family: "Inter", sans-serif;
  color: var(--white);
  background-color: var(--brandColorFrontend);
  border: 2px solid var(--brandColorFrontend);
  transition: all 0.5s ease;
}

.more_button:hover {
  background-color: var(--brandColor);
  color: var(--white);
  border: 2px solid var(--brandColor);
}

.farword_arrow {
  margin-left: 0.75rem;
  transition: margin-left 0.4s ease;
}
.more_button:hover .farword_arrow {
  margin-left: 1.2rem;
}

.heading {
  font-family: "Poppins", sans-serif;
  font-weight: 600;
  font-size: 4rem;
  line-height: 1.1;
  color: var(--frontendFont1);
}

.text_font1 {
  font-family: "Inter", sans-serif;
  font-weight: 500;
  font-size: 1.2rem;
  line-height: 1.4;
  color: var(--frontendFont2);
}

.text_font2 {
  font-family: "Inter", sans-serif;
  font-weight: 500;
  font-size: 1rem;
  line-height: 1.4;
  color: var(--frontendFont2);
}

@media (max-width: 992px) {
  .container_padding {
    padding: 6rem 2rem;
  }
  .heading {
    font-size: 3.5rem;
  }
  .text_font1 {
    font-size: 1.15rem;
  }
  .text_font2 {
    font-size: 0.9rem;
  }
  .more_button {
    width: 16rem;
    font-size: 1.1rem;
  }
}

@media (max-width: 768px) {
  .section {
    background: #fefbfa
      url("../../../Assets/frontendImages/workloadBgSmall.avif") no-repeat
      center bottom / contain;
  }
  .container_padding {
    padding: 4rem 2rem;
  }
  .heading {
    font-size: 3rem;
  }
  .text_font1 {
    font-size: 1.1rem;
  }
  .text_font2 {
    font-size: 0.875rem;
  }
  .more_button {
    width: 15.5rem;
    font-size: 1rem;
  }
}

@media (max-width: 576px) {
  .container_padding {
    padding: 2rem 1rem;
  }
  .heading {
    font-size: 2.5rem;
  }
  .text_font1 {
    font-size: 1rem;
  }
}
