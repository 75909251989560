.heading {
  font-family: "Poppins", sans-serif;
  font-weight: 600;
  line-height: 1.3;
  font-size: 2rem;
  color: var(--frontendFont1);
}

.text_font1 {
  font-family: "Inter", sans-serif;
  font-weight: 500;
  font-size: 1.1rem;
  line-height: 1.5;
  color: var(--frontendFont2);
}

.more_button {
  font-family: "Inter", sans-serif;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  max-width: fit-content;
  color: var(--brandColorFrontend);
  font-weight: 500;
  font-size: 1.1rem;
  transition: all 0.4s ease;
}
.more_button:hover {
  color: var(--brandColor);
}

.cta_icons {
  margin-left: 0.1rem;
  transition: margin-left 0.4s ease;
}

.more_button:hover .cta_icons {
  margin-left: 0.6rem;
}

.text_font2 {
  font-family: "Inter", sans-serif;
  font-weight: 500;
  font-size: 1.1rem;
  color: var(--font2);
  line-height: 1.5;
}

.border_left_side_of_content {
  border-left: 3px solid var(--brandColorFrontend);
  padding-left: 0.5rem;
}

@media (max-width: 992px) {
  .heading {
    font-size: 1.8rem;
  }
  .text_font1 {
    font-size: 1rem;
  }
  .more_button {
    font-size: 1rem;
  }
  .text_font2 {
    font-size: 1rem;
  }
}

@media (max-width: 768px) {
  .heading {
    font-size: 1.7rem;
  }
}

@media (max-width: 576px) {
  .heading {
    font-size: 1.5rem;
  }
}
