.custum-week-picker-box {
  height: 2.75rem;
  width: 100%;
  display: flex;
  align-items: center;
  border: 1px solid var(--borderInput);
  background-color: var(--white);
  padding: 6px 9px;
  border-radius: 6px;
  cursor: pointer;
  font-weight: 400;
  font-family: "Poppins", sans-serif;
  font-size: 0.875rem;
}

.custum-week-picker-box:hover {
  border: 1px solid var(--brandColor);
}

.custum-week-picker-box .dropdown-icon {
  font-size: 14px !important;
  color: var(--font2) !important;
  opacity: 0.7;
}

.custum-week-picker-box .button-hover:hover {
  background-color: var(--backgroundPink) !important;
}

.custum-week-dropdown .ant-dropdown-menu .ant-dropdown-menu-item:hover {
  background-color: var(--backgroundPink) !important;
}

.custum-week-picker {
  color: var(--font1);
  font-family: "Poppins", sans-serif;
  font-size: 0.875rem;
  background: var(--white);
  width: 14.375rem;
  border: 1px solid var(--borderInput) !important;
  padding: 0 0.75rem;
  box-shadow: none !important;
  height: 2.75rem;
}

.custum-week-picker:hover {
  border: 1px solid var(--brandColor) !important;
}

.custum-week-picker-popup {
  font-size: 0.812rem;
  font-weight: 500;
}

.custum-week-picker-popup .ant-picker-header-view {
  font-family: "Poppins", sans-serif;
  font-size: 0.875rem;
  font-weight: 600;
  color: var(--font1);
}

.custum-week-picker-popup .ant-picker-header-view .ant-picker-month-btn:hover,
.ant-picker-year-btn:hover {
  color: var(--brandColor) !important;
}

.custum-week-picker-popup .ant-picker-content tr th {
  font-family: "Poppins", sans-serif;
  font-size: 0.875rem;
  font-weight: 600;
  color: var(--brandColor);
}

.custum-week-picker-popup .ant-picker-cell {
  font-family: "Poppins", sans-serif;
  font-size: 0.812rem;
  font-weight: 500 !important;
}

.custum-week-picker-popup
  .ant-picker-week-panel-row:hover
  td.ant-picker-cell::before {
  background: var(--brandColor);
}

.custum-week-picker-popup
  .ant-picker-week-panel-row-selected
  td.ant-picker-cell::before {
  background: var(--brandColor);
}

.custum-week-picker-popup
  .ant-picker-cell-today
  .ant-picker-cell-inner::before {
  border: 2px solid var(--brandColor) !important;
}

.custum-week-picker-popup
  .ant-picker-week-panel-row-selected:hover
  td.ant-picker-cell::before {
  background: var(--brandColor);
}
