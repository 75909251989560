.custum-time-picker-one {
  width: 100% !important;
  height: var(--formInputHeight);
  border-radius: var(--formInputBorderRadius);
  display: flex;
  align-items: center;
  padding: 0 1rem;
  border: 1px solid var(--borderInput);
  background-color: var(--white);
  color: var(--font1);
  cursor: pointer;
  font-weight: 400;
  font-family: "Poppins", sans-serif;
  font-size: 0.875rem;
}

.custum-time-picker-one:hover,
.custum-time-picker-one:focus,
.custum-time-picker-one:focus-within {
  box-shadow: none !important;
  outline: none !important;
  border: 1px solid var(--brandColor) !important;
}

.custum-time-picker-one .ant-picker-clear {
  color: var(--brandColor);
}

.custum-time-picker-one .ant-picker-clear:hover {
  color: var(--brandColor);
}

.custum-time-picker-one-popup {
  font-family: "Poppins", sans-serif;
  font-size: 0.875rem;
  font-weight: 500;
  z-index: 1100;
}

.custum-time-picker-one-popup .ant-picker-header-view {
  font-size: 0.875rem;
  font-weight: 500;
  color: var(--font1);
}

.custum-time-picker-one-popup
  .ant-picker-time-panel-column
  > li.ant-picker-time-panel-cell-selected
  .ant-picker-time-panel-cell-inner {
  color: var(--brandColor) !important;
  background: var(--backgroundPink) !important;
}

.custum-time-picker-one-popup
  .ant-picker-time-panel-column
  > li.ant-picker-time-panel-cell
  .ant-picker-time-panel-cell-inner:hover {
  color: var(--brandColor) !important;
  background: var(--backgroundPink) !important;
}

.custum-time-picker-one-popup .ant-picker-ranges > li > a {
  text-decoration: none !important;
  color: var(--brandColor) !important;
}

.custum-time-picker-one-popup .ant-picker-ranges > li > button {
  background-color: var(--brandColor) !important;
}

.custum-time-picker-one-popup .ant-picker-ranges > li > button:hover {
  background-color: var(--hoverButtonColor) !important;
}
