.Toggle {
  width: 2.5rem;
  height: 3.5rem;
  float: right;
  display: flex;
  flex-flow: column;
  justify-content: space-around;
  align-items: center;
  padding: 10px 0;
  box-sizing: border-box;
  cursor: pointer;
}

.Toggle div {
  width: 90%;
  height: 3px;
  background-color: var(--white);
}

.Nav-top {
  display: flex;
  height: 3.125rem;
  justify-content: space-between;
  align-items: center;
  width: -webkit-fill-available;
}

@media (min-width: 1079px) {
  .Toggle {
    display: none;
  }
}

@media (min-width: 1079px) {
  .Nav-top {
    display: none;
  }
}
